import React, {useState} from "react";
import TableCard from "../../generic/TableCard";
import Table from "./Table";
import TextSearch from "../../generic/field/TextSearch";
import { pickBy, isEqual } from 'lodash';
import { useQueryParam, StringParam } from 'use-query-params';
import {exportStocksReporting} from "../../../service/stocksReporting";
import ExportFunButton from "../../generic/ExportFunButton";
import {Button} from "reactstrap";

export default function Index() {
    const [exportParams, setExportParams] = useState({});
    const [filterBVCode, setFilterBVCode] = useQueryParam('bvCode', StringParam);
    const [filterWarehouseCode, setWarehouseCode] = useQueryParam('warehouseCode', StringParam);

    const searchBVCode = filterBVCode => {
        setFilterBVCode(filterBVCode)
    };

    const searchWarehouseCode = filterWarehouseCode => {
        setWarehouseCode(filterWarehouseCode)
    };

    const dropFilters = () => {
        setFilterBVCode(undefined)
        setWarehouseCode(undefined)
    };

    const filters = pickBy({
        bvCode: filterBVCode,
        warehouseCode: filterWarehouseCode
    })

    const onDataTableChange = (data) => {
        if (!isEqual(exportParams, data)) setExportParams(data);
    };

    return (
        <TableCard title={"Stocks"}>
            <div style={{minHeight: "400px"}}>
                <div className={"row justify-content-start ml-1 mb-3"}>
                    <TextSearch
                        name="bvCode"
                        onSearch={ searchBVCode }
                        className="form-control col-sm-3 mr-2"
                        value={ filterBVCode }
                        placeholder={"Enter BV code..."}
                    />
                    <TextSearch
                        name="warehouseName"
                        onSearch={ searchWarehouseCode }
                        className="form-control col-sm-3 mr-2"
                        value={ filterWarehouseCode }
                        placeholder={"Enter warehouse code..."}
                    />
                    <Button className="mr-sm-2" color="default" onClick={ dropFilters }>
                        <i className="fa fa-fw fa-times"/>
                        Cancel
                    </Button>
                    <div className="export-button">
                        <ExportFunButton dataParameters={exportParams} exportFun={exportStocksReporting}/>
                    </div>
                </div>
                <Table filters={ filters } onDataTableChange={onDataTableChange} />
            </div>
        </TableCard>
    )
}