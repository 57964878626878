import React from "react";
import Loader from "../../../generic/Loader";
import {showDangerToast} from "../../../../lib/notify";
import PropTypes from 'prop-types';
import DataTable, {field} from "../../../generic/DataTable";
import {findAndCount} from "../../../../service/deliveryMatrixData";
import moment from 'moment';
import {find} from "../../../../service/deliveryMatrix";

export default class Table extends React.Component {

    constructor(props) {
        super(props);
        this.handleSort = this.handleSort.bind(this);
        this.loadData = this.loadData.bind(this);

        this.state = {
            sortField: "id",
            sortDir: "desc",
            start: 0,
            limit: 10,
            loading: false
        };
    }

    componentDidMount() {
        this.loadData();
    }

    loadData(){
        this.setState({loading: true});
        return find({input_file_id:this.props.uploadId})
            .then((data) => {
                this.setState({dmId:data[0] ? data[0].id : 0, loading: false})
            })
            .catch(e => {
                this.setState({loading: false});
                showDangerToast(e);
            });
    }

    handleSort(field) {
        return dir => this.setState({sortField: field, sortDir: dir});
    }


    render() {
        const filters = {};
        const dmId = this.state.dmId;
        if (this.state.loading) return <Loader/>;
        if (dmId) filters.delivery_matrix_id = dmId;
        return (<DataTable
            filters={filters}
            findAndCount={findAndCount}
            fields={[
                field("INDEX", true, 'index'),
                field("City", true, "city"),
                field("Created at", true, "created_at",res => moment(res.created_at).format("YYYY.MM.DD")),
                field("Region", true, "region"),
                field("Input File Id", true, "delivery_matrix_id")
            ]}
            name={"deliveryMatrixData"}
        />);
    }
}

Table.propTypes = {
    uploadId: PropTypes.number,
};