import React, {useEffect} from 'react'
import {Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {showWarningToast} from "../../lib/notify";
import {createEntry, updateEntry} from "../../service/timeSlots";
import useInput from "../../hooks/useInput";
import PropTypes from 'prop-types'
import RegularButton from "../generic/RegularButton";

const CEComponent = ({changeData, closeModalConfirmation, type, isOpen, onToggle}) => {

  const {value: timeslots, bind: bindTimeslots, changeValue: changeTimeslots} = useInput(undefined);
  const {value: comment, bind: bindComment, changeValue: changeComment} = useInput(undefined);

  useEffect(() => {
    if (changeData) {
      const {
        timeslots,
        comment
      } = changeData;

      changeTimeslots(timeslots);
      changeComment(comment);
    }
  }, [changeData])

  const timeslotsValidate = (timeslots) => /^((0[0-9]|1[0-9]|2[0-3])[.|:][0-5][0-9]-(0[0-9]|1[0-9]|2[0-3])[.|:][0-5][0-9];)+$/.test(timeslots);

  const onSave = () => {
    const data = {
      timeslots,
      comment
    }

    if (timeslotsValidate(timeslots)) {
      if (type === 'create') {
        createEntry(data).then(() => {
            changeTimeslots(undefined);
            changeComment(undefined);
            closeModalConfirmation();
        }).catch(() => showWarningToast('Something went wrong.'));
      } else {
        updateEntry(changeData.id, data)
          .then(closeModalConfirmation)
          .catch(() => showWarningToast('Something went wrong.'));
      }
    } else {
      showWarningToast('Time slots are incorrect');
    }
  }

  return <Modal isOpen={isOpen} toggle={onToggle}>
    <ModalHeader>{type === 'create' ? 'Create row' : 'Edit row'}</ModalHeader>
    <ModalBody>
      <Form>
        <FormGroup>
          <Label for="code">Time slots</Label>
          <Input type="text" name="timeslots" id="timeslots" placeholder="Enter time slots ..." {...bindTimeslots} />
          <div className="mb-3 mt-1 small text-gray">Examples: "09.00-12.00;" or "10:00-12:00;12:00-14:00;"</div>
          <Label for="code">Comment</Label>
          <Input type="text" name="comment" id="comment" placeholder="Enter comment ..." {...bindComment}/>
        </FormGroup>
      </Form>
    </ModalBody>
    <ModalFooter>
      <RegularButton color="primary" onClick={ onSave }>Save</RegularButton>{' '}
      <Button color="secondary" onClick={ closeModalConfirmation }>Cancel</Button>
    </ModalFooter>
  </Modal>
}

CEComponent.propTypes = {
  type: PropTypes.string,
  isOpen: PropTypes.bool,
  closeModalConfirmation: PropTypes.func,
}

export default CEComponent;