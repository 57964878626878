import React, { useState, useEffect } from 'react'
import { Typeahead } from 'react-bootstrap-typeahead';
import {Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import { showWarningToast } from "../../lib/notify";
import { createEntry, updateEntry } from "../../service/deliveryMethodProducts";
import * as notify from "../../lib/notify"
import {getCodeAndLabelOfDeliveryMethod, modifyMethodLabelOfObjectForTypeahead} from "../../common";
import RegularButton from "../generic/RegularButton";

const CEComponent = ({ changeData, isOpen, closeModalConfirmation, onToggle, methods, type }) => {
  const [code, setCode] = useState(undefined);
  const [method, setMethod] = useState(undefined);
  const [availability, setAvailability] = useState(undefined);

  useEffect(() => {
    if(changeData) {
      const { code, method, availability } = changeData;

      setCode(code);
      setMethod(method);
      setAvailability(availability);
    }
  }, [changeData]);

  const refreshStateAndCloseModal = () => {

    setCode(undefined);
    setMethod(undefined);
    setAvailability(undefined);

    closeModalConfirmation();
  }

  const onSave = () => {
    const data = {
      code,
      method,
      availability: !!availability
    }

    if(type === 'create') {
      if(code && method) {
        createEntry(data).then(() => {
          refreshStateAndCloseModal()
        }).catch(e => showWarningToast('Something went wrong.'));
      } else {
        notify.showDangerToast('All fields must be filled');
      }
    } else {
      if(code && method) {
        updateEntry(changeData.id, data).then(() => {
          refreshStateAndCloseModal();
        }).catch(e => showWarningToast('Something went wrong.'));
      } else {
        notify.showDangerToast('All fields must be filled');
      }
    }
  }

  const handleCodeChange = event => {
    event.preventDefault();
    const code = event.target.value;

    setCode(code);
  }

  const handleAvailabilityChange = () => {
    setAvailability(!availability);
  }

  const handleTypeaheadChange = selected => {
    if(selected && selected[0]) {
      setMethod(selected[0].code);
    }
  }

  let defaultTypeaheadValue;

  if(methods && changeData && changeData.method) {
    defaultTypeaheadValue = getCodeAndLabelOfDeliveryMethod(methods, changeData);
  }

  return <Modal isOpen={isOpen} toggle={onToggle}>
    <ModalHeader>{type === 'create' ? 'Create row' : 'Edit row'}</ModalHeader>
    <ModalBody>
      <Form>
        <FormGroup>
          <Label for="code">Code</Label>
          <Input type="text" name="code" id="code" placeholder="Enter code ..." value={ code } onChange={ handleCodeChange }/>
          <Label for="typeahead" className="mt-2">Method</Label>
          <Typeahead
            id="typeahead"
            className="mb-3"
            options={ modifyMethodLabelOfObjectForTypeahead(methods) }
            placeholder="Choose delivery method..."
            onChange={ handleTypeaheadChange }
            defaultInputValue={ defaultTypeaheadValue }
          />
          <div className="form-check mt-3 mr-3">
            <label className="form-check-label">
              <input
                type="checkbox"
                onChange={ handleAvailabilityChange }
                checked={ availability }
              />
              Availability
              <i className="input-helper"/>
            </label>
          </div>
        </FormGroup>
      </Form>
    </ModalBody>
    <ModalFooter>
      <RegularButton color="primary" onClick={ onSave }>Save</RegularButton>{' '}
      <Button color="secondary" onClick={ closeModalConfirmation }>Cancel</Button>
    </ModalFooter>
  </Modal>
}

export default CEComponent;