import React from "react";
import TableCard from "../generic/TableCard";
import Table from "./Table";
import TextSearch from "../generic/field/TextSearch";
import { Button } from "reactstrap";
import { pickBy } from 'lodash';
import { useQueryParam, StringParam, NumberParam, DateParam } from 'use-query-params';
import 'rc-time-picker/assets/index.css';
import './Index.css';
import DatepickerWithTime from "../generic/field/DatepickerWithTime";

export default function Index() {
    const [filterMethodId, setFilterMethodId] = useQueryParam('methodId', NumberParam);
    const [filterMethodCode, setFilterMethodCode] = useQueryParam('methodCode', StringParam);
    const [filterMethodActivationDate, setFilterMethodActivationDate] = useQueryParam('methodActivationDate', DateParam);
    const [filterMethodDeactivationDate, setFilterMethodDeactivationDate] = useQueryParam('methodDeactivationDate', DateParam);
    const [filterMethodActivationTime, setFilterMethodActivationTime] = useQueryParam('methodActivationTime', StringParam);
    const [filterMethodDeactivationTime, setFilterMethodDeactivationTime] = useQueryParam('methodDeactivationTime', StringParam);

    const searchMethodId = filterMethodId => {
        setFilterMethodId(filterMethodId)
    };

    const searchMethodName = filterMethodCode => {
        setFilterMethodCode(filterMethodCode)
    };

    const searchFilterMethodActivationDate = filterMethodActivationDate => {
        setFilterMethodActivationDate(filterMethodActivationDate)
    };

    const searchFilterMethodActivationTime = filterMethodActivationTime => {
        setFilterMethodActivationTime(filterMethodActivationTime.format('HH:mm'))
    };

    const searchFilterMethodDeactivationDate = filterMethodDeactivationDate => {
        setFilterMethodDeactivationDate(filterMethodDeactivationDate)
    };

    const searchFilterMethodDeactivationTime = filterMethodDeactivationTime => {
        setFilterMethodDeactivationTime(filterMethodDeactivationTime.format('HH:mm'))
    };

    const dropFilters = () => {
        setFilterMethodId(undefined);
        setFilterMethodCode(undefined);
        setFilterMethodActivationDate(undefined);
        setFilterMethodDeactivationDate(undefined);
        setFilterMethodActivationTime(undefined);
        setFilterMethodDeactivationTime(undefined);
    };

    const filters = pickBy({
        id: filterMethodId,
        code: filterMethodCode,
        activationDate: filterMethodActivationDate,
        deactivationDate: filterMethodDeactivationDate,
        activationTime: filterMethodActivationTime,
        deactivationTime: filterMethodDeactivationTime
    })

    return (
        <TableCard>
            <div style={{minHeight: "400px"}}>
                <div className={"row justify-content-start ml-1 mb-3"}>
                    <TextSearch
                        name="id"
                        onSearch={ searchMethodId }
                        className="form-control col-sm-3 mr-2"
                        value={ filterMethodId }
                        placeholder={"Enter id..."}
                    />
                    <TextSearch
                        name="name"
                        onSearch={ searchMethodName }
                        className="form-control col-sm-3 mr-2"
                        value={ filterMethodCode }
                        placeholder={"Enter method code..."}
                    />
                    <Button className="mr-sm-2" color="default" onClick={ dropFilters }>
                        <i className="fa fa-fw fa-times"/>
                        Cancel
                    </Button>
                </div>
                <div className={"row justify-content-start ml-3 mb-3"}>
                    <DatepickerWithTime
                        datepickerDaySelected={filterMethodActivationDate}
                        datepickerSearchFunction={searchFilterMethodActivationDate}
                        datepickerPlaceholder={'Choose activation date'}
                        timepickerPlaceholder={'Start time'}
                        timepickerSearchFunction={searchFilterMethodActivationTime}
                        timepickerValue={filterMethodActivationTime}
                    />
                    <label className="ml-3 mr-3 col-form-label-sm"> — </label>
                    <DatepickerWithTime
                        datepickerDaySelected={filterMethodDeactivationDate}
                        datepickerSearchFunction={searchFilterMethodDeactivationDate}
                        datepickerPlaceholder={'Choose deactivation date'}
                        timepickerPlaceholder={'End time'}
                        timepickerSearchFunction={searchFilterMethodDeactivationTime}
                        timepickerValue={filterMethodDeactivationTime}
                    />
                </div>
                <Table filters={ filters } />
            </div>
        </TableCard>
    )
}