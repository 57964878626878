import React from 'react';
import "./LoaderOverlay.css";
import PropTypes from 'prop-types';
import Loader from './Loader';

class LoaderOverlay extends React.Component {
    render() {
        return <div className={"loader-wrap " + this.props.className}>
            <div className={`loader-overlay ${this.props.isVisible ? 'visible' : ''}`}>
                <Loader/>
            </div>
            {this.props.children}
        </div>
    }
}

LoaderOverlay.propTypes = {
    isVisible: PropTypes.bool,
    className: PropTypes.string
};

export default LoaderOverlay;

