import {CustomInput} from "reactstrap";
import React from "react";
import {Controller} from "react-hook-form";
import PropTypes from "prop-types";

const valueToBool = (value) => {
    if (!value) return false
    return value.toLowerCase() === 'да';
}

const changeValue = (value) => {
    if (!value || value.toLowerCase() === 'нет') return 'Да'
    if (value.toLowerCase() === 'да') return 'Нет'
}

const Toggle = ({it, control, defaultValue }) => (
    <div className="col-lg-4 col-md-6 mb-3 mt-auto">
        <Controller
            control={control}
            name={it.id}
            defaultValue={defaultValue || 'Нет'}
            render={({value, onChange}) => (
            <CustomInput type="checkbox" className={'custom-switch'} id={it.id} name={it.id} label={it.name}
             checked={valueToBool(value)} value={value} onChange={(e) => onChange(changeValue(e.target.value))} />)}
        />
    </div>)

Toggle.propTypes = {
    control: PropTypes.object.isRequired,
    it: PropTypes.object.isRequired,
    defaultValue: PropTypes.string,
}

export default React.memo(Toggle);