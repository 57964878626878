import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import RoleButton from "./RoleButton";
import {getUserRoleFromSession} from "../../lib/auth";


export default function RegularButton({children, ...props}) {
    const [role, setRole] = useState('IT System Support');

    useEffect(() => {
        setRole(getUserRoleFromSession())
    })

    return (
        <RoleButton disableFor={"IT System Support"} role={role} {...props}>{children}</RoleButton>
    )
}

RoleButton.propTypes = {
    children: PropTypes.element.isRequired,
    notComponent: PropTypes.bool
}