import React from "react";
import DataTable, { field } from "../../../generic/DataTable";
import { findAndCount } from "../../../../service/stocks";

const Table = ({ uploadId }) => {
     const filters = { input_file_id: uploadId };
     return (<DataTable
         filters={ filters }
         findAndCount={ findAndCount }
         fields={[
             field("BV Code", true, "bvCode"),
             field("Available QTY", true, "availableQuantity"),
             field('Warehouse code', true, 'warehouseCode'),
             field('Warehouse name', true, 'warehouseName')
         ]}
         name={ "stocks" }
        />
     );
}

export default Table;