import {Service} from "../lib/strapi";
import {strapi} from "../lib/auth";

const entity = "productslimitsdata";

const service = new Service(entity);

export const findAndCount = service.findAndCountServerSide;

export const deleteEntry = (id) => {
    return strapi.deleteEntry(entity, id);
};
