import React from "react";
import Card from "../generic/Card";
import {Col, Row} from "reactstrap";
import PropTypes from "prop-types";

class InputFileDatabaseCard extends React.Component {

    render() {
        const {updatedRows, insertedRows, warningRows} = this.props.inputFile;
        return <Card title="Database status">
            <Row className="mt-2">
                <Col xs={12} md={12} lg={12}>
                    <div className="form-group">
                        <label>Inserted rows:</label> {insertedRows}
                    </div>

                    <div className="form-group">
                        <label>Updated rows:</label> {updatedRows}
                    </div>

                    <div className="form-group">
                        <label>Warnings:</label> {warningRows}
                    </div>
                </Col>
            </Row>
        </Card>
    }
}

InputFileDatabaseCard.propTypes = {
    inputFile: PropTypes.object.isRequired,
};

export default InputFileDatabaseCard;

