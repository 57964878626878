import React, { useState, useEffect } from "react";
import TableCard from "../generic/TableCard";
import Table from "./Table";
import TextSearch from "../generic/field/TextSearch";
import CEComponent from "./CEComponent";
import {findAndCount} from "../../service/referenceData";
import {deleteEntry} from "../../service/deliveryMethodProducts";
import ModalConfirmation from "../generic/ModalConfirmation";
import RegularButton from "../generic/RegularButton";
import {Button} from "reactstrap";

const Index = () => {

  const [filterSKUCode, setFilterSKUCode] = useState(undefined);
  const [filterMethod, setFilterMethod] = useState(undefined);
  const [openDeleteModalConfirmation, setOpenDeleteModalConfirmation] = useState(undefined);
  const [openCreateModalConfirmation, setOpenCreateModalConfirmation] = useState(undefined);
  const [openEditModalConfirmation, setOpenEditModalConfirmation] = useState(undefined);
  const [reloadTable, setReloadTable] = useState(false);
  const [methods, setMethods] = useState([]);
  const [changeData, setChangeData] = useState({});
  const [id, setId] = useState(undefined);

  const searchSKUCode = filterSKUCode => {
    setFilterSKUCode(filterSKUCode);
  };

  const searchMethod = filterMethod => {
    setFilterMethod(filterMethod);
  };

  const dropFilters = () => {
    setFilterSKUCode(undefined)
    setFilterMethod(undefined);
  };

  const closeModalConfirmation = () => {
    setOpenCreateModalConfirmation(undefined);
    setOpenEditModalConfirmation(undefined);
    setReloadTable(!reloadTable);
    setOpenDeleteModalConfirmation(false);
    setId(undefined);
  }

  const onCreate = () => {
    setOpenCreateModalConfirmation(true);
  }

  const onChange = changeData => {
    setOpenEditModalConfirmation(true);
    setChangeData(changeData);
  }

  const onDelete = ({ id }) => {
    setOpenDeleteModalConfirmation(true);
    setId(id);
  }

  useEffect(() => {
    loadMethods();
  }, []);

  const loadMethods = async () => {
    const methods = await findAndCount(0, 100000, 'id', 'desc', { kind: 'deliveryMethod' });
    setMethods(methods)
  };

  return (
    <TableCard title={"Delivery methods products"}>
      <div style={{ minHeight: "400px" }}>
        <div className={ "row justify-content-start ml-1 mb-3" }>

          <TextSearch
            type="SKUName"
            name="SKUName"
            onSearch={ searchSKUCode }
            className="form-control col-sm-3 mr-2"
            value={ filterSKUCode }
            placeholder={"Enter product code..."}
          />
          <TextSearch
            type="method"
            name="method"
            onSearch={ searchMethod }
            className="form-control col-sm-3 mr-2"
            value={ filterMethod }
            placeholder={"Enter method..."}
          />
          <Button className="mr-sm-2" color="default" onClick={ dropFilters }>
            <i className="fa fa-fw fa-times"/>
            Cancel
          </Button>
          <RegularButton className="mr-sm-2" color="primary" onClick={ onCreate }>
            <i className="fa fa-plus"/>
            Add
          </RegularButton>
        </div>
        <ModalConfirmation text="Are you sure you want to delete it?" isOpen={openDeleteModalConfirmation} onConfirm={() => {
          deleteEntry(id).then(() => closeModalConfirmation())
        }} onClose={closeModalConfirmation} />
        <CEComponent type="create" isOpen={ openCreateModalConfirmation } closeModalConfirmation={ closeModalConfirmation } methods={ methods } />
        <CEComponent type="edit" isOpen={ openEditModalConfirmation } changeData={ changeData } closeModalConfirmation={ closeModalConfirmation } methods={ methods } />

        <Table referenceDataMethods={ methods } reloadTable={ reloadTable } filters={{ method: filterMethod, code: filterSKUCode }}
               onChange={ onChange } onDelete={ onDelete } />
      </div>
    </TableCard>
  )
}

export default Index;