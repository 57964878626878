import React from "react";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";

export class MenuLink extends React.Component {
    render() {
        const {name, to, icon} = this.props;
        const isActive = this.props.location.pathname === to;
        return (
            <li className={`nav-item ${isActive ? "active" : ""}`}>
                <Link
                    className={`nav-link ${isActive ? "active" : ""}`}
                    to={to}>
                    <i className={`fa fa-${icon} menu-icon`}/>
                    <span className="menu-title">{name}</span>
                </Link>
            </li>
        );
    }
}

MenuLink.propTypes = {
    to: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
};

export class ExpandableLink extends React.Component {
    render() {
        const {name, icon, to, children} = this.props;
        const isActive = this.props.location.pathname.startsWith(to);
        const id = "id" + to.replace(/[^a-zA-Z]/g, "_");
        return (
            <li className={`nav-item ${isActive ? "active" : ""}`}>

                <a className={`nav-link ${isActive ? "active" : ""}`}
                   data-toggle="collapse"
                   href={"#" + id}
                   aria-expanded={isActive}
                   aria-controls={id}>
                    <i className={`fa fa-${icon} menu-icon`}/>
                    <span className="menu-title">{name}</span>
                    <i className="menu-arrow"/>
                </a>

                <div className={"collapse hide-for-minimal " + (isActive ? "show" : "")} id={id}>
                    <ul className="nav flex-column sub-menu">
                        {children}
                    </ul>
                </div>
            </li>
        );
    }
}

ExpandableLink.propTypes = MenuLink.propTypes;

export class SubMenuLink extends React.Component {
    render() {
        const {to, children, exact, location} = this.props;
        const isActive = exact ? location.pathname === to : location.pathname.startsWith(to);
        return (
            <li className={`nav-item ${isActive ? "active" : ""}`}>
                <Link
                    className={`nav-link ${isActive ? "active" : ""}`}
                    to={to}>
                    {children}
                </Link>
            </li>
        );
    }
}

SubMenuLink.propTypes = {
    to: PropTypes.string.isRequired,
    exact: PropTypes.bool
};