import React, { useEffect } from "react";
import LoaderOverlay from "./generic/LoaderOverlay";
import { authAzure } from "../service/microsoft";
import { saveToken } from "../lib/session";
import {Container} from "reactstrap";

export default function AzureLogin(props) {
    useEffect(() => {
        const { pathname, search } = props.location;

        if(pathname.includes('/connect/microsoft')) {
            authAzure(search).then(response => {
                saveToken(response.data)
                window.location.reload();
            })
        }
    }, [])

    return (
        <Container>
                <div className="container-fluid page-body-wrapper full-page-wrapper">
                    <div className="content-wrapper d-flex align-items-center justify-content-center auth px-0">
                        <LoaderOverlay isVisible />
                    </div>
                </div>
        </Container>
        )
}