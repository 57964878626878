import React, { useState, useEffect, useRef } from "react";
import Card from "../../../generic/Card";
import FileInput from "../../../generic/field/FileInput";
import { Col, Row } from "reactstrap";
import { uploadStocks } from '../../../../service/inputFile';
import * as notify from "../../../../lib/notify";
import InputFileStep from "../../InputFileStep";
import { Redirect, withRouter } from "react-router-dom";
import { Typeahead } from 'react-bootstrap-typeahead';
import LoaderOverlay from "../../../generic/LoaderOverlay";
import { findAndCount } from "../../../../service/warehouses";
import { modifyWarehouseDataForTypeahead } from "../../../../common";
import RegularButton from "../../../generic/RegularButton";

const Index = () => {

    const typeahead = useRef();
    const [file, setFile] = useState(undefined);
    const [redirectToItemId, setRedirectToItemId] = useState(undefined);
    const [loading, setLoading] = useState(undefined);
    const [warehouse, setWarehouse] = useState(undefined);
    const [warehouses, setWarehouses] = useState(undefined);


    useEffect(() => {
        loadWarehouses();
    }, []);

    const handleClear = () => {
        setFile(undefined);
        setWarehouse(undefined)

        typeahead.current.clear();
    }

    const handleSubmit = () => {

        if(!file || !warehouse) {
            notify.showDangerToast('Please select warehouse or file');
            return;
        }

        file.warehouseCode = warehouse.code;
        file.warehouseName = warehouse.label;

        uploadStocks(file).then(async entry => {
              setLoading(true);

              if (entry.error === 'Wrong format') {
                  notify.showDangerToast('Wrong format. Please check format of uploaded file.')
                  return;
              }

              notify.showSuccessToast("File uploaded");

              setRedirectToItemId(entry.id);

              return entry;
          })
          .catch(notify.showDangerToast)
          .finally(() => setLoading(false));
    }

    const loadWarehouses = async () => {
        const warehouses = await findAndCount(0, 100000, 'name', 'asc');
        setWarehouses(warehouses);
    }

    const onChange = warehouse => {
        if(warehouse.length) {
            setWarehouse(warehouse[0]);
        }
    }

    if(redirectToItemId) return <Redirect push to={ `/input/stocks/upload/${redirectToItemId}` }/>

    return (
        <Card title={ "Upload new file" }>
            <InputFileStep status="upload"/>
            <LoaderOverlay isVisible={loading}>
                <Row className="mt-2">
                    <Col xs={12} md={6}>
                        <div className="form-group">
                            <label>File:</label>
                            <FileInput file={file} onSelect={file => setFile(file)}/>
                        </div>
                        <Typeahead
                          id="typeahead"
                          ref={typeahead}
                          className="mb-3"
                          options={ modifyWarehouseDataForTypeahead(warehouses) }
                          placeholder="Choose warehouse..."
                          onChange={ onChange }
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <RegularButton notComponent type="button" className="btn btn-primary mr-sm-2" onClick={ handleSubmit }>Submit</RegularButton>
                        <RegularButton notComponent type="button" className="btn btn-outline-secondary" onClick={ handleClear }>Clear</RegularButton>
                    </Col>
                </Row>
            </LoaderOverlay>

        </Card>
    );
}

export default withRouter(Index);