import React from "react";
import TableCard from "../../../generic/TableCard";
import Table from "./Table";
import { Link } from "react-router-dom";

const Index = () => {

    return (
        <TableCard title={ "Alternative Products" }>
            <form className="form-inline mb-2">
                <Link className="btn btn-primary" to={ "/input/alternativeProducts/upload/new" }>
                    <i className="fa fa-plus"/>
                    &nbsp; Upload file
                </Link>
            </form>
            <div style={{ minHeight: "400px" }}>
                <Table/>
            </div>
        </TableCard>
    );
}

export default Index;