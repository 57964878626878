import React, {useState} from 'react'
import TableCard from "../generic/TableCard";
import ModalConfirmation from "../generic/ModalConfirmation";
import {deleteEntry, findAndCount} from "../../service/timeSlots";
import TextSearch from "../generic/field/TextSearch";
import DataTable, {field} from "../generic/DataTable";
import CEComponent from "./CEComponent";
import {pickBy} from "lodash";
import {useQueryParam, StringParam} from 'use-query-params';
import {showWarningToast} from "../../lib/notify";
import RegularButton from "../generic/RegularButton";
import {Button} from "reactstrap";

export default function Index() {
    const [searchId, setSearchId] = useQueryParam('id', StringParam);
    const [openDeleteModalConfirmation, setOpenDeleteModalConfirmation] = useState(undefined);
    const [id, setId] = useState(undefined);
    const [openCreateModalConfirmation, setOpenCreateModalConfirmation] = useState(undefined);
    const [openEditModalConfirmation, setOpenEditModalConfirmation] = useState(undefined);
    const [changeData, setChangeData] = useState(undefined);
    const [reload, setReload] = useState(undefined);

    const filters = pickBy({
      id: searchId
    })

    const onClearFilters = () => {
        setSearchId(undefined);
    }

    const onDelete = ({ id }) => {
        setOpenDeleteModalConfirmation(true);
        setId(id);
    }

    const onCreate = () => setOpenCreateModalConfirmation(true);

    const onChange = changeData => {
        setOpenEditModalConfirmation(true);
        setChangeData(changeData);
    }

    const closeModalConfirmation = () => {
        setReload(!reload);
        setOpenEditModalConfirmation(false);
        setId(undefined);
        setOpenCreateModalConfirmation(false);
        setOpenDeleteModalConfirmation(false);
    }

    let fields = [
        field("ID",true, "id"),
        field("Time Slots", true, "timeslots"),
        field("Comment", true, "comment")
    ];

    return <TableCard title={"Time slots"}>
        <div>
            <ModalConfirmation text="Are you sure you want to delete it?" isOpen={openDeleteModalConfirmation} onConfirm={() => {
                deleteEntry(id).then((res) => res.error ? showWarningToast(res.error) :  closeModalConfirmation()).catch((res) => showWarningToast(res.message));
            }} onClose={closeModalConfirmation} />
            <CEComponent type="create" isOpen={openCreateModalConfirmation} closeModalConfirmation={closeModalConfirmation}/>
            <CEComponent type="edit" isOpen={openEditModalConfirmation} changeData={changeData} closeModalConfirmation={closeModalConfirmation}/>
            <div className={"row justify-content-start align-items-center ml-1 mb-3"}>
                <TextSearch
                    type="text"
                    name="Code"
                    onSearch={setSearchId}
                    value={searchId}
                    className="form-control col-sm-3 mr-2"
                    placeholder={"Enter id..."}
                />
                <Button className="mr-sm-2" color="default" onClick={onClearFilters}>
                    <i className="fa fa-fw fa-times"/>
                    Cancel
                </Button>
                <RegularButton className="mr-sm-2" color="primary" onClick={onCreate}>
                    <i className="fa fa-plus"/>
                    Add
                </RegularButton>
            </div>
            <DataTable
                filters={filters}
                findAndCount={findAndCount}
                fields={fields}
                onDelete={onDelete}
                onChange={onChange}
                name = {"timeslots"}
                reload={reload}
            />
        </div>
    </TableCard>
};