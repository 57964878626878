import React from "react";
import Card from "../../../generic/Card";
import FileInput from "../../../generic/field/FileInput";
import {Col, Row} from "reactstrap";
import {uploadWeekends} from '../../../../service/inputFile';
import * as notify from "../../../../lib/notify";
import InputFileStep from "../../InputFileStep";
import {Redirect, withRouter} from "react-router-dom";
import LoaderOverlay from "../../../generic/LoaderOverlay";
import RegularButton from "../../../generic/RegularButton";

class Index extends React.Component {

    constructor(props) {
        super(props);
        this.handleClear = this.handleClear.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            file: undefined,
            redirectToItemId: undefined,
            loading: false
        }
    }

    handleClear() {
        this.setState({
            file: undefined
        })
    }

    handleSubmit() {
        const {file} = this.state;

        this.setState({loading: true});
        uploadWeekends(file).then(
            entry => {
                if(entry.error === 'Wrong format') {
                    notify.showDangerToast('Wrong format. Please check format of uploaded file.')
                    return;
                }
                notify.showSuccessToast("File uploaded");
                this.setState({redirectToItemId: entry.id});
            })
            .catch(notify.showDangerToast)
            .finally(() => this.setState({loading: false}));
    }

    render() {

        const {file, redirectToItemId, loading} = this.state;

        if(redirectToItemId) return <Redirect push to={"/input/weekends/upload/" + redirectToItemId}/>

        return (
            <Card title={"Upload new file"}>
                <InputFileStep status="upload"/>
                <LoaderOverlay isVisible={loading}>
                    <Row className="mt-2">
                        <Col xs={12} md={6}>
                            <div className="form-group">
                                <label>File:</label>
                                <FileInput file={file} onSelect={file => this.setState({file})}/>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <RegularButton notComponent type="button" className="btn btn-primary mr-sm-2" onClick={this.handleSubmit}>Submit</RegularButton>
                            <RegularButton notComponent type="button" className="btn btn-outline-secondary" onClick={this.handleClear}>Clear</RegularButton>
                        </Col>
                    </Row>
                </LoaderOverlay>

            </Card>
        );
    }
}

export default withRouter(Index);