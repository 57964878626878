import React, {useEffect, useRef, useState} from 'react';
import { format, isValid, parse } from 'date-fns';
import FocusTrap from 'focus-trap-react';
import { DayPicker } from 'react-day-picker';
import { usePopper } from 'react-popper';

const DatePicker = (props) => {
    const [selected, setSelected] = useState();
    const [inputValue, setInputValue] = useState('');
    const [isPopperOpen, setIsPopperOpen] = useState(false);

    const popperRef = useRef();
    const [popperElement, setPopperElement] = useState();

    const popper = usePopper(popperRef.current, popperElement, {
        placement: 'bottom-start'
    });

    const closePopper = () => {
        setIsPopperOpen(false);
    };

    const handleInputChange = (e) => {
        setInputValue(e.currentTarget.value);
        const date = parse(e.currentTarget.value, 'y.MM.dd', new Date());
        if (isValid(date)) {
            if (props.onDateChange) props.onDateChange(date);
            setSelected(date);
        } else {
            setSelected(undefined);
        }
    };

    const handleClick = () => {
        setIsPopperOpen(true);
    };

    const handleDaySelect = (date) => {
        setSelected(date);
        if (date) {
            if (props.onDateChange) props.onDateChange(date);
            setInputValue(format(date, 'y.MM.dd'));
            closePopper();
        } else {
            setInputValue('');
        }
    };

    useEffect(() => {
        if (!props.selectedDay) {
            setInputValue('');
        } else {
            setInputValue(format(props.selectedDay, 'y.MM.dd'));
        }
    }, [props.selectedDay]);

    return (
        <div>
            <div ref={popperRef} className='DayPickerInput'>
                <input
                    size={12}
                    type="text"
                    placeholder={props.placeholder || format(new Date(), 'y.MM.dd')}
                    value={inputValue}
                    onChange={handleInputChange}
                    onClick={handleClick}
                    className='form-control form-control'
                />
            </div>
            {isPopperOpen && (
                <FocusTrap
                    active
                    focusTrapOptions={{
                        initialFocus: false,
                        allowOutsideClick: true,
                        clickOutsideDeactivates: true,
                        onDeactivate: closePopper,
                    }}
                >
                    <div
                        tabIndex={-1}
                        style={{ ...popper.styles.popper, zIndex: 999 }}
                        className="dialog-sheet"
                        {...popper.attributes.popper}
                        ref={setPopperElement}
                        role="dialog"
                        aria-label="DayPicker calendar"
                    >
                        <DayPicker
                            id={props.id}
                            mode="single"
                            className={'form-control ' + props.classNames}
                            defaultMonth={selected}
                            selected={selected}
                            onSelect={handleDaySelect}
                        />
                    </div>
                </FocusTrap>
            )}
        </div>
    );
}

export default DatePicker;