import React from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap';
import PropTypes from "prop-types";
import RegularButton from "./RegularButton";

class ModalConfirmation extends React.Component {
    render() {
        const {onConfirm, onClose, isOpen, areButtonsNeeded} = this.props;
        return (
            <Modal isOpen={isOpen} toggle={onClose} className={this.props.className}>
                <ModalHeader>Confirmation</ModalHeader>
                <ModalBody>
                    {this.props.text}
                </ModalBody>
                <ModalFooter>
                  {
                    areButtonsNeeded &&
                      <div>
                        <RegularButton color={this.props.color} onClick={onConfirm}>{this.props.confirmButtonText}</RegularButton>{' '}
                        <Button color="secondary" onClick={onClose}>Cancel</Button>
                      </div>
                  }
                </ModalFooter>
            </Modal>
        );
    }
}
ModalConfirmation.propTypes = {
    text: PropTypes.node,
    isOpen: PropTypes.bool,
    onConfirm: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    className: PropTypes.string,
    closeModalConfirmation: PropTypes.func,
    confirmButtonText: PropTypes.string,
    color: PropTypes.string,
    areButtonsNeeded: PropTypes.bool,
    disabled: PropTypes.bool
};

ModalConfirmation.defaultProps = {
    confirmButtonText: "Delete",
    color: "danger",
    isOpen: false,
    areButtonsNeeded: true
};

export default ModalConfirmation;