import React from 'react'
import { useQueryParam, StringParam } from 'use-query-params';
import TableCard from "../generic/TableCard";
import Table from "./Table";
import TextSearch from "../generic/field/TextSearch";
import {Button} from "reactstrap";

export default function Index() {

    const [user, setUser] = useQueryParam('user', StringParam);
    const [entity, setEntity] = useQueryParam('entity', StringParam);
    const [url, setUrl] = useQueryParam('url', StringParam);

    const dropFilters = () => {
        setUser(undefined);
        setEntity(undefined);
        setUrl(undefined);
    };

    return (<TableCard title={"Audit journal"}>
        <div className="form-inline mb-2">
            <TextSearch value={user} className="form-control col-sm-3 mr-2" onSearch={setUser}
                        placeholder="User email"/>
            <TextSearch value={entity} className="form-control col-sm-3 mr-2" onSearch={setEntity}
                        placeholder="Entity"/>
            <TextSearch value={url} className="form-control col-sm-3 mr-2" onSearch={setUrl}
                        placeholder="URL"/>
            <Button className="mr-sm-2" color="default" onClick={ dropFilters }>
                <i className="fa fa-fw fa-times"/>
                Cancel
            </Button>
        </div>
        <Table user={user} entity={entity} url={url} />
    </TableCard>);
}