import React, {useState} from "react";
import DataTable, {field} from "../generic/DataTable";
import {findAndCount} from "../../service/inputFile";
import {InputFileStatusBadge} from "./InputFileStatus";
import {datetime} from "../../lib/format";
import user from "../../service/user";
import PropTypes from "prop-types";

function InputFileHistory(props) {
    const { onClick, entity } = props;
    const [users, setUsers] = useState(undefined);

    const filters = {
        onClick,
        entity,
        ...props.filters
    }

    const loadUsers = async () => {
        const users = await user.findAndCount(0, 1000, 'id', 'desc');
        setUsers(users);
    }

    useState(() => {
        loadUsers();
    }, [])

    const getUserName = response => {
        if(users && users.data) {
            const data = users.data.find(user => user.id === response.user);
            if(data) {
                return data.username;
            } else {
                return undefined;
            }
        }
    }

    return <DataTable
        filters={filters}
        findAndCount={findAndCount}
        onClick={props.onClick}
        fields={[
            field("id", true),
            field("File Name", true, "fileName", res => res.file && res.file.name),
            field("Status", true, "status", res => <InputFileStatusBadge value={res.status}/>),
            field("Date", true, "date",res => datetime(res.date)),
            field("User", true, "user", response => getUserName(response)),
            field("Updated at", true, "updated_at", res => datetime(res.updated_at)),
            field("Created at", true, "created_at", res => datetime(res.created_at))
        ]}
        name="inputFileHistory"
    />;
}

InputFileHistory.propTypes = {
    onClick: PropTypes.func,
    entity: PropTypes.oneOf(["delivery_matrix"]).isRequired,
    filters: PropTypes.object
}

InputFileHistory.defaultProps = {
    filters: {}
};

export default InputFileHistory;