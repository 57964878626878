import React from "react";
import PrivateRoute from '../../../route/PrivateRoute';
import { SubMenuLink } from '../../generic/links';
import alternativeProductsData from './alternativeProductsData/Index';
import Upload from './upload/Route';
import { Redirect, Switch } from "react-router-dom";

const Index = props => {
    return (
        <div>
            <ul className="nav nav-tabs">
                <SubMenuLink to={ "/input/alternativeProducts/upload" } { ...props }>Upload</SubMenuLink>
            </ul>
            <Switch>
                <Redirect push exact from="/input/alternativeProducts" to="/input/alternativeProducts/upload"/>
                <PrivateRoute path="/input/alternativeProducts/alternativeProductsData" component={ alternativeProductsData }/>
                <PrivateRoute path="/input/alternativeProducts/upload" component={ Upload }/>
            </Switch>
        </div>
    )
}

export default Index;