import React from "react";
import moment from "moment";
import DataTable, { field } from "../generic/DataTable";
import { exportCORSData, findAndCount } from "../../service/cors";
import ExportFunButton from "../generic/ExportFunButton";
import PropTypes from "prop-types";

const Table = ({ filters, onChange, onDelete, reload }) => {
    return (
        <DataTable
            filters={ filters }
            findAndCount={ findAndCount }
            fields={[
                field('CORS', true, 'domain'),
                field("Date", true, "date", res => moment(res.date).format('YYYY-MM-DD HH:mm'))
            ]}
            tableButtons={ dataParameters => <ExportFunButton dataParameters={ dataParameters } exportFun={ exportCORSData }/> }
            name={ "CORS" }
            onChange={ onChange }
            onDelete={ onDelete }
            reload={ reload }
        />
    );
}

Table.propTypes = {
    filters: PropTypes.object.isRequired,
    onDelete: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    reload: PropTypes.bool
}

export default Table;