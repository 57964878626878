import * as React from 'react';
import DataTable, { field } from '../generic/DataTable';
import { findAndCount } from '../../service/publicTokens';
import { Link, useHistory } from 'react-router-dom';
import { deleteEntry } from '../../service/publicTokens';
import { useState } from 'react';
import TableCard from '../generic/TableCard';
import * as notify from '../../lib/notify';

const PublicTokensTable = () => {
  const [reload, setReload] = useState(false);
  const history = useHistory();

  const onDelete = ({ id }) => deleteEntry(id)
    .then(() => {
      setReload(!reload);
      notify.showSuccessToast("Success");
    });

  return (
    <TableCard title='Public Tokens'>
      <Link className="btn btn-primary" to="/administration/public-tokens/edit">
        <i className="fa fa-plus"/> Add
      </Link>
      <DataTable
        findAndCount={findAndCount}
        fields={[
          field('Name', false, 'name'),
          field('Token', false, 'token'),
        ]}
        onDelete={onDelete}
        onChange={x => history.push(`/administration/public-tokens/edit/${x.id}`)}
        reload={reload}
      />
    </TableCard>
  )
}

export default PublicTokensTable;