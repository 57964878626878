import React from "react";
import PropTypes from 'prop-types';
import DataTable, {field} from "../../../generic/DataTable";
import {findAndCount} from "../../../../service/productsLimitsRaw";

export default class Table extends React.Component {

    render() {
        const filters = {};
        const {uploadId} = this.props;

        if (uploadId) filters.input_file_id = uploadId;

        return (<DataTable
            filters={filters}
            findAndCount={findAndCount}
            fields={[
                field("id", true),
                field("BVCode", true, "bvCode"),
                field("Limit", true, "limit"),
                ]}
            name={"productsLimitsRaw"}
        />);
    }
}

Table.propTypes = {
    uploadId: PropTypes.number
};