import React from 'react'
import {Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import useInput from "../../hooks/useInput";
import "./styles.css";
import PropTypes from 'prop-types'
import {createEntry} from "../../service/methodFolders";
import {showWarningToast} from "../../lib/notify";
import RegularButton from "../generic/RegularButton";

const CFolderComponent = ({closeModalConfirmation, isOpen, onToggle, loadMethodFolders}) => {

  const {value: folder, bind: bindFolder} = useInput(undefined);

  const onSave = () => {
    const data = {
      folder
    }
    createEntry(data).then(closeModalConfirmation).then(loadMethodFolders).catch(() => showWarningToast('Something went wrong.'));
  }

  return <Modal isOpen={isOpen} toggle={onToggle}>
    <ModalHeader>Create folder</ModalHeader>
    <ModalBody>
        <FormGroup>
          <Label for="folder">Folder name</Label>
          <Input type="text" name="folder" id="folder" placeholder="Enter folder name ..." {...bindFolder} />
        </FormGroup>
    </ModalBody>
    <ModalFooter>
      <RegularButton color="primary" onClick={onSave}>Save</RegularButton>{' '}
      <Button color="secondary" onClick={closeModalConfirmation}>Cancel</Button>
    </ModalFooter>
  </Modal>
}

CFolderComponent.propTypes = {
  isOpen: PropTypes.bool,
  closeModalConfirmation: PropTypes.func,
  loadMethodFolders: PropTypes.func
}

export default CFolderComponent;