import DataTable, {field} from "../../../../generic/DataTable";
import {
    findAndCount,
} from "../../../../../service/deliverymatrixactivations";
import {datetime} from "../../../../../lib/format";
import React, {useState} from "react";
import PropTypes from "prop-types";
import EDComponent from "./EDComponent";

const Index = ({ matrixId, reload, setReload }) => {
    const [isModalOpen, setIsModalOpen] = useState(undefined);
    const [modalType, setModalType] = useState(undefined);
    const [rowId, setRowId] = useState(undefined);
    const closeModalConfirmation = () => {
        setRowId(undefined);
        setModalType(undefined);
        setIsModalOpen(false);
    }

    return (
        <div className="tab-pane fade" id="future-activations" role="tabpanel" aria-labelledby="profile-tab">
            <div className="media">
                <div className="media-body">
                    <EDComponent isModalOpen={isModalOpen} setReload={setReload} closeModalConfirmation={closeModalConfirmation} modalType={modalType} rowId={rowId} matrixId={matrixId} />
                    <DataTable
                        filters={{ matrixId }}
                        findAndCount={findAndCount}
                        fields={[
                            field("ID", true, "id"),
                            field("Activation Time", false, "activationDatetime", response => datetime(response.activationDatetime)),
                            field("Deactivation Time", false, "deactivationDatetime", response => datetime(response.deactivationDatetime))
                        ]}
                        onChange={ response => {
                            setRowId(response.id);
                            setModalType('update');
                            setIsModalOpen(true);
                        } }
                        onDelete={response => {
                            setRowId(response.id)
                            setModalType('delete');
                            setIsModalOpen(true);
                        } }
                        reload={ reload }
                    />
                </div>
            </div>
        </div>
    )
}

export default Index;

Index.propTypes = {
    filters: PropTypes.object,
    reload: PropTypes.bool,
    setReload: PropTypes.func
}