import React, {Fragment} from "react";
import Card from "../../../generic/Card";
import {Col, Row} from "reactstrap";
import Loader from "../../../generic/Loader";
import InputFileCard from "../../InputFileCard";
import InputFileProcess from "../../InputFileProcess";
import InputFileDatabaseCard from "../../InputFileDatabaseCard";
import DeliveryMatrixDataTable from "../deliveryMatrixData/Table";
import DeliveryMatrixRawTable from "../deliveryMatrixRaw/Table";
import {Redirect} from "react-router-dom";
import MappingIssues from "./raw/MappingIssues";
import MappingModal from "./MappingModal";

class Index extends InputFileProcess {

    constructor(props) {
        super(props);

        this.reloadRawTable = this.reloadRawTable.bind(this);
        this.openMappingModal = this.openMappingModal.bind(this);
        this.toggleMappingModal = this.toggleMappingModal.bind(this);
        this.state = {
            reload: false,
            isMappingModalOpened: false
        }
    }

    openMappingModal(code) {
        this.setState({
            isMappingModalOpened: true,
            code
        })
    }

    toggleMappingModal() {
        this.setState({
            isMappingModalOpened: false
        })
    }

    reloadRawTable() {
        this.setState({
            reload: !this.state.reload
        })
    }


    render() {
        const {loading, deleted, inputFile, reload, isMappingModalOpened, code} = this.state;
        if (loading) return <Card title={"Process file"}><Loader/></Card>;
        if (deleted) return <Redirect push to={"/input/deliveryMatrix/upload"}/>;
        if (!inputFile) return "";

        const {status} = inputFile;
        return (
            <Fragment>
                {super.render()}
                <br/>
                <Row>
                    <Col lg={["success", "loading", "mapping"].includes(status) ? 6 : 12} md={6}>
                        <InputFileCard inputFile={inputFile} onCancel={this.onConfirmCancel}
                                       onDelete={this.onConfirmDelete}
                                       onConfirm={this.onConfirm}/>
                    </Col>
                    {status === "mapping" && <Col lg={6} md={12}>
                        <MappingIssues inputFileId={inputFile.id} openMappingModal={this.openMappingModal}
                                       reload={reload} onConfirm={this.onConfirm} saveMapping={this.saveMappingModal} reloadRawTable={this.reloadRawTable}/>
                    </Col>}
                    {["success", "loading"].includes(status) && <Col lg={6} md={6}>
                        <InputFileDatabaseCard inputFile={inputFile}/>
                    </Col>}
                </Row>
                <br/>

                <Fragment>

                    {
                        status === "success" ?
                            <Card title={"Database load result"}>
                                <DeliveryMatrixDataTable uploadId={inputFile.id} isNew={true}/>
                            </Card> :
                            <Card title={"Raw file data"}>
                                <DeliveryMatrixRawTable uploadId={inputFile.id} isNew={true}/>
                            </Card>
                    }
                </Fragment>
                <MappingModal isOpen={isMappingModalOpened} onToggle={this.toggleMappingModal}
                              onSave={this.saveMappingModal} reloadRawTable={this.reloadRawTable} code={code}
                />

            </Fragment>
        );
    }
}

export default Index;