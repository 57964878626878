import React from "react";
import DataTable, {field} from "../generic/DataTable";
import { findAndCount } from "../../service/stocks";
import { datetime } from "../../lib/format";
import PropTypes from "prop-types";

const Table = ({ filters }) => {
    return (
        <DataTable
            filters={filters}
            findAndCount={findAndCount}
            fields={[
                field('BV Code', true, 'bvCode'),
                field("Available QTY", true, "availableQuantity"),
                field('Warehouse Code', true, 'warehouseCode'),
                field("Warehouse Name", true, "warehouseName"),
                field("Creation Date", true, "creationDate", response => datetime(response.creationDate)),
                field("DM Creation Date", true, "created_at", response => datetime(response.created_at))
            ]}
            name={"stocks"}
        />
    );
}

Table.propTypes = {
    filters: PropTypes.object
}

export default Table;