import React from 'react'
import TableCard from "../../generic/TableCard";
import Table from "./Table";
import ReactQueryParams from 'react-query-params';

export default class Index extends ReactQueryParams {

    render() {
        return <TableCard title={"Delivery Matrices"}>
                <Table />
        </TableCard>
    }
};

