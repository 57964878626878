import React from "react";
//import TableCard from "../../generic/TableCard";
import TableCard from "../../generic/TableCard";
import Table from "./Table";

class Index extends React.Component {

    render() {
        return (
            <TableCard title={"Roles"}>
                <div style={{minHeight: "400px"}}>
                    <Table/>
                </div>
            </TableCard>
        )
    }
}

export default Index;