export const routes = [
    {
        title: "Dashboard",
        to: "/",
        icon: "home"
    },
    {
        title: "Delivery",
        to: "/delivery",
        icon: "file-alt",
        children: [
            {
                title: "Delivery Date",
                to: "/delivery/deliveryDate"
            }
        ]
    },
    {
        title: "Catalog",
        to: "/catalog",
        icon: "table",
        children: [
            {
                title: "Delivery Matrices",
                to: "/catalog/deliveryMatrices"
            },
            {
                title: "Delivery Method",
                to: "/catalog/reference/deliveryMethod"
            },
            {
                title: "Payment Method",
                to: "/catalog/reference/paymentMethod"
            },
            {
                title: "Products",
                to: "/catalog/products"
            },
            {
                title: "Delivery Method Products",
                to: "/catalog/deliveryMethodProducts"
            },
            {
                title: "Warehouses",
                to: "/catalog/warehouses"
            },
            {
                title: "Stocks",
                to: "/catalog/stocks"
            },
            {
                title: "Orders",
                to: "/catalog/orders"
            },
            {
              title: "Alternative products",
              to: "/catalog/alternativeProducts"
            },
            {
              title: "Time slots",
              to: "/catalog/timeSlots"
            }
        ]
    },
    {
        title: "Input",
        to: "/input",
        icon: "list-ul",
        children: [
            {
                title: "Delivery Matrix",
                to: "/input/deliveryMatrix"
            },
            {
                title: "Weekends",
                to: "/input/weekends"
            },
            {
                title: "Delivery method products",
                to: "/input/deliveryMethodProducts"
            },
            {
                title: "Stocks",
                to: "/input/stocks"
            },
            {
              title: "Alternative products",
              to: "/input/alternativeProducts"
            },
            {
                title: "Products limits",
                to: "/input/productsLimits"
            }
        ]
    },
    {
        title: "Reporting",
        to: "/reporting",
        icon: "file-excel",
        children: [
            {
                title: "Stocks",
                to: "/reporting/stocks"
            }
        ]
    },
    {
        title: "Administration",
        to: "/administration",
        icon: "user",
        children: [
            {
                title: "User",
                to: "/administration/user"
            },
            {
                title: "Roles",
                to: "/administration/roles"
            },
            {
                title: "CORS",
                to: "/administration/cors"
            },
            {
                title: "Delivery matrix logs",
                to: "/administration/deliverymatrixlogs"
            },
            {
                title: "Delivery method logs",
                to: "/administration/deliverymethodlogs"
            },
            {
                title: "Journal",
                to: "/administration/journal"
            },
            {
                title: "Public Tokens",
                to: "/administration/public-tokens"
            }
        ],
        access: ["IT System Support"]
    }
];

const flattenRoutesFun = (source, parent) =>
    source.reduce((acc, route) => {
        const builtRoute = {
            title: route.title,
            to: route.to,
            breadcrumbs: ((parent && parent.breadcrumbs) || []).concat([{title: route.title, to: route.to, icon: route.icon}])
        };
        return acc.concat(builtRoute).concat(flattenRoutesFun(route.children || [], builtRoute))
    }, []);

const flattenRoutes = flattenRoutesFun(routes).reverse();

export const getCurrentRoute = to =>
    flattenRoutes.find(route => to.startsWith(route.to));