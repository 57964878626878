import DataTable, {field} from "../../generic/DataTable";
import {
    createMethodActivation,
    findAndCount,
} from "../../../service/deliverymethodactivations";
import {datetime} from "../../../lib/format";
import React, {useState} from "react";
import PropTypes from "prop-types";
import EDComponent from "./EDComponent";
import DatepickerWithTime from "../../generic/field/DatepickerWithTime";
import * as notify from "../../../lib/notify";
import RegularButton from "../../generic/RegularButton";

const Index = ({ methodId, methodCode }) => {
    const [isModalOpen, setIsModalOpen] = useState(undefined);
    const [modalType, setModalType] = useState(undefined);
    const [rowId, setRowId] = useState(undefined);
    const [methodActivationDate, setMethodActivationDate] = useState(undefined);
    const [methodActivationTime, setMethodActivationTime] = useState(undefined);
    const [methodDeactivationDate, setMethodDeactivationDate] = useState(undefined);
    const [methodDeactivationTime, setMethodDeactivationTime] = useState(undefined);
    const [reload, setReload] = useState(undefined);

    const findAndCountOptimized = (...args) => {
        if (methodId !== null) {
            return findAndCount(...args);
        }
        return Promise.resolve({total: 0, data: []})
    }

    const closeModalConfirmation = () => {
        setRowId(undefined);
        setModalType(undefined);
        setIsModalOpen(false);
    }

    return (
        <div className="media">
            <div className="media-body">
                <div className={"form-inline pl-2 ml-1 mb-3 mt-3"}>
                    <DatepickerWithTime
                        datepickerDaySelected={methodActivationDate}
                        datepickerPlaceholder={'Choose start date'}
                        datepickerSearchFunction={value => setMethodActivationDate( value )}
                        timepickerPlaceholder={'Start time'}
                        timepickerSearchFunction={ value => {
                            if (value) {
                                setMethodActivationTime( value.format('HH:mm') )
                            } else {
                                setMethodActivationDate( undefined )
                                setMethodActivationTime( undefined )
                            }
                        }}
                        timepickerValue={methodActivationTime}
                        className={'startDate'}
                    />
                    <label className="ml-3 mr-3 col-form-label-sm"> — </label>
                    <DatepickerWithTime
                        datepickerDaySelected={methodDeactivationDate}
                        datepickerPlaceholder={'Choose end date'}
                        datepickerSearchFunction={value => setMethodDeactivationDate(value)}
                        timepickerPlaceholder={'End time'}
                        timepickerSearchFunction={value => {
                            if (value) {
                                setMethodDeactivationTime( value.format('HH:mm') )
                            } else {
                                setMethodDeactivationDate( undefined )
                                setMethodDeactivationTime( undefined )
                            }
                        }}
                        timepickerValue={methodDeactivationTime}
                        className={'endDate'}
                    />
                </div>
                <RegularButton color="primary" className="mb-3" onClick={() => {
                    if((methodActivationDate && methodActivationTime) || (methodDeactivationDate && methodDeactivationTime)) {
                        createMethodActivation({
                            methodActivationDate,
                            methodActivationTime,
                            methodDeactivationDate,
                            methodDeactivationTime,
                            methodId,
                            code: methodCode
                        })
                            .then(() => notify.showSuccessToast('Delayed activation was successful'))
                            .then(() => {
                                setMethodActivationDate(undefined);
                                setMethodActivationTime(undefined);
                                setMethodDeactivationDate(undefined);
                                setMethodDeactivationTime(undefined);
                                setReload(!reload);
                            })
                            .catch(error => notify.showDangerToast(error.message));
                    }
                }}>
                    Past to schedule
                </RegularButton>
                <EDComponent isModalOpen={isModalOpen} setReload={setReload} closeModalConfirmation={closeModalConfirmation} modalType={modalType} rowId={rowId} methodId={methodId} />
                <DataTable
                    filters={{ methodId }}
                    findAndCount={findAndCountOptimized}
                    fields={[
                        field("ID", true, "id"),
                        field("Activation Time", false, "activationDatetime", response => datetime(response.activationDatetime)),
                        field("Deactivation Time", false, "deactivationDatetime", response => datetime(response.deactivationDatetime))
                    ]}
                    onChange={ response => {
                        setRowId(response.id);
                        setModalType('update');
                        setIsModalOpen(true);
                    } }
                    onDelete={response => {
                        setRowId(response.id)
                        setModalType('delete');
                        setIsModalOpen(true);
                    } }
                    reload={reload}
                />
            </div>
        </div>
    )
}

export default Index;

Index.propTypes = {
    methodId: PropTypes.number
}