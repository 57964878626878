import React from "react";
import {Controller} from "react-hook-form";
import PropTypes from "prop-types";
import {Label} from "reactstrap";
import {Typeahead} from "react-bootstrap-typeahead";

const onChoose = (fn) => (chosen) => {
    const [item] = chosen;

    if (item) {
        return fn(item.code)
    } else {
        return fn(undefined)
    }
}

const getDefaultValue = (options, value) => {
    if (!value) return undefined

    const option = options.find((it) => it.code === value)

    if (option) {
        return {code: option.code, label: option.code}
    }

    return undefined
}

const TypeaheadCustom = ({it, control, defaultInputValue, options, placeholder, label, error }) => {
    const defaultValue = getDefaultValue(options, defaultInputValue);
    options = options.map(it => {
        it.label = it.code;
        return it;
    })

    return (
        <div className="col-lg-4 col-md-6 mb-3 mt-auto">
            <Label>{label}</Label>
            <Controller
                control={control}
                name={it.id}
                defaultValue={defaultValue && defaultValue.code}
                rules={{required: {value: true, message: 'You need to choose from the list'}}}
                render={({onChange}) => (
                    <Typeahead
                        id={it.id}
                        options={options}
                        placeholder={placeholder}
                        defaultInputValue={defaultValue && defaultValue.label}
                        onChange={onChoose(onChange)}
                    />
                )}
            />
            {error && <span className="input-error">{error.message}</span>}
        </div>)
}

TypeaheadCustom.propTypes = {
    control: PropTypes.object.isRequired,
    it: PropTypes.object.isRequired,
    defaultValue: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    options: PropTypes.array
}

export default React.memo(TypeaheadCustom);