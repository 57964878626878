import React from "react";
import PropTypes from 'prop-types';
import DataTable, {field} from "../../../../generic/DataTable";
import Card from "../../../../generic/Card";
import './Tables.css';
import {findAndCount} from "../../../../../service/deliveryMatrixRaw"
import RegularButton from "../../../../generic/RegularButton";

export default class MappingIssues extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modelsMap: {},
            reload: false
        };
    }

    render() {
        const filters = {};
        const {inputFileId, reload, onConfirm} = this.props;

        const modelCell = res => !res ? "" :
            <div className="buttons btn-group">
                <RegularButton type="button" className="btn btn-success btn-xs" onClick={() => this.props.openMappingModal(res.courier_company)}>
                    <i className="fa fa-marker" />
                </RegularButton>
            </div>;

        filters.input_file_id = inputFileId;
        filters.courier_company = null;

        return <Card title="Mapping issues">
            <DataTable
                limit={5}
                filters={filters}
                findAndCount={findAndCount}
                fields={[
                    field("Courier company", false, "courier_company"),
                    field("Code", false, "code",
                        res => modelCell(res)),
                ]}
                rowClass={() => "table-danger"}
                reload={reload}
                emptyContent={
                    <p className="text-center">
                        <i className="fa fa-check-square fa-6x" style={{color: "#0acf97"}}/>
                        <br/>
                        All records are mapped
                        <br/>
                        <br/>
                        <RegularButton onClick={onConfirm} color="success">Confirm and Load</RegularButton>
                    </p>
                }
                totalRowRender={() => ""}
                name={"mapping_issues"}
            />
        </Card>
    }
}

MappingIssues.propTypes = {
    inputFileId: PropTypes.number.isRequired,
    openMappingModal: PropTypes.func.isRequired,
    reload: PropTypes.any.isRequired,
    onConfirm: PropTypes.func.isRequired
};