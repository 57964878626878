import React from "react";
import {values} from "./InputFileStatus";
import PropTypes from "prop-types";
import {Nav, NavItem, NavLink} from "reactstrap";

class InputFileStep extends React.Component {

    render() {
        const {status,failed} = this.props;
        return <Nav pills={true} fill={true} className={failed ? "nav-pills-danger" : "nav-pills-primary"}>
            <NavItem>
                <NavLink href="#" active={status === "upload"}>1. Upload file</NavLink>
            </NavItem>
            <NavItem>
                <NavLink href="#" active={status === "process"}>2. Process</NavLink>
            </NavItem>
            <NavItem>
                <NavLink href="#" active={status === "mapping"}>3. Check</NavLink>
            </NavItem>
            <NavItem>
                <NavLink href="#" active={status === "loading"}>4. Load</NavLink>
            </NavItem>
            <NavItem>
                <NavLink href="#" active={["success", "fail", "cancel"].includes(status)}>5. Result</NavLink>
            </NavItem>
        </Nav>
    }
}

InputFileStep.propTypes = {
    status: PropTypes.oneOf(values).isRequired,
    failed: PropTypes.number
};

export default InputFileStep