import React, {Fragment} from "react";
import Card from "../../../generic/Card";
import {Col, Row} from "reactstrap";
import Loader from "../../../generic/Loader";
import InputFileCard from "../../InputFileCard";
import InputFileProcess from "../../InputFileProcess";
import InputFileDatabaseCard from "../../InputFileDatabaseCard";
import WeekendsDataTable from "../weekendsData/Table";
import WeekendsRawTable from "../weekendsRaw/Table";
import {Redirect} from "react-router-dom";

class Index extends InputFileProcess {

    constructor(props) {
        super(props);

        this.reloadRawTable = this.reloadRawTable.bind(this);
        this.state = {
            reload: false
        }
    }


    reloadRawTable() {
        this.setState({
            reload: !this.state.reload
        })
    }


    render() {
        const {loading, deleted, inputFile} = this.state;
        if (loading) return <Card title={"Process file"}><Loader/></Card>;
        if (deleted) return <Redirect push to={"/input/weekends/upload"}/>;
        if (!inputFile) return "";

        const {status} = inputFile;
        return (
            <Fragment>
                {super.render()}
                <br/>
                <Row>
                    <Col lg={["success", "loading"].includes(status) ? 6 : 12} md={12}>
                        <InputFileCard inputFile={inputFile} onCancel={this.onConfirmCancel}
                                       onDelete={this.onConfirmDelete}
                                       onConfirm={this.onConfirm}/>
                    </Col>

                    {["success", "loading"].includes(status) && <Col lg={6} md={12}>
                        <InputFileDatabaseCard inputFile={inputFile}/>
                    </Col>}
                </Row>
                <br/>

                <Fragment>

                    {
                        status === "success" ?
                            <Card title={"Database load result"}>
                                <WeekendsDataTable uploadId={inputFile.id} isNew={true}/>
                            </Card> :
                            <Card title={"Raw file data"}>
                                <WeekendsRawTable uploadId={inputFile.id} isNew={true}/>
                            </Card>
                    }
                </Fragment>

            </Fragment>
        );
    }
}

export default Index;