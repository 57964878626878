import React from "react";
import Card from "../generic/Card";
import {Col, Row} from "reactstrap";
import {apiUrl} from "../../lib/strapi";
import {InputFileStatusBadge} from "./InputFileStatus";
import PropTypes from "prop-types";
import ButtonWithConfirm from "../generic/ButtonWithConfirm";
import RegularButton from "../generic/RegularButton";


class InputFileCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            status: undefined
        };
    }


    componentWillMount() {
    }

    render() {
        const {inputFile, onConfirm, onCancel} = this.props;

        const {status, file} = inputFile;
        return <Card title="File status">
            <Row className="mt-2">
                <Col xs={12} md={12} lg={6}>

                    <div className="form-group">
                        <label>Status:</label> <InputFileStatusBadge
                        value={this.state.status ? this.state.status : status} className="mb-2"/>
                    </div>


                    <div className="form-group">
                        <label>File:</label>
                        &nbsp;
                        {file && <a href={apiUrl + file.url} title="скачать"
                                    download={file.name}>
                            {file.name}&nbsp;
                            <i className="fa fa-download"/>
                        </a>}
                    </div>
                </Col>

                <Col xs={12} md={12} lg={6}>
                    <div className="form-group">
                        <label>Total rows:</label> {inputFile.rows}
                    </div>
                    <div className="form-group">
                        <label>Good rows:</label> {inputFile.rowsGood}
                    </div>
                    <div className="form-group">
                        <label>Bad rows:</label> {inputFile.rowsBad}
                    </div>
                    <div className="form-group">
                        <label>Processed:</label> {inputFile.rowsProcessed}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>

                    {
                        ["upload", "process", "mapping", "loading"].includes(status) &&
                        <ButtonWithConfirm onClick={() => {
                            this.setState({
                                showWarning: false
                            });
                            this.props.onCancel();
                        }}
                                           className="mr-2"
                                           color="outline-danger"
                                           modalText="Do you want to cancel this upload?">
                            Cancel
                        </ButtonWithConfirm>
                    }

                    {
                        ["fail", "cancel"].includes(status) &&
                        <ButtonWithConfirm onClick={() => {
                            this.setState({
                                showWarning: false
                            });
                            this.props.onDelete();
                        }}
                                           className="mr-2"
                                           color="outline-danger"
                                           modalText="Do you want to delete this upload?">
                            Delete
                        </ButtonWithConfirm>
                    }

                    {
                        status === "mapping" &&
                        <RegularButton className="mr-2"
                                onClick={onConfirm}
                                color="outline-success">
                            Confirm and Load
                        </RegularButton>
                    }

                    {
                        ((status === "success")) &&
                        <ButtonWithConfirm
                            onClick={() => {
                                this.setState({
                                    showWarning: false
                                });
                                onCancel();
                            }}
                            className="mr-2"
                            color="outline-danger"
                            modalText={
                                <p>Do you want to <b>withdraw</b> this upload?<br/>
                                    All data in database, loaded from this file, will be deleted</p>
                            }>
                            Withdraw
                        </ButtonWithConfirm>
                    }

                </Col>
            </Row>
        </Card>
    }
}

InputFileCard.propTypes = {
    inputFile: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    reload: PropTypes.any
};

export default InputFileCard;

