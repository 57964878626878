import DatePicker from "./DatePicker";
import TimePicker from "rc-time-picker";
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import moment from "moment";

export default function DatepickerWithTime({ datepickerDaySelected, datepickerSearchFunction, datepickerPlaceholder, timepickerPlaceholder, timepickerSearchFunction, timepickerValue, className }) {
    const additionalProps = {};

    if(timepickerValue) {
        timepickerValue = timepickerValue.split(':');

        additionalProps.value = moment().set({
            hours: timepickerValue[0],
            minutes: timepickerValue[1]
        });
    } else {
        additionalProps.value = undefined;
    }

    return (
        <div className={classNames('row', className)}>
            <DatePicker
                classNames="form-control"
                placeholder={ datepickerPlaceholder }
                selectedDay={ datepickerDaySelected } onDateChange={ datepickerSearchFunction }
            />
            <TimePicker placeholder={ timepickerPlaceholder } className="ml-2 mr-2 timePicker" showSecond={false} minuteStep={30} inputIcon={false} clearIcon={false} onChange={timepickerSearchFunction} {...additionalProps} />
        </div>
    );
}

DatepickerWithTime.propTypes = {
    datepickerDaySelected: PropTypes.object,
    datepickerSearchFunction: PropTypes.func,
    timepickerSearchFunction: PropTypes.func,
    datepickerPlaceholder: PropTypes.string,
    timepickerPlaceholder: PropTypes.string,
    className: PropTypes.string
}