import React from "react";
import PrivateRoute from '../../../route/PrivateRoute';
import {SubMenuLink} from '../../../components/generic/links';
import weekendsData from './weekendsData/Index';
import Upload from './upload/Route';
import {Redirect, Switch} from "react-router-dom";

class Index extends React.Component {
    render() {
        return (
            <div>
                <ul className="nav nav-tabs">
                    <SubMenuLink to={"/input/weekends/upload"} {...this.props}>Upload</SubMenuLink>
                    <SubMenuLink to={"/input/weekends/weekendsData"} {...this.props}>Weekends Data</SubMenuLink>
                </ul>
                <Switch>
                    <Redirect push exact from="/input/weekends" to="/input/weekends/upload"/>
                    <PrivateRoute path="/input/weekends/weekendsData" component={weekendsData}/>
                    <PrivateRoute path="/input/weekends/upload" component={Upload}/>
                </Switch>
            </div>
        )
    }
}

export default Index;