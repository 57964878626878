
export const showSuccessToast = function(msg) {
  resetToastPosition();
  window.$.toast({
    heading: 'Success',
    text: msg,
    showHideTransition: 'slide',
    icon: 'success',
    loaderBg: '#f96868',
    position: 'top-right'
  })
};
export const showInfoToast = function($) {
  resetToastPosition();
  window.$.toast({
    heading: 'Info',
    text: 'And these were just the basic demos! Scroll down to check further details on how to customize the output.',
    showHideTransition: 'slide',
    icon: 'info',
    loaderBg: '#46c35f',
    position: 'top-right'
  })
};
export const  showWarningToast = function(msg) {
  resetToastPosition();
  window.$.toast({
    heading: 'Warning',
    text: msg,
    showHideTransition: 'slide',
    icon: 'warning',
    loaderBg: '#57c7d4',
    position: 'top-right'
  })
};
export const  showDangerToast = error => {
  resetToastPosition();
  if(error){
    window.$.toast({
      heading: 'Error',
      text: error.message||error,
      showHideTransition: 'slide',
      icon: 'error',
      loaderBg: '#f2a654',
      position: 'top-right'
    })
  }

};
export const  showToastPosition = function(position) {
  resetToastPosition();
  window.$.toast({
    heading: 'Positioning',
    text: 'Specify the custom position object or use one of the predefined ones',
    position: String(position),
    icon: 'info',
    stack: false,
    loaderBg: '#f96868'
  })
}
export const  showToastInCustomPosition = function($) {
  resetToastPosition();
  window.$.toast({
    heading: 'Custom positioning',
    text: 'Specify the custom position object or use one of the predefined ones',
    icon: 'info',
    position: {
      left: 120,
      top: 120
    },
    stack: false,
    loaderBg: '#f96868'
  })
}
export const   resetToastPosition = function($) {
  window.$('.jq-toast-wrap').removeClass('bottom-left bottom-right top-left top-right mid-center'); // to remove previous position class
  window.$(".jq-toast-wrap").css({
    "top": "",
    "left": "",
    "bottom": "",
    "right": ""
  }); //to remove previous position style
}
