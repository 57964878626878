import React, { useState } from "react";
import InputFileHistory from "../../InputFileHistory";
import TableCard from "../../../generic/TableCard";
import {Link, Redirect} from "react-router-dom";

const Index = () => {

    const [redirectToFileId, setRedirectToFileId] = useState(undefined);

    const handleClick = row => {
        setRedirectToFileId(row.id);
    }

    const filters = {};
    if(redirectToFileId) return <Redirect push to={ `/input/stocks/upload/${redirectToFileId}` }/>;
    return (
        <TableCard title={ "Uploaded Files" }>
            <form className="form-inline mb-2">
                <Link className="btn btn-primary"
                      to={ `/input/stocks/upload/new?` }>
                    <i className="fa fa-plus"/>
                    &nbsp; Upload file
                </Link>
            </form>
            <InputFileHistory
                filters={ filters }
                entity="stocks"
                onClick={ handleClick }
            />
        </TableCard>
    );
}

export default Index;