import React from "react";
import PropTypes from 'prop-types';
import {showDangerToast} from "../../lib/notify";

class ReferenceSelect extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: undefined
        };
        this.loadData = this.loadData.bind(this);
    }

    componentDidMount() {
        if (this.props.async) {
            this.loadData();
        }
    }

    async loadData() {
        const { kind, placeholder } = this.props;
        return this.props.findAndCount(0, 100000, "id", "desc", {
            kind
        })
            .then(({data, total, totalRow}) => {
                if(placeholder) {
                    data.unshift({
                        code: placeholder,
                        label: placeholder
                    })
                }
                this.setState({data, total, totalRow, loading: false})
            })
            .catch(e => {
                this.setState({loading: false});
                showDangerToast(e);
            });
    }

    renderOptions(data) {
        return data && data.map((elem) =>
            <option key={elem.id} value={elem.code}>{elem.label ? elem.label : elem.code}</option>);
    }

    render() {
        let { data } = this.state;
        const { onChange, id, options, async, placeholder } = this.props;

        return (
            <select id={id} className="form-control mr-2 mt-1" onChange={(choice) => onChange(choice.target.value)}>
                {placeholder && <option selected disabled key={placeholder}>{placeholder}</option>}
                {
                    async ? this.renderOptions(data) : this.renderOptions(options)
                }
            </select>
        );
    }
}

ReferenceSelect.propTypes = {
    reference_type: PropTypes.string.isRequired,
    kind: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    id: PropTypes.string,
    placeholder: PropTypes.string,
    async: PropTypes.bool,
    options: PropTypes.array
};

export default ReferenceSelect;