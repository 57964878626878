import React from "react";
import DataTable, {field} from "../generic/DataTable";
import {exportOrdersData, findAndCount} from "../../service/orders";
import ExportFunButton from "../generic/ExportFunButton";
import {date} from "../../lib/format";

const Table = ({ filters }) => {
    return (
        <DataTable
            filters={filters}
            findAndCount={findAndCount}
            fields={[
                field('Number', true, 'number'),
                field("Delivery method", true, "deliveryMethod"),
                field('Postal code', true, 'postalCode'),
                field("Type", true, "type"),
                field("Target Delivery Date", true, "targetShippingDate", (res) => date(res.targetShippingDate)),
                field("Status", true, "status")
            ]}
            tableButtons={dataParameters => <ExportFunButton dataParameters={dataParameters} exportFun={exportOrdersData}/> }
            name={"orders"}
        />
    );
}

export default Table;