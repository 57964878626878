import {Service} from '../lib/strapi';
import {validatePasswordChange, validatePasswordDifficulty} from "../lib/validation";
import {BAD_PASSWORD_ERROR_MESSAGE} from "../common/constants";

const service = new Service("changeanexpiredpassword");

export const changeAnExpiredPassword = data => {
    const validationError = validatePasswordChange(data.newPassword, data.confirmNewPassword);

    if (validationError) return Promise.reject(validationError);

    if(!validatePasswordDifficulty(data.newPassword)) return Promise.reject(BAD_PASSWORD_ERROR_MESSAGE);

    return service.request('PUT', '/changeanexpiredpassword', { data: { ...data } });
}
