import React, {Fragment} from 'react';
import PropTypes from "prop-types";
import ModalConfirmation from "./ModalConfirmation";
import RegularButton from "./RegularButton";

class ButtonWithConfirm extends React.Component {
    constructor(props) {
        super(props);
        this.onConfirm = this.onConfirm.bind(this);
        this.state = {
            isOpen: false
        }
    }

    onConfirm() {
        this.setState({isOpen: false});
        this.props.onClick();
    }

    render() {
        const {className, color, children, modalText, modalButtonColor, modalButtonText} = this.props;
        return <Fragment>
            <RegularButton className={className} color={color} onClick={() => this.setState({isOpen: true})}>{children}</RegularButton>
            <ModalConfirmation text={modalText}
                               isOpen={this.state.isOpen}
                               confirmButtonText={modalButtonText || children}
                               color={modalButtonColor || color}
                               onConfirm={this.onConfirm}
                               onClose={() => this.setState({isOpen: false})}
            />
        </Fragment>
    }
}

ButtonWithConfirm.propTypes = {
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string,
    color: PropTypes.string,
    modalText: PropTypes.node,
    modalButtonColor: PropTypes.string,
    modalButtonText: PropTypes.string
};

ButtonWithConfirm.defaultProps = {
    modalText: "Please confirm this action"
};

export default ButtonWithConfirm;