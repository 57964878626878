import React, {Fragment} from 'react';
import "./Loader.css";
import PropTypes from "prop-types";
import Pagination from "./Pagination";
import {Row, Col} from "reactstrap";


class Pager extends React.Component {

    constructor(props) {
        super(props);
        this.onPageChange = this.onPageChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            start: props.start,
            limit: Number(props.limit)
        };
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const currentStartValue = this.state.start;
        const newStartValue = this.props.start;
        const currentLimitValue = this.state.limit;
        const newLimitValue = Number(this.props.limit);

        if((currentStartValue !== newStartValue) || (currentLimitValue !== newLimitValue)) {
            this.setState({
                start: newStartValue,
                limit: newLimitValue
            })
        }
    }

    handleChange(event) {
        let limit = event.target.value;
        this.props.onLimitChange(limit);
    }

    onPageChange(page) {
        const newStart = (page - 1) * this.props.limit;
        this.setState({
            start: newStart
        });
        this.props.onPageChange(newStart);
    }

    render() {
        const limitList = [10,20,100,1000];
        const minimalLimit = Math.min(limitList[0], this.props.limit);
        const page = 1 + Math.floor(this.state.start / this.props.limit);
        const totalPages = Math.ceil(this.props.total / this.props.limit);
        if (this.props.total > minimalLimit)
            return <Fragment>
                <Row className={"mr-2"}>
                    <Col sm={12} md={5}>
                        <Pagination current={page} total={totalPages} onPageChange={this.onPageChange}/>
                    </Col>
                    {this.props.canChangeLimit && <Col sm={12} md={4}>
                        <div className="form-group form-inline">
                            <label className={"text-right  text-muted mt-2 mr-2"}> entries on page</label>
                            <select
                                name="order-listing_length" aria-controls="order-listing"
                                value={this.state.limit}
                                onChange={this.handleChange} className="form-control">
                                {limitList.map(value=><option key={value} value={value}>{value}</option>)}
                            </select>
                        </div>
                    </Col>}
                    <Col sm={12} md={3} className={"text-right text-muted"}>
                        <div className={"mt-2"}>
                            {this.state.start} to {Math.min(this.state.start + Number(this.props.limit), this.props.total)} out
                            of {this.props.total}
                        </div>
                    </Col>
                </Row>

            </Fragment>;
        else
            return "";
    }
}

Pager.propTypes = {
    start: PropTypes.number.isRequired,
    total: PropTypes.number,
    onPageChange: PropTypes.func.isRequired,
    limit: PropTypes.number,
    canChangeLimit: PropTypes.bool
};

Pager.defaultProps = {
    start: 0,
    limit: 10,
    canChangeLimit: true
};

export default Pager;

