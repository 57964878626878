import jwtDecode from "jwt-decode";
import {getCookie, removeCookie, saveToken, setCookie} from "./session";
import {validateCredentials, validatePasswordChange} from "./validation";

import {remote} from "./strapi";
import moment from "moment";

/**
 * Backward compatibility
 * @type {Strapi}
 */
export const strapi = remote;

export const login = async (email, password) => {

    const validationError = validateCredentials(email, password);
    if (validationError) return Promise.reject(validationError);

    return strapi.login(email, password).then(res => {
        if (res && res.needChangePassword) {
            strapi.clearToken();
            return Promise.resolve(res)
        }

        if (!res && !res.jwt) return Promise.reject(res);

        saveToken(res);

        return res;
    });
};

export const changePassword = async (password,confirm) => {
    const validationError = validatePasswordChange(password,confirm);
    if (validationError) return Promise.reject(validationError);
    const userId = getUserFromSession().id;
    return strapi.getEntry("users", userId).then(result => {
            result.password = password;
            result.created_at = moment(result.created_at).format('YYYY-MM-DD HH:MM:SS');
            result.updated_at = moment(result.updated_at).format('YYYY-MM-DD HH:MM:SS');
            return strapi.updateEntry("users", userId, result);
        }
    );

};

export const getUserFromSession = () => {
    const userString = window.localStorage.getItem("user");
    return (userString && JSON.parse(userString)) || {};
};

export const logout = () => {
    removeCookie("jwt");
    window.localStorage.removeItem("user");

    // to support logging out from all windows
    window.localStorage.setItem("logout", Date.now());
};

export const getUserRoleFromSession = () => {
    const userString = window.localStorage.getItem("user");
    return (userString && JSON.parse(userString) && JSON.parse(userString).role) || "IT System Support";
};

export const getJwt = () => getCookie("jwt");

/*
Проверка на наличие JWT токена и его актуальность. Проверка только локальная, без запроса к серверу.
 */
export const isAuthenticated = ctx => {
    const token = getJwt(ctx);
    if (!token) return false;
    const decoded = jwtDecode(token);
    var dateNow = new Date();
    return (decoded.exp * 1000) > dateNow.getTime();
};

export const setRefreshedJWT = (response) => {
    if(response.jwt) {
        setCookie('jwt', response.jwt)
    }
    return response;
}