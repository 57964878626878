import React, { Fragment } from "react";
import Card from "../../../generic/Card";
import { Col, Row } from "reactstrap";
import Loader from "../../../generic/Loader";
import InputFileCard from "../../InputFileCard";
import InputFileProcess from "../../InputFileProcess";
import InputFileDatabaseCard from "../../InputFileDatabaseCard";
import DeliveryMethodProductsDataTable from "../deliveryMethodProductsData/Table";
import DeliveryMethodProductsRawTable from "../deliveryMethodProductsRaw/Table";
import { Redirect } from "react-router-dom";
import ReferenceData from '../../../../service/referenceData';
import MappingIssues from "./raw/MappingIssues";
import MappingModal from "./MappingModal";
import { findAndCount } from "../../../../service/deliveryMethodProductsRaw";
import { showWarningToast } from "../../../../lib/notify";

class Index extends InputFileProcess {

    constructor(props) {
        super(props);

        this.reloadRawTable = this.reloadRawTable.bind(this);
        this.openMappingModal = this.openMappingModal.bind(this);
        this.toggleMappingModal = this.toggleMappingModal.bind(this);
        this.checkMappingsExistingBeforeConfirm = this.checkMappingsExistingBeforeConfirm.bind(this);

        this.state = {
            reload: false,
            isMappingModalOpened: false,
            code: undefined,
            mappingRow: undefined,
            methods: []
        }
    }

    async loadMethods() {
        let methods;

        try {
            methods = await ReferenceData.findAndCount(0, 100000);
        } catch (e) {
            console.log(`An error happened while reference data receiving: ${e}`)
        }

        this.setState({
            methods
        })
    }

    componentDidMount() {
        super.componentDidMount();
        this.loadMethods();
    }


    reloadRawTable() {
        this.setState({
            reload: !this.state.reload
        })
    }

    openMappingModal(data) {
      this.setState({
        isMappingModalOpened: true,
        code: data.code,
        mappingRow: data
      })
    }

    toggleMappingModal() {
      this.setState({
        isMappingModalOpened: false
      })
    }

    async checkMappingsExistingBeforeConfirm() {
      const { inputFile } = this.state;
      const { data } = await findAndCount(0, 10000, 'id', 'desc', {
        mapping: true,
        input_file_id: inputFile.id
      });

      if(data.length > 0) {
        showWarningToast('You have to resolve all mapping problems before confirm!');
      } else {
        this.onConfirm();
      }
    }


    render() {
        const { loading, deleted, inputFile, isMappingModalOpened, reload, code, mappingRow, methods } = this.state;
        if (loading) return <Card title={ "Process file" }><Loader/></Card>;
        if (deleted) return <Redirect push to={ "/input/deliveryMethodProducts/upload" }/>;
        if (!inputFile) return "";

        const { status } = inputFile;

        return (
            <Fragment>
                {super.render()}
                <br/>
                <Row>
                    <Col lg={["success", "loading", "mapping"].includes(status) ? 6 : 12} md={6}>
                        <InputFileCard inputFile={ inputFile } onCancel={ this.onConfirmCancel }
                                       onDelete={ this.onConfirmDelete }
                                       onConfirm={ this.checkMappingsExistingBeforeConfirm }/>
                    </Col>
                  {status === "mapping" && <Col lg={6} md={12}>
                    <MappingIssues inputFileId={inputFile.id} openMappingModal={this.openMappingModal}
                                   reload={reload} onConfirm={this.onConfirm} reloadRawTable={this.reloadRawTable}
                                   methods={methods}
                    />
                  </Col>}

                    {["success", "loading"].includes(status) && <Col lg={6} md={6}>
                        <InputFileDatabaseCard inputFile={ inputFile }/>
                    </Col>}
                </Row>
                <br/>

                <>
                    {
                        status === "success" &&
                        <Card title={"Database load result"}>
                            <DeliveryMethodProductsDataTable uploadId={ inputFile.id } isNew={ true } methods = { methods }/>
                        </Card>
                    }
                    {
                        status === "mapping" &&
                        <Card title={ "Raw file data" }>
                            <DeliveryMethodProductsRawTable uploadId={ inputFile.id } isNew={ true } reload={ reload } methods = { methods } />
                        </Card>
                    }
                </>
                <MappingModal inputFile={ inputFile } isOpen={isMappingModalOpened} onToggle={this.toggleMappingModal}
                              reloadRawTable={ this.reloadRawTable } code={ code } mappingRow={ mappingRow }
                />

            </Fragment>
        );
    }
}

export default Index;