import React from 'react'

import * as notify from "../../../lib/notify"
import Button from "reactstrap/es/Button";
import user from "../../../service/user";
import role from "../../../service/role";
import {Redirect} from "react-router-dom";
import Loader from "../../generic/Loader";
import {validatePasswordDifficulty} from "../../../lib/validation";

export default class Edit extends React.Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.refreshFields = this.refreshFields.bind(this);
        this.state = {
            id: undefined,
            username: "",
            email: "",
            roleId: 6,
            blocked: false,
            password: "",
            confirmPassword: "",
            error: false,
            loading: false,
            roles: undefined
        };
    };

    componentDidMount() {
        this.loadData(this.props);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.loadData(nextProps);
    }

    loadData(props) {

        if(!this.state.roles) this.loadRoles();

        const userId = props.match.params.id;
        if (userId) {
            this.setState({loading: true});
            user.read(userId)
                .then(({id, username, email, roleId, blocked}) =>
                    this.setState({id, username, email, roleId, blocked, loading: false}))
                .catch(e => {
                    notify.showDangerToast(e);
                    this.setState({error: true, loading: false});
                });
        }
    }

    loadRoles() {
        role.all().then(roles => this.setState({roles}));
    }

    handleChange(event) {
        event.preventDefault();
        this.setState({roleId: event.target.value, role: this.state.roles.find(role => role.id === event.target.value)});
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    refreshFields() {
        this.setState({
            id: undefined,
            username: "",
            email: "",
            password: "",
            confirmPassword: "",
            roleId: 6,
            blocked: false,
            error: false,
            loading: false
        });
        this.props.history.push("/administration/user/edit")
    }

    changeUser = (e, returnBack) => {
        e.preventDefault();

        const {id, username, email, password, confirmPassword, roleId, blocked, roles} = this.state;

        const isCreate = !id;
        const role = roles.find(elem => elem.id === +roleId);

        if (isCreate) {
            user.create(username, email, password, confirmPassword, role)
                .then(() => {
                    notify.showSuccessToast("User created");
                    if (returnBack) this.props.history.push("/administration/user");
                    else this.refreshFields();
                }, (error) => notify.showDangerToast(error));

        } else {
            if(password !== "" && !validatePasswordDifficulty(password)) {
              notify.showDangerToast("Bad password");
              return;
            }

            user.update(id, username, email, password, confirmPassword, roleId, blocked)
                .then(() => {
                    notify.showSuccessToast("User saved");
                    if (returnBack) this.props.history.push("/administration/user");
                }, (error) => notify.showDangerToast(error));
        }

    };

    createButtons() {
        return <div className="col-">
            <Button
                color="primary"
                className="mr-2"
                type="submit"
                onClick={e => this.changeUser(e, true)}
            >
                Create
            </Button>
            <Button
                type="submit"
                color="primary"
                className="mr-2"
                onClick={this.changeUser}
            >
                Create and continue
            </Button>
            <Button
                color="light" onClick={() => {
                (notify.showWarningToast("User creation cancelled"));
                this.props.history.push("/administration/user")
            }}
            >
                Cancel
            </Button>
        </div>
    }

    updateButtons() {
        return <div className="col-">
            <Button
                color="primary"
                className="mr-2"
                type="submit"
                onClick={(e) => this.changeUser(e, true)}
            >
                Save
            </Button>
            <Button
                type="submit"
                color="primary"
                className="mr-2"
                onClick={this.changeUser}
            >
                Save and continue
            </Button>
            <Button
                color="light" onClick={() => {
                (notify.showWarningToast("User editing cancelled"));
                this.props.history.push("/administration/user")
            }}
            >
                Cancel
            </Button>
        </div>
    }

    render() {
        if (this.state.error) return <Redirect to="/administration/user"/>;
        if (this.state.loading) return <Loader/>;
        const isCreate = !this.state.id;
        return (
            <div className="card">
                <div className="card-body">
                    <h4 className="card-title"> {isCreate ? "Create User" :"Edit User"}</h4>
                    <form className="forms-sample">
                        <div className="form-group">
                            <label>Username</label>
                            <input
                                type="text"
                                className="form-control"
                                name="username"
                                placeholder="Username"
                                value={this.state.username}
                                onChange={this.handleInputChange}
                                autoComplete={"false"}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="InputEmail">Email address</label>
                            <input
                                id="InputEmail"
                                type="email"
                                className="form-control"
                                name="email"
                                placeholder="Email"
                                value={this.state.email}
                                onChange={this.handleInputChange}
                                autoComplete="false"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="InputPassword">Password</label>
                            <input
                                id="InputPassword"
                                type="password"
                                className="form-control"
                                name="password"
                                placeholder="Password"
                                value={this.state.password}
                                onChange={this.handleInputChange}
                                autoComplete="false"
                                disabled={isCreate}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="InputConfirmPassword">Confirm Password</label>
                            <input
                                id="InputConfirmPassword"
                                type="password"
                                className="form-control"
                                name="confirmPassword"
                                placeholder="Password"
                                value={this.state.confirmPassword}
                                onChange={this.handleInputChange}
                                autoComplete="false"
                                disabled={isCreate}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="ChooseRole">Choose role</label>
                            <select
                                id="ChooseRole"
                                className="form-control"
                                name="roleId"
                                value={this.state.roleId}
                                onChange={this.handleChange}
                                defaultValue='choose'
                            >
                                <option key={"user-role-none"} value='choose' disabled>Choose role</option>
                                {this.state.roles && this.state.roles.map(role =>{
                                    return <option key={"user-role-" + role.id} value={role.id}>{role.name}</option>})
                                }
                            </select>
                        </div>
                        {!isCreate?
                        <div className="form-check">
                            <label className="form-check-label">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    name="blocked"
                                    checked={this.state.blocked}
                                    onChange={this.handleInputChange}
                                />
                                Blocked
                                <i className="input-helper"/>
                            </label>
                        </div>:<div/>}
                        <div className="row justify-content-between">
                            {isCreate ? this.createButtons() : this.updateButtons()}
                        </div>
                    </form>
                </div>
            </div>
        );
    }
};