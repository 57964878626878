import React, {useState} from 'react';
import {Button, ModalBody, Modal, ModalHeader, ModalFooter, Input, Label} from "reactstrap";
import PropTypes from 'prop-types';
import useInput from "../hooks/useInput";
import {changeAnExpiredPassword} from "../service/changePassword";
import * as notify from "../lib/notify";
import {Redirect} from "react-router-dom";
import {saveToken} from "../lib/session";

const LoginChangeAnExpiredPassword = ({isOpen, email, password, from}) => {
    const {value: newPassword, bind: bindNewPassword} = useInput(undefined);
    const {value: confirmNewPassword, bind: bindConfirmNewPassword} = useInput(undefined);
    const [passwordVisibility, setPasswordVisibility] = useState(false);
    const [confirmPasswordVisibility, setConfirmPasswordVisibility] = useState(false);
    const [buttonDisabled, disableTheButton] = useState(false);
    const [success, setSuccess] = useState(false);

    const onSave = () => {
        disableTheButton(true)
        changeAnExpiredPassword({email, password, newPassword, confirmNewPassword })
            .then((res) => {
                if (!res && !res.jwt) return Promise.reject(res);
                saveToken(res);
                setSuccess(true);
            })
            .catch(e => e ? notify.showDangerToast(e) : null)
            .finally(() => disableTheButton(false))
    }
    if (success) {
        return <Redirect to={from}/>
    }

    return <Modal className="loginChangePasswordModal" isOpen={isOpen}>
        <ModalHeader>Change password</ModalHeader>
        <ModalBody>
            <div className="card-body">
                <div className="mb-3">
                    <Label for="label">Password</Label>
                    <div className="passwordInput">
                        <Input type={passwordVisibility ? 'text' : 'password'} name="label" id="label" placeholder="Enter password ..." {...bindNewPassword} />
                        <button onClick={() => setPasswordVisibility(!passwordVisibility)} className="passwordInput__btn">
                            <i className={`fa fa-fw ${passwordVisibility ?  'fa-eye' : 'fa-eye-slash'}`}/>
                        </button>
                    </div>
                </div>
                <div>
                    <Label for="label">Confirm password</Label>
                    <div className="passwordInput">
                        <Input type={confirmPasswordVisibility ? 'text' : 'password'} name="label" id="label" placeholder="Repeat password ..." {...bindConfirmNewPassword} />
                        <button onClick={() => setConfirmPasswordVisibility(!confirmPasswordVisibility)} className="passwordInput__btn">
                            <i className={`fa fa-fw ${confirmPasswordVisibility ?  'fa-eye' : 'fa-eye-slash'}`}/>
                        </button>
                    </div>

                </div>
            </div>
        </ModalBody>
        <ModalFooter>
            <Button color="primary" onClick={onSave} disabled={buttonDisabled}>Save</Button>
        </ModalFooter>
    </Modal>
}

LoginChangeAnExpiredPassword.propTypes = {
    isOpen: PropTypes.bool,
    username: PropTypes.string,
    password: PropTypes.string,
    from: PropTypes.object
}

export default LoginChangeAnExpiredPassword;