import {Service} from "../lib/strapi";

const entity = "deliverymethodpriorities";

const service = new Service(entity);
export const findAndCount = service.findAndCountServerSide;
export const insertPriorities = async (priorities, code) => {
  return await service.request('post', '/deliverymethodpriorities/insertPriorities', {
    data: {
      priorities,
      code
    }
  });
}
