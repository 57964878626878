import DataTable, {field} from "../../../../generic/DataTable";
import {findAndCount as findAndCountMatrixData, deleteEntry} from "../../../../../service/deliveryMatrixData";
import {datetime} from "../../../../../lib/format";
import React from "react";
import ModalConfirmation from "../../../../generic/ModalConfirmation";
import CEComponent from "./modal/CEComponent";
import Button from "reactstrap/es/Button";
import fields from "./fields.json"
import PropTypes from "prop-types";
import * as notify from "../../../../../lib/notify";
import useTable from "./useTable";

const MatrixItemsTable = ({filters, input_file_id, deliveryMatrixId, createButtons, courierCompanies}) => {
    const {openDeleteModalConfirmation, openModalConfirmation, modalType, id, changeData, reload, onChange,
        onCreate, onCopy, onDelete, closeModalConfirmation} = useTable()

    return <div className="tab-pane fade active show" id="matrix-data" role="tabpanel" aria-labelledby="home-tab">
        <div className="media">
            <div className="media-body">
                <div>
                    {createButtons()}
                    <Button color="success" className={'mt-2 mb-2'} onClick={onCreate}>
                        <i className="fa fa-plus"/>
                        Add address
                    </Button>
                </div>
                <DataTable
                    filters={filters}
                    findAndCount={findAndCountMatrixData}
                    fields={[
                        field("Input File ID", false, "input_file_id", () => input_file_id),
                        field("Created At", false, "created_at", data => datetime(data.created_at)),
                        field("Updated At", false, "updated_at", data => datetime(data.updated_at)),
                        ...fields.map((it) => field(it.name, false, it.id))
                    ]}
                    onDelete={onDelete}
                    onChange={onChange}
                    additionalButtons={(res) => <Button color="primary" size="xs" onClick={() => onCopy(res)}><i className="fa fa-copy"/></Button>}
                    reload={reload}
                    name={"deliverymatrices"}
                />
            </div>
        </div>
        <ModalConfirmation text="Are you sure you want to delete it?" isOpen={openDeleteModalConfirmation} onConfirm={() => {
            deleteEntry(id).then(() => closeModalConfirmation()()).catch(error => notify.showDangerToast(error.message))
        }} onClose={closeModalConfirmation} />
        <CEComponent courierCompanies={courierCompanies} type={modalType} deliveryMatrixId={deliveryMatrixId}
             isOpen={openModalConfirmation} changeData={changeData} closeModalConfirmation={closeModalConfirmation} />
    </div>
}

MatrixItemsTable.propTypes = {
    filters: PropTypes.object,
    input_file_id: PropTypes.number,
    deliveryMatrixId: PropTypes.string,
    createButtons: PropTypes.func.isRequired,
    courierCompanies: PropTypes.array
}

export default MatrixItemsTable;