import {Service} from "../lib/strapi";
import {strapi} from "../lib/auth";

const entity = "deliveryMethodProductsRaw";

const service = new Service(entity);

export const findAndCount = service.findAndCountServerSide;

export const deleteEntry = (id) => {
  return strapi.deleteEntry(entity, id);
};

export const changeBVCodeAndAvailabilityInRawTable = (newCode, availability, rowId) => service.request('post', '/deliverymethodproductsraw/changeBVCodeAndAvailabilityInRawTable', {params: {
    newCode,
    availability,
    rowId
  }});