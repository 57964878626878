import {Service} from '../lib/strapi';
import {strapi} from "../lib/auth";

const service = new Service("deliverymatrices");

export const findAndCount = service.findAndCountServerSide;
export const readMatrixShortInfo = async id => strapi.getEntry("deliverymatrices", id);
/**
 * @param {number} id - new active matrix ID
 */
export const setActive = async (id) => service.request('get',  `/deliverymatrices/setActive`, {params: {
        newActive: id
    }});