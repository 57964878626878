import React from "react";
import {login} from "../lib/auth";
import {Button,  Container, Form, FormGroup, Input} from "reactstrap";
import {Link, Redirect} from "react-router-dom";
import * as notify from "../lib/notify";
import LoaderOverlay from "./generic/LoaderOverlay";
import LoginChangeAnExpiredPassword from "./LoginChangeAnExpiredPassword"
import { azureEnterLink } from "../service/microsoft";

class Login extends React.Component {
    state = {
        email: "",
        password: "",
        error: null,
        loading: false,
        success: false,
        needChangePassword: false
    };

    onSubmit = async e => {
        e.preventDefault();

        const {email, password} = this.state;

        this.setState({loading: true});

        login(email, password).then((data) => {
            if (data.needChangePassword) {
                this.setState({
                    loading: false,
                    needChangePassword: true,
                })
            } else {
                this.setState({
                    loading: false,
                    error: null,
                    success: true,

                })
                notify.showSuccessToast("You have logged in!")
            }
        }).catch(e => {
            this.setState({
                loading: false,
                error: e.message || e

            });
            if(e) {
                notify.showDangerToast(e);
            }
        });

    };
    
    render() {
        const {success} = this.state;

        const {from} = this.props.location.state || {from: {pathname: "/"}};

        if (success) {
            return <Redirect to={from}/>
        }

        return (
            <Container>
                <div className="container-scroller">
                    <div className="container-fluid page-body-wrapper full-page-wrapper">
                        <div className="content-wrapper d-flex align-items-center auth px-0">
                            <div className="row w-100 mx-0">
                                <div className="col-lg-4 mx-auto">
                                    <LoaderOverlay isVisible={this.state.loading}>
                                        <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                                            <div className="brand-logo">
                                                <img src="./images/logo.png" alt="pm-logo"/>
                                            </div>
                                            <h6 className="font-weight-light">Sign in to continue.</h6>
                                            <Form className="pt-3" onSubmit={this.onSubmit}>
                                                <FormGroup>
                                                    <Input
                                                        onChange={e => this.setState({email: e.target.value})}
                                                        type="email"
                                                        name="email"
                                                        className="form-control form-control-lg"
                                                        value={this.state.email}
                                                        id="exampleInputEmail1"
                                                        placeholder="Username"
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Input
                                                        onChange={e => this.setState({password: e.target.value})}
                                                        type="password"
                                                        name="password"
                                                        className="form-control form-control-lg"
                                                        id="exampleInputPassword1"
                                                        placeholder="Password"
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Button
                                                        color="primary"
                                                        type="submit"
                                                        style={{width: "100%" , fontSize: "1.2em"}}
                                                        disabled={this.state.loading}
                                                    >
                                                        Submit
                                                    </Button>
                                                </FormGroup>
                                            </Form>
                                            <a
                                                style={{fontSize: "1.3em", width: "100%"}}
                                                href={azureEnterLink}
                                                className={"btn btn-default"}>
                                                Login via Keycloak
                                            </a>
                                            <Link className="btn btn-default"
                                                  style={{width: "100%" , fontSize: "1.2em"}}
                                                  to={'/forgotPassword'}>
                                                Restore password
                                            </Link>
                                        </div>
                                    </LoaderOverlay>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <LoginChangeAnExpiredPassword isOpen={this.state.needChangePassword} email={this.state.email} from={from} password={this.state.password} />
            </Container>
        );
    }
}

export default Login;