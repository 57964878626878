import React from "react";
import DataTable, { field } from "../../../generic/DataTable";
import { findAndCount } from "../../../../service/deliveryMethodProducts";
import { getCodeAndLabelOfDeliveryMethod } from '../../../../common';

const Table = ({ uploadId, methods }) => {
     const filters = { input_file_id: uploadId };
     return (<DataTable
         filters={ filters }
         findAndCount={ findAndCount }
         fields={[
           field("Product Code", true, "code"),
           field("Delivery Method", true, "method", response => getCodeAndLabelOfDeliveryMethod(methods, response)),
           field('Availability', true, "availability"),
         ]}
         name={ "delivery_method_products" }
        />
     );
}

export default Table;