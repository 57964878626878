import React from "react";
import moment from "moment";
import DataTable, {field} from "../generic/DataTable";
import {exportESBPMIData, findAndCount} from "../../service/ESBPMI";
import ExportFunButton from "../generic/ExportFunButton";

function convertIndustryStatus({ industryStatus }) {
  return industryStatus ? 'Active' : 'Archived';
}

function convertCreationTime({ creationTime }) {
  // Some lines had no creation date before
  if(creationTime) {
    return moment(creationTime).format('DD.MM.YYYY');
  }
}

const Table = ({ filters }) => {
  return (
    <DataTable
      filters={filters}
      findAndCount={findAndCount}
      fields={[
        field('Code', true, 'code'),
        field("Name", true, "name"),
        field("Russian Name", true, "russianName"),
        field('Industry Status', true, 'industryStatus', resp => convertIndustryStatus(resp)),
        field("Manufacturer", true, "manufacturer"),
        field("Brand Family", true, "brandFamily"),
        field("Brand Group", true, "brandGroup"),
        field('Country', true, 'country'),
        field('Date', true, 'creationTime', resp => convertCreationTime(resp)),
        field('Product category', true, 'productCategory'),
        field('Limits', true, 'limit')
      ]}
      tableButtons={dataParameters => <ExportFunButton dataParameters={dataParameters} exportFun={exportESBPMIData}/> }
      name={"esb_pmi"}
    />
    );
}

export default Table;