import { strapi } from '../lib/auth';
import {Service} from "../lib/strapi";



export const find = (filters = {}, start = 0, limit = 20, sortField = "id", sortDir = "desc") => {

  const withSort = Object.assign(filters, {
    "_sort":`${sortField}:${sortDir.toUpperCase()}`,
    "_limit": limit,
    "_start": start
  });
  return strapi.getEntries("deliverymatrices", withSort)
};

export const count = (filters = {}) => strapi.getEntryCount("deliverymatrices", filters);

const service = new Service("deliverymatrices");
export const findAndCount = service.findAndCountServerSide;

/**
 * @param {number} id - new active matrix ID
 */
export const getMatrix = async id => strapi.getEntry("deliverymatrices", id);

/**
 * @param {number} id - new active matrix ID
 * @param {string} user - current user
 */
export const setActive = async (id, user) => service.request('get',  `/deliverymatrices/setActive`, {params: {
    newActive: id,
    user
  }});

export const all = () => strapi.getEntries("deliverymatrices", {
  "_limit": 10000,
  "_sort": "created_at:DESC"
});


export default {all,  findAndCount, find, count};