import React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import Title from "./Title";

import {withRouter} from 'react-router-dom';
import {isAuthenticated} from "../../lib/auth";
import {getCurrentRoute} from "../../route/routes";

/**
 * Используется для внутренней части сайта, после аутентификации
 */
class Layout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: isAuthenticated(),
            route: getCurrentRoute(this.props.location.pathname),
            isSidebarActive: false
        };
    };

    componentDidUpdate(prevProps) {
        const to = this.props.location.pathname;
        if (to !== prevProps.location.pathname) {
            this.setState({
                isAuthenticated: isAuthenticated(),
                route: getCurrentRoute(to)
            });
        }
    }

    render() {
        const {children} = this.props;
        return (
            <div className="container-scroller">
                <Header isAuthenticated={this.state.isAuthenticated}
                        onBurgerClick={() => this.setState({isSidebarActive: !this.state.isSidebarActive})}
                        {...this.props}/>
                <div
                    className={(this.state.isAuthenticated ? "" : "sidebar-absolute sidebar-hidden") + "container-fluid page-body-wrapper"}>

                    {this.state.isAuthenticated && <Sidebar active={this.state.isSidebarActive} {...this.props}/>}

                    <div className="main-panel">
                        <div className="content-wrapper">
                            {this.state.isAuthenticated && <Title {...this.state.route}/>}
                            <div className="row grid-margin">
                                <div className="col-12">
                                    {children}
                                </div>
                            </div>
                        </div>

                        <Footer/>
                    </div>
                </div>

                <script src="/vendors/js/vendor.bundle.base.js"></script>
                <script src="/vendors/js/vendor.bundle.addons.js"></script>
                <script src="/js/template.js"></script>
                <script src="/js/js-grid.js"></script>
                <script src="/js/db.js"></script>
            </div>
        );
    }
}

export default withRouter(props => <Layout {...props}/>);