import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import Table from './Table';
import Edit from './Edit';

const PublicTokens = () => (
  <Switch>
    <Route exact path="/administration/public-tokens" component={Table} />
    <Route exact path="/administration/public-tokens/edit" component={Edit} />
    <Route path="/administration/public-tokens/edit/:id" component={Edit} />
  </Switch>
)

export default PublicTokens;