import React, {useEffect, useState} from 'react'
import TableCard from "../generic/TableCard";
import ModalConfirmation from "../generic/ModalConfirmation";
import {deleteEntry, findAndCount} from "../../service/referenceData";
import TextSearch from "../generic/field/TextSearch";
import DataTable, {field} from "../generic/DataTable";
import CEComponent from "./CEComponent";
import {pickBy} from "lodash";
import { findAndCount as warehousesFindAndCount } from "../../service/warehouses";
import { findAndCount as methodFoldersFindAndCount, deleteEntry as methodFoldersDeleteEntry } from "../../service/methodFolders";
import * as notify from "../../lib/notify";
import {useQueryParam, StringParam, BooleanParam} from 'use-query-params';
import CFolderComponent from "./CFolderComponent";
import "./styles.css";
import RegularButton from "../generic/RegularButton";
import {Button} from "reactstrap";
import ActionsButton from "./multipleActionsButton";

export default function Index(props) {
    const [label, setLabel] = useQueryParam('label', StringParam);
    const [code, setCode] = useQueryParam('code', StringParam);
    const [warehouses, setWarehouses] = useState([]);
    const [openDeleteModalConfirmation, setOpenDeleteModalConfirmation] = useState(undefined);
    const [id, setId] = useState(undefined);
    const [openCreateModalConfirmation, setOpenCreateModalConfirmation] = useState(undefined);
    const [openEditModalConfirmation, setOpenEditModalConfirmation] = useState(undefined);
    const [changeData, setChangeData] = useState(undefined);
    const [reload, setReload] = useState(undefined);
    const [references, setReferences] = useState(undefined);

    const [enabledForSFWidget, setEnabledForSFWidget] = useQueryParam('enabledForSFWidget', BooleanParam);
    const [stockManagement, setStockManagement] = useQueryParam('stockManagement', BooleanParam);
    const [folder, setFolder] = useQueryParam('folder', StringParam);
    const [folderPills, setFolderPills] = useState(false);
    const [folders, setFolders] = useState([]);
    const [openCreateFolderModalConfirmation, setOpenCreateFolderModalConfirmation] = useState(undefined);
    const [openDeleteFolderModalConfirmation, setOpenDeleteFolderModalConfirmation] = useState(undefined);
    const [folderId, setFolderId] = useState(undefined);
    const [multipleSelectedIds, setMultipleSelectedIds] = useState([]);

    const { kind } = props.match.params;
    const filters = pickBy({
        code,
        label,
        kind,
        folder,
        enabledForSFWidget: !!enabledForSFWidget,
        stockManagement: !!stockManagement,
    })

    const onClearFilters = () => {
        setLabel(undefined);
        setCode(undefined);
        setFolder(undefined);
        setEnabledForSFWidget(undefined);
        setStockManagement(undefined);
    }

    const onDelete = ({ id }) => {
        setOpenDeleteModalConfirmation(true);
        setId(id);
    }

  const onCreate = () => setOpenCreateModalConfirmation(true);

  const onCreateFolder = () => setOpenCreateFolderModalConfirmation(true);

  const onDeleteFolder = (it) => {
    setFolderId(it.id);
    setOpenDeleteFolderModalConfirmation(true);
    if (it.folder === folder) setFolder(undefined);
  };


  const onChange = changeData => {
        setOpenEditModalConfirmation(true);
        setChangeData(changeData);
    }

    const closeModalConfirmation = () => {
        setReload(!reload);
        setOpenEditModalConfirmation(false);
        setId(undefined);
        setOpenCreateModalConfirmation(false);
        setOpenDeleteModalConfirmation(false);
        setOpenCreateFolderModalConfirmation(false)
        setFolderId(undefined);
        setOpenDeleteFolderModalConfirmation(false)
    }

    const loadMethodFolders = async () => {
      setFolders(await methodFoldersFindAndCount(0, 100000, 'id', 'asc').then(data => data.data));
    }

    const loadReferences = async () => {
       setReferences(await findAndCount(0, 100000, 'id', 'asc'));
    }

    const loadWarehouses = async () => {
        const warehousesRaw = await warehousesFindAndCount(0, 100000, 'id', 'asc');

        if(warehouses && warehouses.length > 0) {
            notify.showWarningToast('No available warehouses has been detected');
        } else {
            setWarehouses(warehousesRaw);
        }
    }

    const detectWarehouseName = data => {
        if(data.warehouse && warehouses && warehouses.data) {
            const warehouseInfo = warehouses.data.find(elem => elem.code === data.warehouse);

            if(warehouseInfo) return warehouseInfo.name;
        }
    }

    const handleEnabledForSFWidgetChange = () => setEnabledForSFWidget(!enabledForSFWidget);
    const handleStockManagementChange = () => setStockManagement(!stockManagement);
    const folderList = folders.map(it => it.folder);

    let fields = [
        field("ID",true, "id"),
        field("Code", true, "code")
    ];

    useEffect(() => {
        loadWarehouses();
        loadReferences();
        loadMethodFolders();
    }, []);

    if(kind ==='deliveryMethod') {
        fields.push(field("ShippingId", true, "externalId"));
        fields.push(field("Website label", true, "websiteLabel", (response) => <span className="col-max-width">{response.websiteLabel}</span>));
        fields.push(field("SF", true, "enabledForSFWidget", response => response.enabledForSFWidget ? "Yes" : "No"));
        fields.push(field("SM", true, "stockManagement", response => response.stockManagement ? "Yes" : "No"));
        fields.push(field("CDEK", true, "enabledCDEKPVZ", response => response.enabledCDEKPVZ ? "Yes" : "No"));
        fields.push(field("DPD", true, "enabledDPDPVZ", response => response.enabledDPDPVZ ? "Yes" : "No"));
        fields.push(field("Warehouse", true, "warehouse", response => detectWarehouseName(response)));
    } else fields.push(field("Label", true, "label"))

    return <TableCard title={"Reference Data"}>
        <div>
            <ModalConfirmation text="Are you sure you want to delete it?" isOpen={openDeleteModalConfirmation} onConfirm={() => {
                deleteEntry(id).then(() => closeModalConfirmation())
            }} onClose={closeModalConfirmation} />
            {kind === 'deliveryMethod' &&
              <>
                <ModalConfirmation text="Are you sure you want to delete it?" isOpen={openDeleteFolderModalConfirmation} onConfirm={() => {
                  methodFoldersDeleteEntry(folderId).then(loadMethodFolders).then(() => closeModalConfirmation())
                }} onClose={closeModalConfirmation} />
                <CFolderComponent isOpen={openCreateFolderModalConfirmation} closeModalConfirmation={closeModalConfirmation} loadMethodFolders={loadMethodFolders}/>
              </>}
            <CEComponent type="create" folderOnCreation={folder} isOpen={openCreateModalConfirmation} kind={kind} closeModalConfirmation={closeModalConfirmation} warehouses={warehouses} referenceData={references} folders={folderList}/>
            <CEComponent type="edit" isOpen={openEditModalConfirmation} changeData={changeData} kind={kind} closeModalConfirmation={closeModalConfirmation} warehouses={warehouses} referenceData={references} folders={folderList}/>
            <div className={"row justify-content-start align-items-center ml-1 mb-3"}>
                <TextSearch
                    type="text"
                    name="Code"
                    onSearch={setCode}
                    value={code}
                    className="form-control col-sm-3 mr-2"
                    placeholder={"Enter code..."}
                />
                <TextSearch
                    type="text"
                    name="Label"
                    onSearch={setLabel}
                    value={label}
                    className="form-control col-sm-3 mr-2"
                    placeholder={"Enter label..."}
                />
                <Button className="mr-sm-2" color="default" onClick={onClearFilters}>
                    <i className="fa fa-fw fa-times"/>
                    Cancel
                </Button>
                <RegularButton className="mr-sm-2" color="primary" onClick={onCreate}>
                    <i className="fa fa-plus"/>
                    Add
                </RegularButton>
            </div>
            {kind === 'deliveryMethod' &&
            <div className={"row justify-content-start align-items-center ml-1 mb-3"}>
                <div className="form-check mr-sm-3">
                    <label className="form-check-label">
                        <input
                          type="checkbox"
                          onChange={ handleEnabledForSFWidgetChange }
                          checked={ enabledForSFWidget }
                        />
                        Enabled for SF widget
                        <i className="input-helper"/>
                    </label>
                </div>
                <div className="form-check mr-sm-3">
                    <label className="form-check-label">
                        <input
                          type="checkbox"
                          onChange={ handleStockManagementChange }
                          checked={ stockManagement }
                        />
                        Stock Management
                        <i className="input-helper"/>
                    </label>
                </div>
            </div>}
            <div className="table-wrap">
              {kind === 'deliveryMethod' &&
              <div className={`table-wrap__folders ${folderPills ? 'table-wrap__folders_active' : ''}`}>
                <div className="d-flex justify-content-end">
                  <button type="button" className="table-wrap__toggle" onClick={() => setFolderPills(!folderPills)}>
                    <i className="fa fa-arrow-circle-right fa-2x"/>
                  </button>
                </div>
                <div className="table-wrap__menu">
                  <RegularButton className="mb-3 w-100" color="primary" onClick={onCreateFolder}>
                    <i className="fa fa-plus"/>
                    Add folder
                  </RegularButton>
                  <div className="nav flex-column nav-pills">
                    <div className="nav-item">
                      <button type="button" className={`nav-link ${folder === undefined ? 'active' : ''}`} onClick={() => setFolder(undefined)}>
                        <i className={`far mr-1 ${folder === undefined ? 'fa-folder-open' : 'fa-folder'}`} />All</button>
                    </div>
                    <div className="nav-item">
                      <button type="button" className={`nav-link ${folder === '' ? 'active' : ''}`} onClick={() => setFolder('')}>
                        <i className={`far mr-1 ${folder === '' ? 'fa-folder-open' : 'fa-folder'}`} />No folders</button>
                    </div>
                    {folders.length > 0 && folders.map((it) => {
                      return (<div className="nav-item" key={it.id}>
                        <button type="button" className={`nav-link ${it.folder === folder ? 'active' : ''}`} onClick={() => setFolder(it.folder)}>
                          <i className={`far mr-1 ${it.folder === folder ? 'fa-folder-open' : 'fa-folder'}`} />{it.folder}
                        </button>
                        <RegularButton notComponent type="button" className="btn btn-danger btn-xs" onClick={() => onDeleteFolder(it)} ><i className="fa fa-trash" /></RegularButton>
                      </div>)
                    }) }
                  </div>
                </div>
              </div>}
              <div className="table-wrap__data">
                <DataTable
                  filters={filters}
                  findAndCount={findAndCount}
                  fields={fields}
                  onDelete={onDelete}
                  onChange={onChange}
                  name = {"referencedata"}
                  reload={reload}
                  onMultipleSelect={kind === 'deliveryMethod' && (ids => {
                      setReload(!reload);
                      setMultipleSelectedIds(ids)
                  })}
                />
                { multipleSelectedIds.length > 0 && <ActionsButton ids={multipleSelectedIds}
                                                                   onSuccess={() => {
                                                                       setReload(!reload);
                                                                   }}
                /> }
              </div>
            </div>
        </div>
    </TableCard>
};