export const TIMESLOTS_FIELDS = ['monday_timeslot_id', 'tuesday_timeslot_id', 'wednesday_timeslot_id', 'thursday_timeslot_id',
    'friday_timeslot_id', 'saturday_timeslot_id', 'sunday_timeslot_id', 'holiday_timeslot_id']

export const noRangeFields = [
    {
        "id": "start_of_working_days",
        "name": "Start of working days",
        "validation": {
            "required": false,
            "pattern": "time"
        }
    },
    {
        "id": "end_of_working_days",
        "name": "End of working days",
        "validation": {
            "required": false,
            "pattern": "time"
        }
    },
    {
        "id": "min_express",
        "name": "Min express",
        "validation": {
            "required": false,
            "pattern": "number"
        }
    },
    {
        "id": "max_express",
        "name": "Max express",
        "validation": {
            "required": false,
            "pattern": "number"
        }
    },
]

export const noRange =   {
    "id": "no_range",
    "name": "No range",
    "validation": {
        "required": false,
        "pattern": "toggle"
    }
}