import React from "react";
import {Row} from "reactstrap";

class Index extends React.Component {
    render() {
        return (
            <div>
                <Row className="mt-2">
                    Index Page
                </Row>
            </div>
        )
    }
}

export default Index;