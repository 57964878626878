import React, { Component } from 'react';

import {Input} from "reactstrap";

const WAIT_INTERVAL = 2000;
const ENTER_KEY = 13;

export default class TextSearch extends Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.triggerChange = this.triggerChange.bind(this);

        this.state = {
            value: props.value || ""
        };
    }

    componentWillMount() {
        this.timer = null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.value !== this.props.value) this.setState({value: this.props.value || ""})
    }

    handleChange(e) {
        clearTimeout(this.timer);

        this.setState({ value: e.target.value });

        this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL);
    }

    handleKeyDown(e) {
        if (e.keyCode === ENTER_KEY) this.triggerChange();
    }

    triggerChange() {
        const { value } = this.state;

        if(this.props.onSearch) this.props.onSearch(value);
    }

    render() {
        const { className } = this.props;

        return (
            <Input
                className={className}
                value={this.state.value||""}
                onChange={this.handleChange}
                onKeyDown={this.handleKeyDown}
                placeholder={this.props.placeholder}
            />
        );
    }
}
