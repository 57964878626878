import React from "react";
//import DataTable, {field} from "../../generic/DataTable";
import DataTable, {field} from "../../generic/DataTable";
import {findAndCount} from "../../../service/role";
import moment from 'moment';
import PropTypes from "prop-types";

export default class Table extends React.Component {
    render() {
        const {date, material} = this.props;
        const filters = {};
        if(date) filters.SDate = moment(date).format("YYYY-MM-DD");
        if(material) filters.SMaterial = material;

        return <DataTable
            filters={filters}
            findAndCount={findAndCount}
            fields={[
                field("id", false),
                field("Name", false, "name"),
                field("Description", false, "description")
            ]}>
            name={"roles"}
        </DataTable>;
    }
}

Table.propTypes = {
    name: PropTypes.instanceOf(Date),
    material: PropTypes.string
};