import React, {useEffect, useState} from 'react'
import moment from 'moment';
import {Button, Form, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import { updateMethodActivation, deleteEntry, getEntry } from "../../../service/deliverymethodactivations";
import DatepickerWithTime from "../../generic/field/DatepickerWithTime";
import "./EDComponent.css";
import PropTypes from "prop-types";
import * as notify from "../../../lib/notify";
import RegularButton from "../../generic/RegularButton";

const EDComponent = ({ isModalOpen, modalType, closeModalConfirmation, rowId, setReload, methodId }) => {
    const [methodActivationDate, setMethodActivationDate] = useState(undefined);
    const [methodDeactivationDate, setMethodDeactivationDate] = useState(undefined);
    const [methodActivationTime, setMethodActivationTime] = useState(undefined);
    const [methodDeactivationTime, setMethodDeactivationTime] = useState(undefined);
    const onUpdate = () => {
        if((methodActivationDate && methodActivationTime) || (methodDeactivationDate && methodDeactivationTime)) {
            updateMethodActivation(rowId, {
                methodActivationDate,
                methodActivationTime,
                methodDeactivationDate,
                methodDeactivationTime,
                methodId
            }).then((res) => setReload(res)).catch(error => notify.showDangerToast(error.message));
            closeModalConfirmation();
        }
    };
    const onDelete = () => {
        deleteEntry(rowId).then(setReload);
        closeModalConfirmation();
    }

    useEffect(() => {
        loadData();

        return () => {
            setMethodActivationDate(undefined);
            setMethodActivationTime(undefined);
            setMethodDeactivationDate(undefined);
            setMethodDeactivationTime(undefined);
        }
    }, [rowId])

    const loadData = async () => {
        if(rowId) {
            const {activationDatetime, deactivationDatetime} = await getEntry(rowId);

            if(activationDatetime) {
                setMethodActivationDate(moment(activationDatetime).format('YYYY.MM.DD'));
                setMethodActivationTime(moment(activationDatetime).format('HH:mm'));
            }
            if(deactivationDatetime) {
                setMethodDeactivationDate(moment(deactivationDatetime).format('YYYY.MM.DD'));
                setMethodDeactivationTime(moment(deactivationDatetime).format('HH:mm'));
            }
        }
    }

    return <Modal isOpen={isModalOpen}>
        <ModalHeader>{modalType === 'update' ? 'Update activation' : 'Delete activation'}</ModalHeader>
        <ModalBody>
            <Form>
                <FormGroup>
                    <div className={"form-inline pl-2 ml-1 mb-3 mt-3"}>
                        {
                            (modalType === 'update') ?
                                <>
                                    <DatepickerWithTime
                                        datepickerDaySelected={methodActivationDate}
                                        datepickerPlaceholder={'Choose start date'}
                                        datepickerSearchFunction={value => setMethodActivationDate(value)}
                                        timepickerPlaceholder={'Start time'}
                                        timepickerSearchFunction={value => {
                                            if(value) {
                                                setMethodActivationTime(value.format('HH:mm'))
                                            } else {
                                                setMethodActivationDate(undefined);
                                                setMethodActivationTime(undefined);
                                            }
                                        }}
                                        timepickerValue={methodActivationTime}
                                        className={'startDate'}
                                    />
                                    <label className="ml-3 mr-3 col-form-label-sm"> — </label>
                                    <DatepickerWithTime
                                        datepickerDaySelected={methodDeactivationDate}
                                        datepickerPlaceholder={'Choose end date'}
                                        datepickerSearchFunction={value => setMethodDeactivationDate(value)}
                                        timepickerPlaceholder={'End time'}
                                        timepickerSearchFunction={value => {
                                            if(value) {
                                                setMethodDeactivationTime(value.format('HH:mm'))
                                            } else {
                                                setMethodDeactivationDate(undefined);
                                                setMethodDeactivationTime(undefined);
                                            }
                                        }}
                                        timepickerValue={methodDeactivationTime}
                                        className={'endDate'}
                                    />
                                </>
                                :
                                <>
                                    <p>Are you sure you want to delete this activation?</p>
                                </>
                        }
                    </div>
                </FormGroup>
            </Form>
        </ModalBody>
        <ModalFooter>
            {
                modalType === 'update' ? <RegularButton color="primary" onClick={ () => onUpdate() }>Update</RegularButton>
                    :
                    <RegularButton color="danger" onClick={ () => onDelete() }>Delete</RegularButton>
            }
            <Button color="secondary" onClick={ () => closeModalConfirmation() }>Cancel</Button>
        </ModalFooter>
    </Modal>
}

EDComponent.propTypes = {
    isModalOpen: PropTypes.bool,
    modalType: PropTypes.string,
    closeModalConfirmation: PropTypes.func,
    rowId: PropTypes.number,
    setReload: PropTypes.func,
    methodId: PropTypes.number
}

export default EDComponent;