import React from "react";
import PrivateRoute from '../../../route/PrivateRoute';
import { SubMenuLink } from '../../../components/generic/links';
import deliveryMethodProductsData from './deliveryMethodProductsData/Index';
import Upload from './upload/Route';
import { Redirect, Switch } from "react-router-dom";

const Index = props => {
    return (
        <div>
            <ul className="nav nav-tabs">
                <SubMenuLink to={ "/input/deliveryMethodProducts/upload" } { ...props }>Upload</SubMenuLink>
            </ul>
            <Switch>
                <Redirect push exact from="/input/deliveryMethodProducts" to="/input/deliveryMethodProducts/upload"/>
                <PrivateRoute path="/input/deliveryMethodProducts/deliveryMethodProductsData" component={ deliveryMethodProductsData }/>
                <PrivateRoute path="/input/deliveryMethodProducts/upload" component={ Upload }/>
            </Switch>
        </div>
    )
}

export default Index;