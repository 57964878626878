import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, FormGroup, Input } from 'reactstrap';
import { getEntry, saveEntry } from '../../service/publicTokens';
import { useForm } from 'react-hook-form';
import Loader from '../generic/Loader';
import { useHistory } from 'react-router-dom';
import * as notify from '../../lib/notify';

const PublicTokensEdit = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const form = useForm();
  const { register, reset } = form;

  const id = props.match.params.id;

  const onSubmit = (data) => {
    saveEntry(id, data).then(() => {
      history.push('/administration/public-tokens');
      notify.showSuccessToast("Success");
    })
  }

  useEffect(() => {
    if (id) {
      setLoading(true);
      getEntry(id)
        .then(reset)
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id]);

  return loading ? <Loader/> : (
    <Card>
      <CardHeader>{id ? 'Edit' : 'Create'} Public Token</CardHeader>
      <CardBody>
        <FormGroup>
          <label>Name</label>
          <Input
            name="name"
            placeholder="Enter Name"
            innerRef={register()}
          />
        </FormGroup>
        <FormGroup>
          <label>Token</label>
          <Input
            name="token"
            placeholder="Enter Token"
            innerRef={register()}
          />
        </FormGroup>
        <Button color="primary" onClick={form.handleSubmit(onSubmit)}>Save</Button>
      </CardBody>
    </Card>
  )
}

export default PublicTokensEdit;