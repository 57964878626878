import React from "react";
import SortableColumn from "./SortableColumn";
import PropTypes from "prop-types";

export default class SortableTable extends React.Component {

    constructor(props) {
        super(props);

        this.handleSort = this.handleSort.bind(this);

        this.state = {
            sortField: this.props.sortField,
            sortDir: this.props.sortDir,
            multipleSelectAll: false
        };
    }

    /**
     * Обновляет сортировку при клике по столбцу
     * @param sortField - ключ поля
     * * @param sortDir - направление соритровки asc или desc
     */
    handleSort(sortField, sortDir) {
        this.setState({sortField: sortField, sortDir: sortDir});
        this.props.handleSort(sortField, sortDir);
    }

    onMultipleSelectAll(checked) {
        this.setState({multipleSelectAll: checked});
        this.props.onMultipleSelectAll(checked);
    }

    /**
     * Обновляет сортировку, если поля изменяются снаружи
     * @param prevProps
     */
    componentDidUpdate(prevProps) {
        const {sortDir, sortField} = this.props;
        if (sortDir !== prevProps.sortDir || sortField !== prevProps.sortField)
            this.setState({sortDir, sortField});
    }

    render() {
        return <table className={this.props.className ||
        ("table table-striped table-bordered" + (this.props.hover ? " table-hover" : ""))}>
            <thead>
            <tr>
                {this.props.hasMultipleSelection && <th>
                    <input
                        onChange={event => this.onMultipleSelectAll(event.target.checked)}
                        checked={this.state.multipleSelectAll ? 'checked' : ''}
                        className="checkbox"
                        type="checkbox"
                    />
                </th>}
                {this.props.fields.map(field =>
                    field.isSortable ?
                        <SortableColumn key={field.key}
                                        sort={this.state.sortField === field.key ? this.state.sortDir : null}
                                        handleSort={sortDir => this.handleSort(field.key, sortDir)} {...field.props}
                                        className={field.className}>
                            {field.label}
                        </SortableColumn> :
                        <th key={field.key} className={field.className}>{field.label}</th>
                )}
            </tr>
            </thead>
            <tbody>
            {this.props.children}
            </tbody>
        </table>;
    }
}

export class HeaderConfig {
    constructor(label, isSortable, key, className) {
        this.label = label;
        this.isSortable = isSortable || false;
        this.key = key || label;
        this.className = className;
    }
}

export const headerConfig = (label, isSortable, key, className) => new HeaderConfig(label, isSortable, key, className)

SortableTable.propTypes = {
    fields: PropTypes.arrayOf(PropTypes.instanceOf(HeaderConfig)),
    className: PropTypes.string,
    sortField: PropTypes.string,
    sortDir: PropTypes.string,
    handleSort: PropTypes.func.isRequired,
    hover: PropTypes.bool
};
