import React, {useState} from "react";
import {Button, FormGroup, Label, Row} from "reactstrap";
import {useForm} from "react-hook-form";
import {Link} from "react-router-dom";
import {forgotPassword} from "../../service/forgotPassword";
import * as notify from "../../lib/notify";
import './Index.css';
import LoaderOverlay from "../generic/LoaderOverlay";

export default function Index() {
    const { register, handleSubmit, formState: { errors } } = useForm({});
    const [isLoading, setIsLoading] = useState(false)

    const onSave = async data => {
        setIsLoading(true)
        forgotPassword(data).then(response => {
            if(response && response.message) notify.showSuccessToast('Password has been reset, please check your email')
            else notify.showDangerToast('User not found or does not exists')
        }).catch(e => notify.showDangerToast(e.message)).finally(() => setIsLoading(false));
    };

    return (
        <form onSubmit={handleSubmit(onSave)} className="d-flex justify-content-center">
            <FormGroup className="forgot-password-form">
                <LoaderOverlay isVisible={isLoading}>
                    <Label for="email">Email</Label>
                    <input
                        name="email"
                        placeholder="Enter email"
                        className={`form-control mb-2 ${errors.email ? "is-invalid" : ""}`}
                        ref={register({
                            required: "Email is required",
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "Invalid email address"
                            }
                        })}
                        type="email"
                    />
                    {(errors && errors.email) && <div className="invalid-feedback">{errors.email.message}</div>}
                </LoaderOverlay>
                <Row className="mt-3">
                    <Button disabled={isLoading} color="primary" className="mr-2 d-block" onClick={() => {
                        handleSubmit(onSave)()
                    }}>Restore password</Button>
                    <Link className="btn btn-default"
                          to={'/login'}>
                        Return to login
                    </Link>
                </Row>
            </FormGroup>
        </form>
    )
}