import {useState} from "react";

const useTable = () => {
    const [openDeleteModalConfirmation, setOpenDeleteModalConfirmation] = useState(false);
    const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
    const [modalType, setModalType] = useState(undefined);
    const [id, setId] = useState(undefined);
    const [reload, setReload] = useState(false);
    const [changeData, setChangeData] = useState({});

    const closeModalConfirmation = () => {
        setChangeData({})
        setOpenDeleteModalConfirmation(false)
        setOpenModalConfirmation(false)
        setId(undefined)
        setModalType(undefined)

        return () => setReload(!reload)
    }

    const onDelete = ({ id }) => {
        setOpenDeleteModalConfirmation(true)
        setId(id)
    }

    const onChange = (data) => {
        setModalType('edit')
        setChangeData(data)
        setOpenModalConfirmation(true)
    }

    const onCreate = (data) => {
        setModalType('create')
        setChangeData(data)
        setOpenModalConfirmation(true)
    }

    const onCopy = (data) => {
        setModalType('create')
        setChangeData(data)
        setOpenModalConfirmation(true)
    }

    return {
        openDeleteModalConfirmation,
        openModalConfirmation,
        modalType,
        id,
        changeData,
        reload,
        onChange,
        onCreate,
        onCopy,
        onDelete,
        closeModalConfirmation
    }
}

export default useTable;