import React from "react";
import DataTable, {field} from "../../../../generic/DataTable";
import Card from "../../../../generic/Card";
import './Tables.css';
import { findAndCount } from "../../../../../service/deliveryMethodProductsRaw"
import { getLabelOfDeliveryMethod } from "../../../../../common";
import RegularButton from "../../../../generic/RegularButton";

const MappingIssues = ({ inputFileId, reload, onConfirm, openMappingModal, methods }) => {

    const filters = {};

    const modelCell = res => !res ? "" :
        <div className="buttons btn-group">
            <RegularButton type="button" className="btn btn-success btn-xs" onClick={() => openMappingModal(res)}>
                <i className="fa fa-marker" />
            </RegularButton>
        </div>;

    filters.input_file_id = inputFileId;
    filters.mapping = true;

    return <Card title="Mapping issues">
        <DataTable
            limit={5}
            filters={filters}
            findAndCount={findAndCount}
            fields={[
                field("Code", false, "code", null, "cancel-nowrap"),
                field("Method", false, "method", response => getLabelOfDeliveryMethod(methods, response), "cancel-nowrap"),
                field("SKU", false, "SKU", null, "cancel-nowrap"),
                field("Availability", false, "availability", value => value.availability !== null ? !!value.availability : '', "cancel-nowrap"),
                field(<></>, false, "code",
                  res => modelCell(res))
            ]}
            rowClass={() => "table-danger"}
            reload={reload}
            emptyContent={
                <p className="text-center">
                    <i className="fa fa-check-square fa-6x" style={{color: "#0acf97"}}/>
                    <br/>
                    All records are mapped
                    <br/>
                    <br/>
                    <RegularButton onClick={onConfirm} color="success">Confirm and Load</RegularButton>
                </p>
            }
            totalRowRender={() => ""}
            name={"mapping_issues"}
        />
    </Card>
}

export default MappingIssues;