import {Label} from "reactstrap";
import React from "react";
import PropTypes from "prop-types";

const validationMapper = {
    number: /^\d+$/,
    time: /^((0?[0-9]|1[0-9]|2[0-3])[:][0-5][0-9]([:][0-5][0-9])?)$/,
}

const Field = ({register, it, defaultValue, error}) => {
    return (
        <div className="col-lg-4 col-md-6 mb-3">
            <Label for={it.id}>{it.name}</Label>
            <input
                name={it.id}
                placeholder={`Enter ${it.name.toLowerCase()}...`}
                className={`form-control ${error ? "is-invalid" : ""}`}
                defaultValue={defaultValue}
                ref={register({
                        required: {
                            value: it.validation.required,
                            message: 'The field is required'
                        },
                        pattern: {
                            value: validationMapper[it.validation.pattern],
                            message: 'The field is filled in incorrectly'
                        }
                    }
                )}
            />
            {error && <span className="input-error">{error.message}</span>}
        </div>)
}

Field.propTypes = {
    register: PropTypes.func.isRequired,
    it: PropTypes.object.isRequired,
    defaultValue: PropTypes.string,
    error: PropTypes.object
}

export default React.memo(Field);