import React from "react";
import ReactQueryParams from 'react-query-params';
import SortableTable, {HeaderConfig} from "../generic/SortableTable";
import LoaderOverlay from "../generic/LoaderOverlay";
import {showDangerToast} from "../../lib/notify";
import Pager from "../generic/Pager";
import PropTypes from "prop-types";
import {setCookie} from "../../lib/session";
import {Button} from 'reactstrap';
import "./DataTable.css";
import {withRouter} from "react-router-dom";


class DataTable extends ReactQueryParams {

    constructor(props) {
        super(props);

        this.loadData = this.loadData.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
        this.handleCellClick = this.handleCellClick.bind(this);
        this.onMultipleSelectionClick = this.onMultipleSelectionClick.bind(this);
        this.onMultipleSelectAll = this.onMultipleSelectAll.bind(this);

        this.state = {
            sortField: props.sortField,
            sortDir: props.sortDir,
            start: 0,
            loading: false,
            data: [],
            multipleSelectedIds: [],
            multipleSelectAll: false
        };
    }

    componentDidMount() {
        this.loadData();
    }

    componentWillMount() {
        if(this.queryParams[this.props.name + '_start'] && this.queryParams[this.props.name + '_limit']) {
            const newState = {};
            Object.assign(newState, this.state);
            newState[this.props.name + '_start'] = this.queryParams[this.props.name + '_start'];
            newState[this.props.name + '_limit'] = this.queryParams[this.props.name + '_limit'];
            this.setState(newState);
        } else {
            const newQueryState = {};
            newQueryState[this.props.name + "_start"] = 0;
            newQueryState[this.props.name + "_limit"] = 10;
            const newState = {};
            Object.assign(newState, this.state);
            newState[this.props.name + "_start"] = 0;
            newState[this.props.name + "_limit"] = 10;
            this.setQueryParams(newQueryState);
            this.setState(newState);
        }
    }

    loadData() {
        const {
            sortField,
            sortDir,
        } = this.state;
        const start = this.state[this.props.name + "_start"];
        const limit  = this.state[this.props.name + "_limit"];

        const filters = Object.assign({}, this.props.filters);


        this.setState({loading: true});
        return this.props.findAndCount(start, limit, sortField, sortDir, filters)
            .then(({data, total, totalRow}) => {
                this.setState({data, total, totalRow, loading: false})
            })
            .catch(e => {
                this.setState({loading: false});
                if (e.message === "Forbidden") this.props.history.push('/');
                showDangerToast(e);
            });
    }

    handleSort(sortField, sortDir) {
        this.setState({sortField, sortDir});
    }

    handleRowClick(e, row) {
        if (this.props.onCellClick) return;
        e.preventDefault();
        if (this.props.onClick) this.props.onClick(row);
    }

    handleCellClick(e, row, field) {
        if (!this.props.onCellClick) return;
        e.preventDefault();
        this.props.onCellClick(row, field);
    }

    filterDataIds(ids, data) {
        return (ids || []).filter(id => (data || []).find(x => x[this.props.rowKey] === id));
    }

    multipleSelectedItems() {
        return (this.state.data || []).filter(x => x[this.props.rowKey] && this.state.multipleSelectedIds &&
            this.state.multipleSelectedIds.includes(x[this.props.rowKey]));
    }

    onMultipleSelectionClick(id, item, checked) {
        let newArray = this.filterDataIds(this.state.multipleSelectedIds, this.state.data).filter(x => x !== id);

        if(checked) newArray = newArray.concat(id);

        this.setState({
            multipleSelectedIds: newArray
        }, () => {
            if(this.props.onMultipleSelect) this.props.onMultipleSelect(this.state.multipleSelectedIds, this.multipleSelectedItems);
        })
    }

    onMultipleSelectAll(checked) {
        let newArray = [];

        if(checked) newArray = (this.state.data || []).map(x => x[this.props.rowKey]);

        this.setState({
            multipleSelectAll: checked,
            multipleSelectedIds: newArray
        }, () => {
            if(this.props.onMultipleSelect) this.props.onMultipleSelect(this.state.multipleSelectedIds, this.multipleSelectedItems);
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {filters, reload} = this.props;
        const {sortDir, sortField} = this.state;
        const start = this.state[this.props.name + "_start"];
        const limit  = this.state[this.props.name + "_limit"];

        const objectsNotEquals = (obj1, obj2) =>
            Object.keys(obj1).length !== Object.keys(obj2).length ||
            Object.keys(obj1).some(key => obj1[key] !== obj2[key]);

        if(objectsNotEquals(prevProps.filters, filters)) {
            this.setQueryParams({
                [this.props.name + "_start"]: 0
            })
        }

        if (prevProps.reload !== reload ||
            prevState.sortDir !== sortDir ||
            prevState.sortField !== sortField ||
            prevState[this.props.name + "_start"] !== start ||
            prevState[this.props.name + "_limit"] !== limit ||
            objectsNotEquals(prevProps.filters, filters)
        ) {
            const currentStartValue = this.state[this.props.name + "_start"];
            let newStartValue = this.queryParams[this.props.name + "_start"];
            
            if (!newStartValue) newStartValue = currentStartValue

            if(currentStartValue !== newStartValue) {
                this.setState({
                    [this.props.name + "_start"]: newStartValue
                }, () => this.loadData().catch(showDangerToast));
            } else {
                this.loadData().catch(showDangerToast);
            }
        }
    }

    render() {
        const {data, loading, sortField, sortDir, totalRow} = this.state;
        const {emptyContent, fields, onClick, onChange, onDelete, tableButtons, additionalButtons, onMultipleSelect, multipleSelection} = this.props;
        const hasMultipleSelection = !!onMultipleSelect || multipleSelection;

        const dataParameters = {sortField, sortDir, start: this.state[this.props.name + "_start"], limit: this.state[this.props.name + "_limit"], filters: {...this.props.filters}};

        const hasButtons = onChange || onDelete || additionalButtons;

        const totalRowRender = this.props.totalRowRender || (totalRow => <tr className="totalRow">
            {hasMultipleSelection && <th/>}
            {fields.map(field =>
                <th key={`total-row-cell-${field.key}`}>
                    {field.render(totalRow)}
                </th>
            )}
            {hasButtons && <th/>}
        </tr>);

        return <LoaderOverlay isVisible={loading} className="data-table-content">
            {((!data || data.length === 0) && emptyContent) ? emptyContent :
                <div className="table-responsive data-table-content">
                    <SortableTable handleSort={this.handleSort}
                                   fields={fields.map(x => x.headerConfig).concat(hasButtons ? [new HeaderConfig("", false, "action-column")] : [])}
                                   sortField={sortField}
                                   hasMultipleSelection={hasMultipleSelection}
                                   onMultipleSelectAll={this.onMultipleSelectAll}
                                   multipleSelectAll={this.state.multipleSelectAll}
                                   sortDir={sortDir}
                                   hover={!!onClick}>
                        {totalRow && totalRowRender(totalRow)}
                        {data && data.map(res => (
                            <tr className={this.props.rowClass && this.props.rowClass(res)} key={res[this.props.rowKey]}
                                onClick={e => this.handleRowClick(e, res)}>
                                {hasMultipleSelection &&
                                <td>
                                    <input
                                        onChange={event => this.onMultipleSelectionClick(res[this.props.rowKey], res, event.target.checked)}
                                        checked={this.state.multipleSelectedIds && this.state.multipleSelectedIds.find(x => x === res[this.props.rowKey]) ? 'checked' : ''}
                                        className="checkbox"
                                        type="checkbox"
                                    />
                                </td>
                                }
                                {fields.map(field =>
                                    <td key={`row-${res[this.props.rowKey]}-cell-${field.key}`}
                                        onClick={e => this.handleCellClick(e, res, field)}
                                        className={field.getClassName(res, field)}>
                                        {typeof field.render(res) === 'boolean' ? field.render(res).toString() : field.render(res)}
                                    </td>
                                )}
                                {
                                    hasButtons && (
                                        <td className="small-padding small-width">
                                            <div className="btn-group">
                                                {additionalButtons && additionalButtons(res)}
                                                {onChange && <Button color="success" size="xs" onClick={() => onChange(res)}><i className="fa fa-marker"/></Button>}
                                                {onDelete && <Button color="danger" size="xs" onClick={() => onDelete(res)}><i className="fa fa-trash"/></Button>}
                                            </div>
                                        </td>
                                    )
                                }
                            </tr>
                        ))}
                    </SortableTable>
                </div>
            }
            <div className={"mt-2"}>
                <Pager
                    start={Number(this.state[this.props.name + "_start"])}
                    total={this.state.total}
                    limit={Number(this.state[this.props.name + "_limit"])}
                    onPageChange={start => {
                        const newQueryState = {}, newState = {};
                        Object.assign(newQueryState, this.queryParams);
                        newQueryState[this.props.name + "_start"] = start;
                        this.setQueryParams(newQueryState);
                        Object.assign(newState, this.state);
                        newState[this.props.name + "_start"] = start;
                        this.setState(newState)
                    }}
                    onLimitChange={limit => {
                        setCookie(`${this.props.name}-limit`, Number(limit), {secure: true, sameSite: 'strict'});
                        const newQueryState = {}, newState = {};
                        Object.assign(newQueryState, this.queryParams);
                        newQueryState[this.props.name + "_limit"] = limit;
                        this.setQueryParams(newQueryState);
                        Object.assign(newState, this.state);
                        newState[this.props.name + "_limit"] = limit;
                        this.setState(newState)
                    }}
                    canChangeLimit={!!this.props.name}
                />
                {tableButtons && tableButtons(dataParameters)}
            </div>
        </LoaderOverlay>;
    }
}

export class Field {
    constructor(label, isSortable, key, render, className) {
        this.getClassName = this.getClassName.bind(this);
        this.label = label;
        this.isSortable = isSortable || false;
        this.key = key || label;
        this.render = render || (x => Field.defaultRender(x[this.key]));
        this.className = className;
    }

    get headerConfig() {
        return new HeaderConfig(this.label, this.isSortable, this.key)
    }

    static defaultRender(value) {
        //Display decimal with two places
        if(Number(value) === value && value % 1 !== 0) {
            return parseFloat(Math.round(value * 100) / 100).toFixed(2);
        } else return value;
    }

    getClassName(res, field) {
        if(!this.className) return undefined;
        if(typeof this.className === "function") return this.className(res, field);
        return this.className;
    }
}

export default withRouter(DataTable);

/**
 * Конфигурация поля таблицы
 * @param label - Название поля. Может быть тегом <i>курсив</i>
 * @param isSortable - Флаг сортировки. По умолчанию выключена
 * @param key - ключ поля (название поля в таблице). По умолчанию равен label
 * @param render - функция отображения поля. По умолчанию res => res[key] - значение по ключу поля
 * @param {string|function} className - класс ячейки td
 * @returns {Field}
 */
export const field = (label, isSortable, key, render, className) => new Field(label, isSortable, key, render, className);

DataTable.propTypes = {
    filters: PropTypes.object,
    findAndCount: PropTypes.func.isRequired,
    fields: PropTypes.arrayOf(PropTypes.instanceOf(Field)),
    sortField: PropTypes.string,
    sortDir: PropTypes.string,
    rowKey: PropTypes.string,
    onClick: PropTypes.func,
    onCellClick: PropTypes.func,
    name: PropTypes.string,
    reload: PropTypes.any,
    rowClass: PropTypes.func,
    emptyContent: PropTypes.node,
    limit: PropTypes.number,
    totalRowRender: PropTypes.func,
    onDelete: PropTypes.func,
    onChange: PropTypes.func,
    additionalButtons: PropTypes.func,
    showOnlyActiveDealers: PropTypes.bool
};

DataTable.defaultProps = {
    filters: {},
    sortField: "id",
    sortDir: "desc",
    rowKey: "id",
    name: ""
};

