import React from 'react'

import {Route, Switch,} from 'react-router-dom';
import Index from "./Index";
import Create from "./Create"
import Process from "./Process"

export default class IndexRoute extends React.Component {
    render() {
        return (<Switch>
            <Route exact path="/input/weekends/upload" component={Index} />
            <Route path="/input/weekends/upload/new" component={Create} />
            <Route path="/input/weekends/upload/:id" component={Process} />
        </Switch>);
    }
};