import React from 'react'
import PropTypes from 'prop-types';
import {Badge, Input} from 'reactstrap';

export const statuses = [
    {value: "upload", label: "Upload", color: "light", icon: "fas fa-cog fa-spin"},
    {value: "process", label: "Process", color: "warning", icon: "fas fa-cog fa-spin"},
    {value: "mapping", label: "Mapping", color: "primary"},
    {value: "loading", label: "Check", color: "info", icon: "fas fa-cog fa-spin"},
    {value: "success", label: "Success", color: "success"},
    {value: "fail", label: "Fail", color: "danger"},
    {value: "cancel", label: "Cancelled", color: "secondary"},
];

export const values = statuses.map(x => x.value);

export class InputFileStatusSelect extends React.Component {

    handleChange(event) {
        event.preventDefault();
        this.props.onChange(event.target.value);
    }

    render() {
        return <Input type="select" name="select" value={this.props.value} onChange={this.handleChange}>
            <option>Choose status</option>
            {statuses.map(status => <option key={status.value} value={status.value}>{status.label}</option>)}
        </Input>
    }
}

InputFileStatusSelect.propTypes = {
    value: PropTypes.oneOf(values),
    onChange: PropTypes.func.isRequired
};

export class InputFileStatusBadge extends React.Component {
    render() {
        const status = statuses.find(x => x.value === this.props.value);
        if(!status) return "";
        return <Badge color={this.props.failed ? "danger" : status.color}>
            {status.icon && <span><i className={status.icon}/>&nbsp;</span>}
            {status.label}
        </Badge>;
    }
}

InputFileStatusBadge.propTypes = {
    value: PropTypes.oneOf(values),
    failed: PropTypes.bool
};

export default {statuses, values, InputFileStatusSelect, InputFileStatusBadge};