import React, {Fragment} from 'react';
import {DropdownToggle, UncontrolledButtonDropdown, DropdownMenu, DropdownItem} from 'reactstrap';
import PropTypes from "prop-types";
import ModalConfirmation from "./ModalConfirmation";
import { exportStatuses } from "../../common/index";
import {downloadLink} from "../../service/export";

const statuses = exportStatuses.status;

class ExportButton extends React.Component {

    constructor(props) {
        super(props);

        this.onSelect = this.onSelect.bind(this);
        this.state = {
            showModal: false
        }
    }

    async onSelect(mode) {
        this.props.exportFun(mode).then(result => {
            this.setState({exportData: result, showModal: true})
        })
    }

    render() {
        const {exportData, showModal} = this.state;
        const {status, description} = exportData || {};
        return <Fragment>
            <UncontrolledButtonDropdown direction="up">
                <DropdownToggle className="btn-outline-secondary btn-sm" caret>
                    Export
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem onClick={() => this.onSelect(ExportButton.All)}>All table</DropdownItem>
                    <DropdownItem onClick={() => this.onSelect(ExportButton.Page)}>Current page</DropdownItem>
                </DropdownMenu>
            </UncontrolledButtonDropdown>
            <ModalConfirmation onClose={() => this.setState({showModal: false})}
                               onConfirm={() => this.setState({showModal: false})}
                               isOpen={!!showModal}
                               hasCancel={false}
                               title={"File export"}
                               confirmButtonText={"Ok"}
                               color={"primary"}
                               areButtonsNeeded={false}
                               text={
                                   <Fragment>
                                       {status === statuses.Error && description}
                                       {status === statuses.Ready &&
                                       <p>
                                           <a href={downloadLink(exportData)} rel="noopener noreferrer" target="_blank"
                                              download>Download file: <strong>{exportData.filename}</strong></a>
                                           <br/>
                                       </p>
                                       }
                                   </Fragment>
                               }/>
        </Fragment>
    }
}

ExportButton.propTypes = {
    /**
     * function(mode: string): Promise<{id: string, status: string, filename: string}>
     */
    exportFun: PropTypes.func.isRequired,
};

ExportButton.All = "all";
ExportButton.Page = "page";

export default ExportButton;