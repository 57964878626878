import React from "react";
import DataTable, {field} from "../../generic/DataTable";
import { findAndCount } from "../../../service/stocksReporting";
import { datetime } from "../../../lib/format";
import PropTypes from "prop-types";
import "./styles.css";


const Table = ({ filters, onDataTableChange }) => {
    return (
        <DataTable
            filters={filters}
            findAndCount={findAndCount}
            fields={[
                field('BV Code', true, 'bvCode'),
                field("Available QTY", true, "availableQuantity"),
                field('Warehouse Code', true, 'warehouseCode'),
                field("Warehouse Name", true, "warehouseName"),
                field("Creation Date", true, "creationDate", response => datetime(response.creationDate)),
            ]}
            name={"stocks"}
            tableButtons={dataParameters => onDataTableChange(dataParameters)}
        />
    );
}

Table.propTypes = {
    filters: PropTypes.object,
    onDataTableChange: PropTypes.func
}

export default Table;