import React from "react";
import TableCard from "../generic/TableCard";
import Table from "./Table";
import TextSearch from "../generic/field/TextSearch";
import { Button } from "reactstrap";
import { pickBy } from 'lodash';
import { useQueryParam, StringParam, NumberParam, DateParam } from 'use-query-params';
import 'rc-time-picker/assets/index.css';
import './Index.css';
import DatepickerWithTime from "../generic/field/DatepickerWithTime";

export default function Index() {
    const [filterMatrixId, setFilterMatrixId] = useQueryParam('matrixId', NumberParam);
    const [filterMatrixName, setFilterMatrixName] = useQueryParam('matrixName', StringParam);
    const [filterMatrixActivationDate, setFilterMatrixActivationDate] = useQueryParam('matrixActivationDate', DateParam);
    const [filterMatrixDeactivationDate, setFilterMatrixDeactivationDate] = useQueryParam('matrixDeactivationDate', DateParam);
    const [filterMatrixActivationTime, setFilterMatrixActivationTime] = useQueryParam('matrixActivationTime', StringParam);
    const [filterMatrixDeactivationTime, setFilterMatrixDeactivationTime] = useQueryParam('matrixDeactivationTime', StringParam);

    const searchMatrixId = filterMatrixId => {
        setFilterMatrixId(filterMatrixId)
    };

    const searchMatrixName = filterMatrixName => {
        setFilterMatrixName(filterMatrixName)
    };

    const searchFilterMatrixActivationDate = filterMatrixActivationDate => {
        setFilterMatrixActivationDate(filterMatrixActivationDate)
    };

    const searchFilterMatrixActivationTime = filterMatrixActivationTime => {
        setFilterMatrixActivationTime(filterMatrixActivationTime.format('HH:mm'))
    };

    const searchFilterMatrixDeactivationDate = filterMatrixDeactivationDate => {
        setFilterMatrixDeactivationDate(filterMatrixDeactivationDate)
    };

    const searchFilterMatrixDeactivationTime = filterMatrixDeactivationTime => {
        setFilterMatrixDeactivationTime(filterMatrixDeactivationTime.format('HH:mm'))
    };

    const dropFilters = () => {
        setFilterMatrixId(undefined);
        setFilterMatrixName(undefined);
        setFilterMatrixActivationDate(undefined);
        setFilterMatrixDeactivationDate(undefined);
        setFilterMatrixActivationTime(undefined);
        setFilterMatrixDeactivationTime(undefined);
    };

    const filters = pickBy({
        id: filterMatrixId,
        name: filterMatrixName,
        activationDate: filterMatrixActivationDate,
        deactivationDate: filterMatrixDeactivationDate,
        activationTime: filterMatrixActivationTime,
        deactivationTime: filterMatrixDeactivationTime
    })

    return (
        <TableCard>
            <div style={{minHeight: "400px"}}>
                <div className={"row justify-content-start ml-1 mb-3"}>
                    <TextSearch
                        name="id"
                        onSearch={ searchMatrixId }
                        className="form-control col-sm-3 mr-2"
                        value={ filterMatrixId }
                        placeholder={"Enter id..."}
                    />
                    <TextSearch
                        name="name"
                        onSearch={ searchMatrixName }
                        className="form-control col-sm-3 mr-2"
                        value={ filterMatrixName }
                        placeholder={"Enter matrix name..."}
                    />
                    <Button className="mr-sm-2" color="default" onClick={ dropFilters }>
                        <i className="fa fa-fw fa-times"/>
                        Cancel
                    </Button>
                </div>
                <div className={"row justify-content-start ml-3 mb-3"}>
                    <DatepickerWithTime
                        datepickerDaySelected={filterMatrixActivationDate}
                        datepickerSearchFunction={searchFilterMatrixActivationDate}
                        datepickerPlaceholder={'Choose start date'}
                        timepickerPlaceholder={'Start time'}
                        timepickerSearchFunction={searchFilterMatrixActivationTime}
                        timepickerValue={filterMatrixActivationTime}
                    />
                    <label className="ml-3 mr-3 col-form-label-sm"> — </label>
                    <DatepickerWithTime
                        datepickerDaySelected={filterMatrixDeactivationDate}
                        datepickerSearchFunction={searchFilterMatrixDeactivationDate}
                        datepickerPlaceholder={'Choose end date'}
                        timepickerPlaceholder={'End time'}
                        timepickerSearchFunction={searchFilterMatrixDeactivationTime}
                        timepickerValue={filterMatrixDeactivationTime}
                    />
                </div>
                <Table filters={ filters } />
            </div>
        </TableCard>
    )
}