import React, {Fragment, useState} from 'react';
import {DropdownToggle, UncontrolledButtonDropdown, DropdownMenu, DropdownItem} from 'reactstrap';
import ModalConfirmation from "../../generic/ModalConfirmation";
import {deleteEntry, updateEntry} from "../../../service/referenceData";


const ActionsButton = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [popupText, setPopupText] = useState('');
    const [actionType, setActionType] = useState('');

    const onSelect = actionType => {
        if(actionType === 'enable') setPopupText('Are you sure you want to enable the selected methods?');
        if(actionType === 'disable') setPopupText('Are you sure you want to disable the selected methods?');
        if(actionType === 'delete') setPopupText('Are you sure you want to delete the selected methods?');

        setShowModal(true);
        setActionType(actionType);
    }

    return <div>
        <UncontrolledButtonDropdown direction="up">
            <DropdownToggle className="btn-outline-secondary btn-sm" caret>
                Actions
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem onClick={() => onSelect('enable')}>Enable</DropdownItem>
                <DropdownItem onClick={() => onSelect('disable')}>Disable</DropdownItem>
                <DropdownItem onClick={() => onSelect('delete')}>Delete</DropdownItem>
            </DropdownMenu>
        </UncontrolledButtonDropdown>
        <ModalConfirmation onClose={() => setShowModal(false)}
                           onConfirm={async () => {
                               setShowModal(false)

                               if(actionType === 'enable') {
                                   await Promise.all(props.ids.map(id => updateEntry(id, { enabledForSFWidget: true })))
                               }
                               if(actionType === 'disable') {
                                   await Promise.all(props.ids.map(id => updateEntry(id, { enabledForSFWidget: false })))
                               }
                               if(actionType === 'delete') {
                                   await Promise.all(props.ids.map(id => deleteEntry(id)))
                               }

                               props.onSuccess();
                           }}
                           isOpen={!!showModal}
                           hasCancel={false}
                           title={"Confirmation"}
                           confirmButtonText={"Ok"}
                           color={"primary"}
                           areButtonsNeeded={true}
                           text={
                               <Fragment>
                                   {popupText}
                               </Fragment>
                           }/>
    </div>
}

export default ActionsButton;