import React, {useEffect, useState} from "react";
import {Button, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import { changeBVCodeAndAvailabilityInRawTable } from "../../../../service/deliveryMethodProductsRaw";

const MappingModal = ({ isOpen, onToggle, reloadRawTable, code, mappingRow }) => {

  const [bvCode, setBVCode] = useState(code === null ? '' : code);
  const [availability, setAvailability] = useState(mappingRow ? !!mappingRow.availability : false);

  useEffect(() => {
    setBVCode(code === null ? '' : code);
  }, [code])

  useEffect(() => {
    if(mappingRow) {
      setAvailability(!!mappingRow.availability);
    }
  }, [mappingRow])


  const handleCodeChange = event => {
    setBVCode(event.target.value);
  }

  const handleAvailabilityChange = () => {
    setAvailability(!availability);
  }

  const onSave = () => {
    changeBVCodeAndAvailabilityInRawTable(bvCode, availability ? 1 : 0, mappingRow.id).then(() => {
      onToggle();
      reloadRawTable();
    });
  }

  return <Modal isOpen={isOpen} toggle={onToggle} className="">
    <ModalHeader toggle={onToggle}>Enter new Data</ModalHeader>
    <ModalBody>
      <Form>
        <FormGroup>
          <Label for="bvCode">Code</Label>
          <input type="text" name="bvCode" id="bvCode" placeholder="Enter code..." className="form-control"
                 value={ bvCode } onChange={ handleCodeChange } />
          <div className="form-check mt-3 mr-3">
            <label className="form-check-label">
              <input
                type="checkbox"
                onChange={handleAvailabilityChange}
                checked={availability}
              />
              Availability
              <i className="input-helper"/>
            </label>
          </div>
        </FormGroup>
      </Form>
    </ModalBody>
    <ModalFooter>
      <Button color="primary" onClick={onSave}>Save</Button>{' '}
      <Button color="secondary" onClick={onToggle}>Cancel</Button>
    </ModalFooter>
  </Modal>
}

export default MappingModal;