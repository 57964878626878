import React from 'react'
import DataTable, {field} from "../../generic/DataTable";
import {Redirect} from "react-router-dom";
import {findAndCount} from "../../../service/deliveryMatrix";
import moment from "moment";

export default class Table extends React.Component {
    constructor(props) {
        super(props);
        this.onRowClick = this.onRowClick.bind(this);
        this.state = {
            selectedRowId: undefined
        };
    }

    onRowClick(row){
        this.setState({selectedRowId:row.id});
    }

    render() {
        const {selectedRowId} = this.state;
        if(selectedRowId) return <Redirect push to={`/catalog/deliveryMatrices/${selectedRowId}`}/>;

        return   <DataTable
            findAndCount={findAndCount}
            onClick={this.onRowClick}
            fields={[
                field("ID",false, "id"),
                field("Email", false, "email"),
                field("Name", false, "name"),
                field("Created At", false, "created_at", data => moment(data.created_at).format("YYYY-MM-DD HH:mm")),
                field("Updated At", false, "updated_at", data => moment(data.updated_at).format("YYYY-MM-DD HH:mm")),
                field("Input File ID", false, "input_file_id"),
                field("User ID",false, "email"),
                field("Active", false, "active", response => response.active ? "Yes" : "No")
            ]}
            name = {"deliverymatrices"}
        />
    }
}