import React, { useEffect } from 'react'
import { Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Form } from 'reactstrap';
import { showWarningToast } from "../../lib/notify";
import { createEntry, updateEntry } from "../../service/cors";
import { useForm } from "react-hook-form";
import moment from "moment";
import PropTypes from "prop-types";
import _ from "lodash";

const CEComponent = ({ isOpen, closeModalConfirmation, changeData }) => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm({});
    const isEdit = _.isObject(changeData);

    useEffect(() => {
        const { domain } = changeData || {};

        reset({ domain });
    }, [isEdit])

    const onSave = data => {
        const { domain } = data;
        const date = moment().utc(false).format('YYYY-MM-DD HH:mm');
        const dataToSave ={ domain, date };

        const request = isEdit ? updateEntry(changeData.id, dataToSave) : createEntry(dataToSave);

        request.then(closeModalConfirmation).catch(() => showWarningToast('Something went wrong.'));
    }

    return <Modal isOpen={isOpen}>
        <ModalHeader>{isEdit ? 'Edit row' : 'Create row'}</ModalHeader>
        <ModalBody>
            <Form>
                <FormGroup>
                    <Label for="domain">Name</Label>
                    <input
                        name="domain"
                        placeholder="Enter domain..."
                        className={`form-control mb-2 ${errors.domain ? "is-invalid" : ""}`}
                        ref={register({
                            required: "Domain is required"
                        })}
                    />
                    {(errors && errors.domain) && <div className="invalid-feedback">{errors.domain.message}</div>}
                </FormGroup>
            </Form>
        </ModalBody>
        <ModalFooter>
            <Button color="primary" className="mr-2" onClick={ handleSubmit(onSave) }>Save</Button>
            <Button color="secondary" onClick={ closeModalConfirmation }>Cancel</Button>
        </ModalFooter>
    </Modal>
}

CEComponent.propTypes = {
    closeModalConfirmation: PropTypes.func.isRequired,
    changeData: PropTypes.object,
    isOpen: PropTypes.bool.isRequired
}

export default CEComponent;