import React from "react";
import PrivateRoute from '../../../route/PrivateRoute';
import {SubMenuLink} from '../../../components/generic/links';
import deliveryMatrixData from './deliveryMatrixData/Index';
import Upload from './upload/Route';
import {Redirect, Switch} from "react-router-dom";

class Index extends React.Component {
    render() {
        return (
            <div>
                <ul className="nav nav-tabs">
                    <SubMenuLink to={"/input/deliveryMatrix/upload"} {...this.props}>Upload</SubMenuLink>
                </ul>
                <Switch>
                    <Redirect exact from="/input/deliveryMatrix" to="/input/deliveryMatrix/upload"/>
                    <PrivateRoute path="/input/deliveryMatrix/deliveryMatrixData" component={deliveryMatrixData}/>
                    <PrivateRoute path="/input/deliveryMatrix/upload" component={Upload}/>
                </Switch>
            </div>
        )
    }
}

export default Index;