import moment from "moment";
import {strapi} from "../lib/auth";
import {Service} from "../lib/strapi";

const entity = "deliverymethodactivations";

const service = new Service(entity);

const timeCheckFunc = async (data, id) => {
    let message = undefined;

    if((!data.methodActivationDate && data.methodActivationTime) || (data.methodActivationDate && !data.methodActivationTime)) {
        message = 'Wrong dates'
    } else if((!data.methodDeactivationDate && data.methodDeactivationTime) || (data.methodDeactivationDate && !data.methodDeactivationTime)) {
        message = 'Wrong dates'
    } else {
        if (data.methodActivationDate && data.methodActivationTime) {
            const time = data.methodActivationTime.split(':');

            data.activationDatetime = moment(data.methodActivationDate).set({
                hours: +time[0],
                minutes: +time[1]
            }).utc(false).format('YYYY-MM-DD HH:mm:ss');
        }

        if (data.methodDeactivationDate && data.methodDeactivationTime) {
            const time = data.methodDeactivationTime.split(':');

            data.deactivationDatetime = moment(data.methodDeactivationDate).set({
                hours: +time[0],
                minutes: +time[1]
            }).utc(false).format('YYYY-MM-DD HH:mm');
        }

        delete data.methodActivationDate;
        delete data.methodActivationTime;
        delete data.methodDeactivationDate;
        delete data.methodDeactivationTime;

        data.id = id;

        const {
            result,
            methodId
        } = await service.request('get', '/deliverymethodactivations/checkIfTimeIsNotOccupied', {params: data});

        if (result) {
            message = `Conflict with timer in ${methodId}`
        }

        if (data.activationDatetime && data.deactivationDatetime && !moment(data.activationDatetime).isBefore(data.deactivationDatetime)) {
            message = 'Wrong dates'
        }
    }

    return message
}

export const findAndCount = service.findAndCountServerSide;

export const createMethodActivation = async data => {
    const message = await timeCheckFunc(data);

    if(message) {
        throw new Error(message)
    }

    await strapi.createEntry("deliverymethodactivations", data);

    return data;
}

export const deleteEntry = id => strapi.deleteEntry(entity, id);
export const updateMethodActivation = async (id, data) => {
    const message = await timeCheckFunc(data, id);

    if(message) {
        throw new Error(message)
    }

    if(!data.activationDatetime) data.activationDatetime = null;
    if(!data.deactivationDatetime) data.deactivationDatetime = null;

    await strapi.updateEntry("deliverymethodactivations", id, data);

    return data;
};
export const getEntry = id => strapi.getEntry("deliverymethodactivations", id);