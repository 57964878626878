import React from "react";
import PropTypes from "prop-types";
import Card from "./Card";

class TableCard extends React.Component {
    render() {
        const {title, children} = this.props;
        return (
            <Card title={title}>
                {children}
            </Card>
        );
    }
}

TableCard.propTypes = {
    title: PropTypes.string
};

export default TableCard;