import React from "react";
import PropTypes from 'prop-types';
import DataTable, {field} from "../../../generic/DataTable";
import {findAndCount} from "../../../../service/productsLimitsData";

export default class Table extends React.Component {

    render() {
        const filters = {input_file_id: this.props.uploadId};
        return (<DataTable
            filters={filters}
            findAndCount={findAndCount}
            fields={[
                field("id", true),
                field("BVCode", true, "bvCode"),
                field("Limit", true, "limit"),
            ]}
            name={"productsLimitsData"}
        />);
    }
}

Table.propTypes = {
    uploadId: PropTypes.number,
};