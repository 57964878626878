import React from "react";
import DataTable, { field } from "../generic/DataTable";
import { findAndCount } from "../../service/deliveryMethodProducts";
import { getCodeOfDeliveryMethod, getCodeAndLabelOfDeliveryMethod } from "../../common";

const Table = ({ filters, onChange, reloadTable, referenceDataMethods, onDelete }) => {
  filters.method = getCodeOfDeliveryMethod(referenceDataMethods, filters.method);

  return (
    <DataTable
      filters={ filters }
      findAndCount={ findAndCount }
      fields={[
        field("Product Code", true, "code"),
        field("Delivery Method", true, "method", response => getCodeAndLabelOfDeliveryMethod(referenceDataMethods, response)),
        field('Availability', true, 'availability')
      ]}
      name={ "delivery_method_products" }
      onChange={ onChange }
      onDelete={ onDelete }
      reload={ reloadTable }
    />
  );
}

export default Table;