import React from "react";
import Card from "./../generic/Card";
import {Alert} from "reactstrap";
import Loader from "../generic/Loader";
import {deleteInputFile, getInputFile, updateStatus} from "../../service/inputFile";
import * as notify from "../../lib/notify";
import InputFileStep from "./InputFileStep";

//TODO remove specific realization from genericComponent - updateStatus

class Index extends React.Component {

    constructor(props) {
        super(props);
        this.loadData = this.loadData.bind(this);
        this.checkReloadTimer = this.checkReloadTimer.bind(this);
        this.checkServerStatus = this.checkServerStatus.bind(this);
        this.onConfirm = this.onConfirm.bind(this);
        this.onConfirmCancel = this.onConfirmCancel.bind(this);
        this.onWithdraw = this.onWithdraw.bind(this);
        this.onConfirmDelete = this.onConfirmDelete.bind(this);
        this.state = {
            loading: false,
            inputFile: undefined,
            deleted: false
        }
    }

    loadData() {
        const fileId = this.fileId;
        if (!fileId) return;
        this.setState({loading: true});
        return getInputFile(fileId)
            .then(inputFile => this.setState({inputFile}))
            .catch(e => {
                if(e && e.message === "Not Found") this.setState({deleted: true});
                else throw e;
            })
            .catch(notify.showDangerToast)
            .finally(() => this.setState({loading: false}));
    }

    //????????????????????????????????????????????????????
    get fileId() {
        return this.props.match.params.id;
    }

    onConfirmCancel() {
        this.setState({loading: true});
        updateStatus(this.fileId, "cancel")
            .then(this.loadData)
            .catch(notify.showDangerToast)
            .finally(() => this.setState({loading: false}));
    }

    onConfirm() {
        this.setState({loading: true});
        updateStatus(this.fileId, "loading")
            .then(inputFile => this.setState({inputFile}))
            .catch(notify.showDangerToast)
            .finally(() => this.setState({loading: false}));
    }

    onWithdraw() {
        this.setState({loading: true});
        updateStatus(this.fileId, "cancel")
            .then(this.loadData)
            .catch(notify.showDangerToast)
            .finally(() => this.setState({loading: false}));
    }

    onConfirmDelete() {
        this.setState({loading: true});
        deleteInputFile(this.fileId)
            .then(() => this.setState({deleted: true}))
            .catch(notify.showDangerToast)
            .finally(() => this.setState({loading: false}));
    }

    componentDidMount() {
        this.loadData();
        this.checkReloadTimer();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const prevId = prevProps.match.params.id;
        const currentId = this.fileId;
        if (prevId !== currentId) this.loadData();
    }
    //TODO try to rework this thing because it causes infinite calls to api
    checkReloadTimer() {
        const intervalId = setInterval(this.checkServerStatus, 3000);
        this.setState({intervalId});
    }

    checkServerStatus() {
        if(this.state.inputFile && this.state.inputFile.id) getInputFile(this.state.inputFile.id)
            .then(newInputFile => {
                if(newInputFile && newInputFile.status && this.state.inputFile && this.state.inputFile.status !== newInputFile.status) {
                    console.log("Status changed!");
                    this.loadData();
                }
            })
            .catch(e => {
                if(e && e.message === "Not Found") this.setState({deleted: true});
                else console.error(e);
            });
    }

    componentWillUnmount() {
        if(this.state.intervalId) clearInterval(this.state.intervalId);
    }

    render() {
        const {loading, inputFile} = this.state;
        if (loading) return <Card title={"Process file"}><Loader/></Card>;
        if (!inputFile) return "";

        return (
                <Card title={"Upload workflow"}>
                    <InputFileStep status={inputFile.status} failed={inputFile.failed}/>
                    {inputFile.error && <Alert color="danger">{inputFile.error}</Alert>}
                </Card>
        );
    }
}

export default Index;