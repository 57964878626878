import moment from "moment";
import {getUserFromSession, strapi} from "../lib/auth";
import {Service} from "../lib/strapi";

const entity = "deliverymatrixactivations";

const service = new Service(entity);

const timeCheckFunc = async (data, id) => {
    let message = undefined;

    if((!data.matrixActivationDate && data.matrixActivationTime) || (data.matrixActivationDate && !data.matrixActivationTime)) {
        message = 'Wrong dates'
    } else if((!data.matrixDeactivationDate && data.matrixDeactivationTime) || (data.matrixDeactivationDate && !data.matrixDeactivationTime)) {
        message = 'Wrong dates'
    } else {
        if (data.matrixActivationDate && data.matrixActivationTime) {
            const time = data.matrixActivationTime.split(':');

            data.activationDatetime = moment(data.matrixActivationDate).set({
                hours: +time[0],
                minutes: +time[1]
            }).utc(false).format('YYYY-MM-DD HH:mm:ss');
        }

        if (data.matrixDeactivationDate && data.matrixDeactivationTime) {
            const time = data.matrixDeactivationTime.split(':');

            data.deactivationDatetime = moment(data.matrixDeactivationDate).set({
                hours: +time[0],
                minutes: +time[1]
            }).utc(false).format('YYYY-MM-DD HH:mm');
        }

        delete data.matrixActivationDate;
        delete data.matrixActivationTime;
        delete data.matrixDeactivationDate;
        delete data.matrixDeactivationTime;

        data.id = id;

        const {
            result,
            matrixId
        } = await service.request('get', '/deliverymatrixactivations/checkIfTimeIsNotOccupiedByOtherMatrix', {params: data});

        if (result) {
            message = `Conflict with matrix ${matrixId}`
        }

        if (data.activationDatetime && data.deactivationDatetime && !moment(data.activationDatetime).isBefore(data.deactivationDatetime)) {
            message = 'Wrong dates'
        }
    }

    return message
}

export const findAndCount = service.findAndCountServerSide;

export const createMatrixActivation = async data => {
    const message = await timeCheckFunc(data);

    if(message) {
        throw new Error(message)
    }

    const { username } = getUserFromSession();

    data.user = username;

    await strapi.createEntry("deliverymatrixactivations", data);

    return data;
}

export const deleteEntry = id => strapi.deleteEntry(entity, id);
export const updateMatrixActivation = async (id, data) => {
    const message = await timeCheckFunc(data, id);

    if(message) {
        throw new Error(message)
    }

    if(!data.activationDatetime) data.activationDatetime = null;
    if(!data.deactivationDatetime) data.deactivationDatetime = null;

    await strapi.updateEntry("deliverymatrixactivations", id, data);

    return data;
};
export const getEntry = id => strapi.getEntry("deliverymatrixactivations", id);