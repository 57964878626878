import React from "react";

export default () => {
    return (
        <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
                <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © {new Date().getFullYear()}
                    <a href="/" target="_blank"> Philip Morris</a>. All rights reserved.</span>
            </div>
        </footer>
    );
}