import {Service} from "../lib/strapi";
import {getDateWithTimezone} from "../common";

const entity = "deliverymatrixdata";

const service = new Service(entity);

export const findAndCount = service.findAndCountServerSide;
export const deleteEntry = service.deleteEntry;
export const updateEntry = service.updateEntry;
export const createEntry = service.createEntry;

export const selectDeliveryData = async (date, postal_code, courier_company) => service.request('get', '/deliverymatrixdata/selectDeliveryData', {params: {
        date,
        index: postal_code,
        courier_company,
        now: getDateWithTimezone()
    }});
