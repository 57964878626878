import React from "react";
import TableCard from "../generic/TableCard";
import Table from "./Table";
import TextSearch from "../generic/field/TextSearch";
import { pickBy } from 'lodash';
import moment from "moment";
import { useQueryParam, StringParam, DateParam } from 'use-query-params';
import DatePicker from "../generic/field/DatePicker";
import './Index.css';
import {Button} from "reactstrap";

const Index = () => {
    const [filterOrderNumber, setFilterOrderNumber] = useQueryParam('orderNumber', StringParam);
    const [filterDeliveryMethod, setFilterDeliveryMethod] = useQueryParam('deliveryMethod', StringParam);
    const [filterPostalCode, setFilterPostalCode] = useQueryParam('postalCode', StringParam);
    const [filterOrderType, setFilterOrderType] = useQueryParam('orderType', StringParam);
    const [filterTargetShippingDate, setFilterTargetShippingDate] = useQueryParam('targetShippingDate', DateParam);
    const [filterOrderStatus, setFilterOrderStatus] = useQueryParam('orderStatus', StringParam)

    const searchOrderNumber = filterOrderNumber => {
        setFilterOrderNumber(filterOrderNumber)
    };

    const searchDeliveryMethod = filterDeliveryMethod => {
        setFilterDeliveryMethod(filterDeliveryMethod)
    };

    const searchPostalCode = filterPostalCode => {
        setFilterPostalCode(filterPostalCode)
    };

    const searchOrderType = filterOrderType => {
        setFilterOrderType(filterOrderType)
    };

    const searchTargetShippingDate = filterTargetShippingDate => {
        setFilterTargetShippingDate(filterTargetShippingDate)
    };

    const searchOrderStatus = filterOrderStatus => {
        setFilterOrderStatus(filterOrderStatus)
    };

    const dropFilters = () => {
        setFilterOrderNumber(undefined)
        setFilterDeliveryMethod(undefined)
        setFilterPostalCode(undefined)
        setFilterOrderType(undefined)
        setFilterTargetShippingDate(undefined)
        setFilterOrderStatus(undefined)
    };

    const filters = pickBy({
        number: filterOrderNumber,
        deliveryMethod: filterDeliveryMethod,
        postalCode: filterPostalCode,
        type: filterOrderType,
        targetShippingDate: filterTargetShippingDate ? moment(filterTargetShippingDate).format('YYYY-MM-DD') : null,
        status: filterOrderStatus
    })

    return (
        <TableCard>
            <div style={{minHeight: "400px"}}>
                <div className={"row justify-content-start ml-1 mb-3 g-2"}>
                    <TextSearch
                        type="orderNumber"
                        name="orderNumber"
                        onSearch={ searchOrderNumber }
                        className="form-control col-sm-2 mr-2 mt-2"
                        value={ filterOrderNumber }
                        placeholder={"Enter order number..."}
                    />
                    <TextSearch
                        type="deliveryMethod"
                        name="deliveryMethod"
                        onSearch={ searchDeliveryMethod }
                        className="form-control col-sm-2 mr-2 mt-2"
                        value={ filterDeliveryMethod }
                        placeholder={"Enter delivery method..."}
                    />
                    <TextSearch
                        type="postalCode"
                        name="postalCode"
                        onSearch={ searchPostalCode }
                        className="form-control col-sm-2 mr-2 mt-2"
                        value={ filterPostalCode }
                        placeholder={"Enter postal code..."}
                    />
                    <TextSearch
                        type="orderType"
                        name="orderType"
                        onSearch={ searchOrderType }
                        className="form-control col-sm-2 mr-2 mt-2"
                        value={ filterOrderType }
                        placeholder={"Enter order type..."}
                    />
                  <div className="mr-2 mt-2">
                    <DatePicker
                      classNames="form-control"
                      placeholder="Choose target shipping date..."
                      selectedDay={ filterTargetShippingDate } onDateChange={ date => searchTargetShippingDate(date) } />
                  </div>
                    <TextSearch
                      type="orderStatus"
                      name="orderStatus"
                      onSearch={ searchOrderStatus }
                      className="form-control col-sm-2 mr-2 mt-2"
                      value={ filterOrderStatus }
                      placeholder={"Enter order status..."}
                    />
                    <Button className="mr-sm-2 mt-2" color="default" onClick={ dropFilters }>
                        <i className="fa fa-fw fa-times"/>
                        Cancel
                    </Button>
                </div>
                <Table filters={ filters } />
            </div>
        </TableCard>
    )
}

export default Index;