import React from 'react'
import {Button} from "reactstrap";
import './Index.css';
import DatePicker from "../../generic/field/DatePicker";
import {ReactDadata} from "react-dadata";
import {selectDeliveryData} from "../../../service/deliveryMatrixData";
import {findAndCount} from "../../../service/referenceData";

export default class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            date: undefined,
            postal_code: undefined,
            kladr_id: undefined,
            data: undefined,
            delivery_method: undefined,
            methods: undefined
        };
    }

    componentWillMount() {
        this.loadData();
    }

    async loadData() {
        this.setState({
          methods: await findAndCount(0, 1000)
        })
    }

    render() {
        const { delivery_method, postal_code, kladr_id, minDelivery, maxDelivery, proposedDateDelivery, data, showDeliveryMethod, showDeliveryDate, methods } = this.state;
        return (
            <div className="card">
                <div className="card-body">
                    <form className="forms-sample">
                        <div className="form-group">
                                <div className="form-group">
                                    <label htmlFor="DeliveryAddress">Адрес доставки&nbsp;</label>
                                    <ReactDadata token="564ccc10de37e3345d791584f63d3dd63bb58390" onChange={async suggestion => {
                                        const { data } = await selectDeliveryData(undefined, suggestion.data.postal_code)
                                        this.setState({
                                            postal_code: suggestion.data.postal_code,
                                            kladr_id: suggestion.data.house_kladr_id,
                                            data,
                                            showDeliveryMethod: true,
                                            showDeliveryDate: false
                                        });
                                    }}/>
                                    {postal_code ? <p className="mt-2">Индекс: {postal_code}</p> : <div><label className="mt-2" htmlFor="Index">&nbsp;или индекс&nbsp;</label>
                                        <ReactDadata token="564ccc10de37e3345d791584f63d3dd63bb58390"
                                        onChange={async suggestion => {
                                        const { data } = await selectDeliveryData(undefined, suggestion.data.postal_code);
                                        this.setState({
                                        postal_code: suggestion.data.postal_code,
                                        kladr_id: suggestion.data.kladr_id,
                                        data,
                                        showDeliveryMethod: true,
                                        showDeliveryDate: false
                                    });
                                        }}/></div>}
                                    {showDeliveryMethod &&
                                    <div>
                                        <label htmlFor="Kladr">Кладр&nbsp;</label>
                                        <input
                                            id="Kladr"
                                            className="form-control mr-4 mb-2"
                                            onChange={() => {}}
                                            name="Kladr"
                                            value={kladr_id ? kladr_id : undefined}
                                        />
                                        <label>Способы доставки:&nbsp;</label>
                                        {
                                            data.length ? data.map((item, index) => {
                                                return <Button key={"button" + index} className="btn btn-sm btn-outline-info btn-block" onClick={async () => {
                                                    this.setState({
                                                        showDeliveryDate: true,
                                                        delivery_method: item.courier_company
                                                    })
                                                }}>{methods.data.find(method => method.code === item.courier_company) ? methods.data.find(method => method.code === item.courier_company).label : item.courier_company}</Button>
                                            }) : <p>Нельзя доставить на этот адрес</p>

                                        }
                                    </div>
                                    }
                                    {showDeliveryDate &&
                                    <div>
                                        <label className="block mt-2">&nbsp;Выберите дату доставки&nbsp;</label>
                                        <DatePicker placeholder="Choose date ..." selectedDay={this.state.date}
                                                    onDateChange={async date => {
                                                        this.setState({date})
                                                        const { min, max, proposedDate } = await selectDeliveryData(date, postal_code, delivery_method);
                                                        this.setState({
                                                            minDelivery: min,
                                                            maxDelivery: max,
                                                            proposedDateDelivery: proposedDate
                                                        });
                                                    }}/>
                                        <label className="block mt-2" htmlFor="SystemDateDelivery">&nbsp;Дата доставки
                                            для
                                            системы&nbsp;</label>
                                        <input
                                            id="SystemDateDelivery"
                                            className="form-control mb-2"
                                            name="SystemDateDelivery"
                                            onChange={() => {}}
                                            value={proposedDateDelivery ? proposedDateDelivery : ''}
                                        />
                                        <label htmlFor="MinDateDelivery">Минимальная дата доставки&nbsp;</label>
                                        <input
                                            id="MinDateDelivery"
                                            className="form-control mr-4 mb-2"
                                            name="MinDateDelivery"
                                            onChange={() => {}}
                                            value={minDelivery ? minDelivery : ''}
                                        />
                                        <label htmlFor="MaxDateDelivery">&nbsp;Максимальная дата
                                            доставки&nbsp;</label>
                                        <input
                                            id="MaxDateDelivery"
                                            className="form-control"
                                            name="MaxDateDelivery"
                                            onChange={() => {}}
                                            value={maxDelivery ? maxDelivery : ''}
                                        />
                                    </div>
                                    }
                                </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
};