import React, { useEffect, useState } from "react";
import moment from "moment";
import { pickBy } from 'lodash';
import { Button } from "reactstrap";
import { useQueryParam, StringParam, DateParam } from 'use-query-params';

import Table from "./Table";
import CEComponent from "./CEComponent";
import { deleteEntry } from "../../service/cors";

import DatePicker from "../generic/field/DatePicker";
import DeletePopup from "../generic/DeletePopup";
import TableCard from "../generic/TableCard";
import TextSearch from "../generic/field/TextSearch";

export default function Index() {
    const [filterCORSName, setFilterCORSName] = useQueryParam('domain', StringParam);
    const [filterCORSDate, setFilterCORSDate] = useQueryParam('date', DateParam);

    const [openCreateEditModalConfirmation, setOpenCreateEditModalConfirmation] = useState(undefined);
    const [openDeleteModalConfirmation, setOpenDeleteModalConfirmation] = useState(undefined);

    const [deleteRowId, setDeleteRowId] = useState(undefined);
    const [data, setData] = useState(undefined);

    const [reload, setReload] = useState(false);

    useEffect(() => {
        const value = !!deleteRowId;
        setOpenDeleteModalConfirmation(value);
    }, [deleteRowId])

    const onCreate = () => setOpenCreateEditModalConfirmation(true);

    const searchCORSName = corsName => setFilterCORSName(corsName);

    const searchCORSDate = corsDate => setFilterCORSDate(corsDate);

    const dropFilters = () => [setFilterCORSName, setFilterCORSDate].forEach(f => f(() => undefined));

    const closeModalConfirmation = () => {
        setOpenCreateEditModalConfirmation(false);
        setOpenDeleteModalConfirmation(false);

        setFilterCORSDate(undefined);
        setFilterCORSName(undefined);
        setData(undefined);

        setReload(!reload);
    }

    const onChange = changeData => {
        setOpenCreateEditModalConfirmation(true);
        setData(changeData);
    }

    const onDelete = id => deleteEntry(id).then(() => setReload(!reload));

    const onDeletePopup = () => onDelete(deleteRowId).then(closeModalConfirmation);

    const onDeleteTable = response => setDeleteRowId(response.id)

    const onDateChange = date => searchCORSDate(date);

    const filters = pickBy({
        domain_contains: filterCORSName,
        date_contains: filterCORSDate ? moment(filterCORSDate).format('YYYY-MM-DD') : undefined
    })

    return (
        <TableCard title={"CORS"}>
            <div className={"row justify-content-start ml-1 mb-3"}>
                <TextSearch
                    name="corsName"
                    onSearch={ searchCORSName }
                    className="form-control col-sm-3 mr-2"
                    value={ filterCORSName }
                    placeholder={"Enter CORS..."}
                />
                <DatePicker
                    classNames="form-control"
                    placeholder="Choose CORS date..."
                    selectedDay={ filterCORSDate } onDateChange={ onDateChange }
                />
                <Button className="mr-sm-2 ml-2" color="primary" onClick={ onCreate }>
                    <i className="fa fa-plus"/>
                    Add
                </Button>
                <Button className="mr-sm-2" color="default" onClick={ dropFilters }>
                    <i className="fa fa-fw fa-times"/>
                    Cancel
                </Button>
            </div>
            <CEComponent
                isOpen={ openCreateEditModalConfirmation }
                changeData={ data }
                closeModalConfirmation={ closeModalConfirmation }
            />
            <DeletePopup onDelete={ onDeletePopup }
                         onCancel={ closeModalConfirmation }
                         isOpen={ openDeleteModalConfirmation }>
                <p>Are you sure you want to delete this CORS domain?</p>
            </DeletePopup>
            <Table filters={ filters }
                   onChange={ onChange }
                   reload={ reload }
                   onDelete={ onDeleteTable }
            />
        </TableCard>
    )
}