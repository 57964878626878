import {Button, FormGroup, Label} from "reactstrap";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {check, update} from "../../service/newuser";
import * as notify from "../../lib/notify";
import './Index.css';
import {validatePasswordDifficulty} from "../../lib/validation";
import {setCookie} from "../../lib/session";
import {BAD_PASSWORD_ERROR_MESSAGE} from "../../common/constants";

export default function Index(props) {
    const { register, handleSubmit, formState: { errors } } = useForm({});
    const [isError, setIsError] = useState(true);
    const [errorMessage, setErrorMessage] = useState(undefined)

    const onSave = async data => {
        const { password, confirmPassword } = data;
        const { code } = props.match.params;

        if(password !== "" && !validatePasswordDifficulty(password)) {
            notify.showDangerToast(BAD_PASSWORD_ERROR_MESSAGE);
            return;
        }

        if(password !== confirmPassword) {
            notify.showDangerToast("Passwords mismatch");
            return;
        }

        update({code, password})
            .then((res) => {
                if(!res.hasError) {
                    setCookie("jwt", res.jwt, {secure: true, sameSite: 'strict'});
                    window.localStorage.setItem("user", JSON.stringify({
                        username: res.user.username,
                        email: res.user.email,
                        id: res.user.id,
                        role: res.user.role
                    }));
                    notify.showSuccessToast("User saved");
                    props.history.push("/");
                    window.location.reload();
                }
            }, (error) => notify.showDangerToast(error));
    };

    const checkPasswordJwt = async () => {
        const { code } = props.match.params;

        if(code) {
            let response;
            try {
                response = await check({ code })
            } catch {
                return setErrorMessage('Something went wrong')
            }

            response.hasError ? setErrorMessage(response.message) : setIsError(false)
        }
    }
    useEffect(() => {
        checkPasswordJwt();
    }, [])

    return (
        <>
            {
                !isError ? <form onSubmit={handleSubmit(onSave)} className="d-flex justify-content-center">
                    <FormGroup className="password-form">
                        <Label for="password">Password</Label>
                        <input
                            name="password"
                            placeholder="Enter password"
                            className={`form-control mb-2 ${errors.password ? "is-invalid" : ""}`}
                            ref={register({
                                required: "Password is required"
                            })}
                            type="password"
                        />
                        {(errors && errors.password) && <div className="invalid-feedback">{errors.password.message}</div>}
                        <Label for="confirmPassword">Confirm password</Label>
                        <input
                            name="confirmPassword"
                            placeholder="Confirm password"
                            className={`form-control ${errors.confirmPassword ? "is-invalid" : ""}`}
                            ref={register({
                                required: "Password confirmation is required"
                            })}
                            type="password"
                        />
                        {(errors && errors.confirmPassword) && <div className="invalid-feedback">{errors.confirmPassword.message}</div>}
                        <Button color="primary" className="mr-2 d-block mt-4" onClick={() => {
                            handleSubmit(onSave)()
                        }}>Save</Button>
                    </FormGroup>
                </form>
                    :
                    <h5 className={'d-flex justify-content-center mt-2'}>{errorMessage}</h5>
            }
        </>
    )
}