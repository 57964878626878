import React from "react";
import DataTable, {field} from "../generic/DataTable";
import {findAndCount} from "../../service/journal";
import PropTypes from "prop-types";
import {datetime} from "../../lib/format";
import {pickBy} from 'lodash';

const Table = (props) => {
    const {user, url, entity} = props;
    const filters = pickBy({entity_contains: entity, user_contains: user, url_contains: url});

    return (<DataTable
        filters={filters}
        findAndCount={findAndCount}
        reload={props.reload}
        name="journal"
        fields={[
            field("User", true, "user"),
            field("IP", true, "IP"),
            field("Action", true, "action"),
            field("URL", true, "url"),
            field("Entity", true, "entity"),
            field("Status", true, "status"),
            field("Date", true, "created_at", res => datetime(res.created_at)),
        ]}>
    </DataTable>)
};

Table.propTypes = {
    user: PropTypes.string,
    url: PropTypes.string,
    entity: PropTypes.string
};

export default Table;