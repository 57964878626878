import * as notify from "../../../../../../lib/notify";
import {createEntry, updateEntry} from "../../../../../../service/deliveryMatrixData";

const submitValidation = (data) => {
    if (+data.min > +data.max) throw new Error('Min cannot be greater than Max')
}

const normalizeData = (data) => {
    const response = {};

    for (const field in data) {
        if (field.includes('client_cut_off') && data[field][2] !== ':') {
            response[field] = 0 + data[field]
        } else {
            response[field] = data[field]
        }
    }

    return response
}

const useSubmit = ({closeModalConfirmation, type, deliveryMatrixId, id}) => {
    return (data) => {
        try {
            submitValidation(data)
        } catch (e) {
            return notify.showDangerToast(e)
        }

        const payload = normalizeData(data)

        if (type === 'create') createEntry({...payload, delivery_matrix_id: deliveryMatrixId})
            .then(() => closeModalConfirmation()()).catch(error => notify.showDangerToast(error.message))

        if (type === 'edit') updateEntry(id, payload)
            .then(() => closeModalConfirmation()()).catch(error => notify.showDangerToast(error.message))
    }
}

export default useSubmit;