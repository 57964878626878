import React, {useEffect, useState} from 'react'
import moment from 'moment';
import {Button, Form, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import { updateMatrixActivation, deleteEntry, getEntry } from "../../../../../service/deliverymatrixactivations";
import DatepickerWithTime from "../../../../generic/field/DatepickerWithTime";
import "./EDComponent.css";
import PropTypes from "prop-types";
import * as notify from "../../../../../lib/notify";
import RegularButton from "../../../../generic/RegularButton";

const EDComponent = ({ isModalOpen, modalType, closeModalConfirmation, rowId, setReload, matrixId }) => {
    const [matrixActivationDate, setMatrixActivationDate] = useState(undefined);
    const [matrixDeactivationDate, setMatrixDeactivationDate] = useState(undefined);
    const [matrixActivationTime, setMatrixActivationTime] = useState(undefined);
    const [matrixDeactivationTime, setMatrixDeactivationTime] = useState(undefined);
    const onUpdate = () => {
        if((matrixActivationDate && matrixActivationTime) || (matrixDeactivationDate && matrixDeactivationTime)) {
            updateMatrixActivation(rowId, {
                matrixActivationDate,
                matrixActivationTime,
                matrixDeactivationDate,
                matrixDeactivationTime,
                matrixId
            }).then(setReload).catch(error => notify.showDangerToast(error.message));
            closeModalConfirmation();
        }
    };
    const onDelete = () => {
        deleteEntry(rowId).then(setReload);
        closeModalConfirmation();
    }

    useEffect(() => {
        loadData();

        return () => {
            setMatrixActivationDate(undefined);
            setMatrixActivationTime(undefined);
            setMatrixDeactivationDate(undefined);
            setMatrixDeactivationTime(undefined);
        }
    }, [rowId])

    const loadData = async () => {
        if(rowId) {
            const {activationDatetime, deactivationDatetime} = await getEntry(rowId);

            if(activationDatetime) {
                setMatrixActivationDate(moment(activationDatetime).format('YYYY.MM.DD'));
                setMatrixActivationTime(moment(activationDatetime).format('HH:mm'));
            }
            if(deactivationDatetime) {
                setMatrixDeactivationDate(moment(deactivationDatetime).format('YYYY.MM.DD'));
                setMatrixDeactivationTime(moment(deactivationDatetime).format('HH:mm'));
            }
        }
    }

    return <Modal isOpen={isModalOpen}>
        <ModalHeader>{modalType === 'update' ? 'Update activation' : 'Delete activation'}</ModalHeader>
        <ModalBody>
            <Form>
                <FormGroup>
                    <div className={"form-inline pl-2 ml-1 mb-3 mt-3"}>
                        {
                            (modalType === 'update') ?
                                <>
                                    <DatepickerWithTime
                                        datepickerDaySelected={matrixActivationDate}
                                        datepickerPlaceholder={'Choose start date'}
                                        datepickerSearchFunction={value => setMatrixActivationDate(value)}
                                        timepickerPlaceholder={'Start time'}
                                        timepickerSearchFunction={value => {
                                            if(value) {
                                                setMatrixActivationTime(value.format('HH:mm'))
                                            } else {
                                                setMatrixActivationDate(undefined);
                                                setMatrixActivationTime(undefined);
                                            }
                                        }}
                                        timepickerValue={matrixActivationTime}
                                        className={'startDate'}
                                    />
                                    <label className="ml-3 mr-3 col-form-label-sm"> — </label>
                                    <DatepickerWithTime
                                        datepickerDaySelected={matrixDeactivationDate}
                                        datepickerPlaceholder={'Choose end date'}
                                        datepickerSearchFunction={value => setMatrixDeactivationDate(value)}
                                        timepickerPlaceholder={'End time'}
                                        timepickerSearchFunction={value => {
                                            if(value) {
                                                setMatrixDeactivationTime(value.format('HH:mm'))
                                            } else {
                                                setMatrixDeactivationDate(undefined);
                                                setMatrixDeactivationTime(undefined);
                                            }
                                        }}
                                        timepickerValue={matrixDeactivationTime}
                                        className={'endDate'}
                                    />
                                </>
                                :
                                <>
                                    <p>Are you sure you want to delete this activation?</p>
                                </>
                        }
                    </div>
                </FormGroup>
            </Form>
        </ModalBody>
        <ModalFooter>
            {
                modalType === 'update' ? <RegularButton color="primary" onClick={ () => onUpdate() }>Update</RegularButton>
                    :
                    <RegularButton color="danger" onClick={ () => onDelete() }>Delete</RegularButton>
            }
            <Button color="secondary" onClick={ () => closeModalConfirmation() }>Cancel</Button>
        </ModalFooter>
    </Modal>
}

EDComponent.propTypes = {
    isModalOpen: PropTypes.bool,
    modalType: PropTypes.string,
    closeModalConfirmation: PropTypes.func,
    rowId: PropTypes.number,
    setReload: PropTypes.func,
    matrixId: PropTypes.number
}

export default EDComponent;