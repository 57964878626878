import {Service} from "../lib/strapi";
const service = new Service("referencedata");
export const findAndCount = service.findAndCountServerSide;
export const deleteEntry = service.deleteEntry;
export const createEntry = service.createEntry;
export const updateEntry = service.updateEntry;
export const insertData = async (kind, code, label) => service.request('post', '/referencedata/insert', {params: {
        kind,
        code,
        label
    }});
export default service;
