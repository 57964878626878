import {Service} from '../lib/strapi';
import {validateUploadInputFile} from "../lib/validation";
import {strapi} from "../lib/auth";
import moment from "moment";


const entity = "inputfiles";

const service = new Service(entity);

export const findAndCount = service.findAndCount;

const Entity = {
    delivery_matrix: "delivery_matrix",
    weekends: "weekends",
    delivery_method_products: "delivery_method_products",
    alternative_products: "alternative_products",
    stocks: "stocks",
    products_limits: "products_limits",
};

export const Status = {
    upload: "upload",
    process: "process",
    fail: "fail",
    mapping: "mapping",
    loading: "loading",
    success: "success"
};

export const getInputFile = async id => strapi.getEntry(entity, id);

export const uploadDeliveryMatrix = async (file) => uploadFile(file, Entity.delivery_matrix);

export const uploadWeekends = async (file) => uploadFile(file, Entity.weekends);

export const uploadDeliveryMethodProducts = async (file) => uploadFile(file, Entity.delivery_method_products);

export const uploadStocks = async (file) => uploadFile(file, Entity.stocks);

export const uploadAlternativeProducts = async (file) => uploadFile(file, Entity.alternative_products);

export const uploadProductsLimits = async (file) => uploadFile(file, Entity.products_limits);

const uploadFile = async (file, fileEntity) => {
    const validationError = validateUploadInputFile(file);
    if (validationError) return Promise.reject(validationError);

    const entry = {
        entity: fileEntity,
        status: Status.upload,
        fileName: file.name,
        Name: file.Name,
        Email: file.Email,
        deliveryCode: file.deliveryCode,
        warehouseCode: file.warehouseCode,
        warehouseName: file.warehouseName,
        stockCreationDate: moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
    };

    return strapi.createEntry(entity, entry).then(x => {
        const form = new FormData();
        form.append('files', file, file.name);
        form.append('refId', x.id);
        form.append('ref', "inputfile");
        form.append('field', 'file');
        return strapi.upload(form).then(file => updateStatus(x.id, Status.process));
    })
};

//__________________________________________________________________________________________

/**
 *
 * @param {string|number} id
 * @param {('process'|'fail'|'check'|'cancel')} status
 * @param {object} [properties={}]
 * @returns {Promise<any>}
 */
export const updateStatus = async (id, status, properties = {}) =>
    strapi.request("put", `/${entity}/status/${id}`, {
        data: {status: status, ...properties}
    });



export const deleteInputFile = async id => strapi.deleteEntry(entity, id);