import React from "react";
import Card from "../../../generic/Card";
import FileInput from "../../../generic/field/FileInput";
import {Col, Row} from "reactstrap";
import {uploadDeliveryMatrix} from '../../../../service/inputFile';
import * as notify from "../../../../lib/notify";
import InputFileStep from "../../InputFileStep";
import {Redirect} from "react-router-dom";
import {withRouter} from 'react-router-dom';
import LoaderOverlay from "../../../generic/LoaderOverlay";
import {getUserFromSession} from "../../../../lib/auth";
import moment from "moment";
import RegularButton from "../../../generic/RegularButton";

class Index extends React.Component {

    constructor(props) {
        super(props);
        this.handleClear = this.handleClear.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.state = {
            file: undefined,
            redirectToItemId: undefined,
            loading: false
        }
    }

    handleClear() {
        this.setState({
            file: undefined
        })
    }

    handleInputChange(event) {
        event.preventDefault();
        let target = event.target;
        let name = target.name;
        this.setState({[name]: target.value});
    }

    handleSubmit() {
        const {file} = this.state;
        file.Name = this.state.Name || "Delivery Matrix " + moment(new Date()).format("YYYY-MM-DD HH:mm");
        file.Email = this.state.Email || getUserFromSession().email;

        this.setState({loading: true});
        uploadDeliveryMatrix(file).then(
            entry => {
                if(entry.error === 'Wrong format') {
                    notify.showDangerToast('Wrong format. Please check format of uploaded file.')
                    return;
                }
                notify.showSuccessToast("File uploaded");
                this.setState({redirectToItemId: entry.id});
            })
            .catch(notify.showDangerToast)
            .finally(() => this.setState({loading: false}));
    }

    render() {

        const {file, redirectToItemId, loading} = this.state;

        if(redirectToItemId) return <Redirect push to={"/input/deliveryMatrix/upload/" + redirectToItemId}/>

        return (
            <Card title={"Upload new file"}>
                <InputFileStep status="upload"/>
                <LoaderOverlay isVisible={loading}>
                    <Row className="mt-2">
                        <Col xs={12} md={6}>
                            <div className="form-group">
                                <label>File:</label>
                                <FileInput file={file} onSelect={file => this.setState({file})}/>
                            </div>
                        </Col>
                        <Col xs={12} md={6} className="mt-2">
                        <label htmlFor="Name">Name</label>
                        <input
                            type="text"
                            className="form-control"
                            name="Name"
                            id="Name"
                            placeholder="Name"
                            value={this.state.Name || "Delivery Matrix " + moment(new Date()).format("YYYY-MM-DD HH:mm")}
                            onChange={this.handleInputChange}
                            autoComplete="false"
                        />
                        <label htmlFor="Email" className="mt-2">Email</label>
                        <input
                            type="text"
                            className="form-control"
                            name="Email"
                            id="Email"
                            placeholder="Email"
                            value={this.state.Email || getUserFromSession().email}
                            onChange={this.handleInputChange}
                            autoComplete="false"
                        />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <RegularButton notComponent type="button" className="btn btn-primary mr-sm-2" onClick={this.handleSubmit}>Submit</RegularButton>
                            <RegularButton notComponent type="button" className="btn btn-outline-secondary" onClick={this.handleClear}>Clear</RegularButton>
                        </Col>
                    </Row>
                </LoaderOverlay>

            </Card>
        );
    }
}

export default withRouter(Index);