import React from "react";
import DataTable, { field } from "../generic/DataTable";
import { findAndCount } from "../../service/warehouses";
import PropTypes from 'prop-types'

const Table = ({ filters, reload, onChange, onDelete }) => {
    return (
        <DataTable
            filters={ filters }
            findAndCount={ findAndCount }
            fields={[
                field("Code", true, "code"),
                field("Name", true, "name"),
            ]}
            name={ "warehouses" }
            reload={ reload }
            onChange={ onChange }
            onDelete={ onDelete }
        />
    );
}

Table.propTypes = {
    filters: PropTypes.object,
    onChange: PropTypes.func,
    onDelete: PropTypes.func,
    reload: PropTypes.bool
}

export default Table;