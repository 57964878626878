import React from "react";

import HeaderUser from "./HeaderUser";
import PropTypes from 'prop-types';

class Header extends React.Component {
    render() {
        const {isAuthenticated} = this.props;
        return (
            <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
                <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
                    <a className="navbar-brand brand-logo" href="/"><img alt="pm-logo" src="/images/pm-logo.png"/></a>
                    <a className="navbar-brand brand-logo-mini" href="/"><img alt="pm-logo-mini"
                                                                              src="/images/logo.png"/></a>
                </div>
                {
                    isAuthenticated ?
                        <HeaderUser {...this.props}/> :
                        <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end"/>
                }
            </nav>
        );
    }
}

Header.propTypes = {
    onBurgerClick: PropTypes.func.isRequired
};

export default Header;