import React, {useState} from "react";
import TableCard from "../generic/TableCard";
import Table from "./Table";
import TextSearch from "../generic/field/TextSearch";
import { pickBy } from 'lodash';
import { useQueryParam, StringParam } from 'use-query-params';
import CEComponent from "./CEComponent";
import { deleteEntry } from "../../service/warehouses";
import RegularButton from "../generic/RegularButton";
import ModalConfirmation from "../generic/ModalConfirmation";
import {showWarningToast} from "../../lib/notify";
import {Button} from "reactstrap";

export default function Index() {
    const [filterWarehouseName, setFilterWarehouseName] = useQueryParam('name', StringParam);
    const [filterWarehouseCode, setFilterWarehouseCode] = useQueryParam('code', StringParam);
    const [openCreateModalConfirmation, setOpenCreateModalConfirmation] = useState(undefined);
    const [openEditModalConfirmation, setOpenEditModalConfirmation] = useState(undefined);
    const [openDeleteModalConfirmation, setOpenDeleteModalConfirmation] = useState(undefined);
    const [id, setId] = useState(undefined);
    const [changeData, setChangeData] = useState({});
    const [reload, setReload] = useState(false);

    const onCreate = () => {
        setOpenCreateModalConfirmation(true);
    }

    const searchWarehouseName = filterWarehouseName => {
        setFilterWarehouseName(filterWarehouseName)
    };

    const searchWarehouseCode = filterWarehouseCode => {
        setFilterWarehouseCode(filterWarehouseCode)
    };

    const dropFilters = () => {
        [setFilterWarehouseName, setFilterWarehouseCode].forEach(f => f(() => undefined));
    };

    const closeModalConfirmation = () => {
        setReload(!reload);
        setOpenEditModalConfirmation(false);
        setOpenCreateModalConfirmation(false);
        setOpenDeleteModalConfirmation(false);
    }

    const onChange = changeData => {
        setOpenEditModalConfirmation(true);
        setChangeData(changeData);
    }

    const onDelete = async ({ id }) => {
        setOpenDeleteModalConfirmation(true);
        setId(id);
    }

    const filters = pickBy({
        code: filterWarehouseCode,
        name: filterWarehouseName
    })

    return (
        <TableCard title={"Warehouses"}>
            <div className={"row justify-content-start ml-1 mb-3"}>
                <TextSearch
                    name="warehouseCode"
                    onSearch={ searchWarehouseCode }
                    className="form-control col-sm-3 mr-2"
                    value={ filterWarehouseCode }
                    placeholder={"Enter warehouse code..."}
                />
                <TextSearch
                    name="warehouseName"
                    onSearch={ searchWarehouseName }
                    className="form-control col-sm-3 mr-2"
                    value={ filterWarehouseName }
                    placeholder={"Enter warehouse name..."}
                />
                <RegularButton className="mr-sm-2" color="primary" onClick={ onCreate }>
                    <i className="fa fa-plus"/>
                    Add
                </RegularButton>
                <Button className="mr-sm-2" color="default" onClick={ dropFilters }>
                    <i className="fa fa-fw fa-times"/>
                    Cancel
                </Button>
            </div>
            <ModalConfirmation text="Are you sure you want to delete it?" isOpen={openDeleteModalConfirmation} onConfirm={() => {
                deleteEntry(id).then((res) => res.error ? showWarningToast(res.error) :  closeModalConfirmation()).catch((res) => showWarningToast(res.message));
            }} onClose={closeModalConfirmation} />
            <CEComponent type="create" isOpen={ openCreateModalConfirmation } closeModalConfirmation={ closeModalConfirmation } />
            <CEComponent type="edit" isOpen={ openEditModalConfirmation } changeData={ changeData } closeModalConfirmation={ closeModalConfirmation } />
            <Table filters={ filters } onChange={ onChange } reload={ reload } onDelete={ onDelete } />
        </TableCard>
    )
}