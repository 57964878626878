import React from 'react'
import Button from "reactstrap/es/Button";
import {Redirect} from "react-router-dom";
import Loader from "../../generic/Loader";
import referenceDataService from "../../../service/referenceData";
import {setActive, getMatrix} from "../../../service/deliveryMatrix";
import { readMatrixShortInfo } from "../../../service/deliverymatrices";
import { createMatrixActivation } from "../../../service/deliverymatrixactivations"
import moment from "moment";
import {ReactDadata} from "react-dadata";
import TextSearch from "../../generic/field/TextSearch";
import "./Edit.css"
import ReferenceSelect from "../../generic/ReferenceSelect";
import DatepickerWithTime from "../../generic/field/DatepickerWithTime";
import * as notify from "../../../lib/notify"
import MatrixActivationsPanel from "./components/matrixActivations/Index"
import {getUserFromSession} from "../../../lib/auth";
import {showWarningToast} from "../../../lib/notify";
import RegularButton from "../../generic/RegularButton";
import MatrixItemsTable from "./components/matrixItems/Table";

export default class Edit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: false,
            loading: false,
            reload: false,
            courier_companies: undefined,
            chosen_courier_company: undefined,
            chosen_payment_method: undefined,
            matrixActivationDate: undefined,
            matrixActivationTime: undefined,
            matrixDeactivationDate: undefined,
            matrixDeactivationTime: undefined
        };
        this.loadData = this.loadData.bind(this);
        this.createButtons = this.createButtons.bind(this)
    };

    componentDidMount() {
        const { id } = this.props.match.params;
        if(id) {
            this.loadData(id);
        }
    }

    componentDidUpdate() {
        const { id } = this.props.match.params;
        const { prevID } = this.state;
        if(prevID !== id) {
            this.loadData(id);
        }
    }

    async loadData(id) {
        const shortInfo = await readMatrixShortInfo(id);
        const refData = await referenceDataService.findAndCount(0, 10000, 'id', 'desc')

        const refDataDelivery = refData.data.filter(it => it.kind === 'deliveryMethod');
        const refDataPayment = refData.data.filter(it => it.kind === 'paymentMethod');

        this.setState({
            courier_companies: refDataDelivery,
            payment_methods: refDataPayment
        });
        return await getMatrix(id).then(({ input_file_id, active, bitrix_status, bitrix_status_date, activationDatetime, deactivationDatetime }) => {
            this.setState({
                id,
                input_file_id,
                prevID: id,
                Name: shortInfo.name,
                Email: shortInfo.email,
                active,
                bitrix_status,
                bitrix_status_date,
                matrixActivationDate: activationDatetime ? new Date(activationDatetime) : undefined,
                matrixActivationTime: activationDatetime ? moment(activationDatetime).format('HH:mm') : undefined,
                matrixDeactivationDate: deactivationDatetime ? new Date(deactivationDatetime) : undefined,
                matrixDeactivationTime: deactivationDatetime ? moment(deactivationDatetime).format('HH:mm') : undefined
            })
        });
    }

    createButtons() {
        const { courier_companies, payment_methods, index } = this.state;

        return <div>
            <div className="mb-2 form-inline">
                <div className="dadata mr-2">
                    <ReactDadata placeholder="   Enter address..." token="564ccc10de37e3345d791584f63d3dd63bb58390" onChange={async suggestion => {
                        this.setState({
                            index: suggestion.data.postal_code
                        });
                    }}/>
                </div>
                <ReferenceSelect id="deliveryMethods" reference_type="reference_data" kind="deliveryMethod"
                     findAndCount={referenceDataService.findAndCount} placeholder="Choose delivery method"
                     options={courier_companies}
                     async={false}
                     onChange={choice => this.setState({chosen_courier_company : choice})}/>
                <ReferenceSelect id="paymentMethods" reference_type="reference_data" kind="paymentMethod"
                    findAndCount={referenceDataService.findAndCount} placeholder="Choose payment method"
                     options={payment_methods}
                     async={false}
                     onChange={choice => {
                    if(choice === "on_the_spot_pay_boxberry") document.getElementById('deliveryMethods').selectedIndex = 10;
                    this.setState({[choice] : 'Да'})
                }}/>
                <TextSearch
                    type="text"
                    name="Code"
                    onSearch={index => {
                        this.setState({
                            index
                        })
                    }}
                    value={index}
                    className="form-control text-search mr-2 postal-index"
                    placeholder={"Enter postal index..."}
                />
                <div className="form-check mt-3">
                    <label className="form-check-label">
                        <input
                            id="weekends"
                            type="checkbox"
                            onChange={() => {
                                const {holiday, saturday, sunday} = this.state;
                                if (!holiday && !saturday && !sunday) {
                                    this.setState({holiday: 'Да', saturday: 'Да', sunday: 'Да'})
                                } else {
                                    this.setState({holiday: undefined, saturday: undefined, sunday: undefined})
                                }
                            }
                            }
                        />
                        Weekends delivery
                        <i className="input-helper"/>
                    </label>
                </div>
            </div>
            <div className="form-inline mb-2">
                {!this.state.active ? <RegularButton
                    color="primary" onClick={() => {
                    const { username: user } = getUserFromSession()
                    setActive(+this.props.match.params.id, user).then((res) => res.data !== false ? this.setState({active: true}) : showWarningToast('This delivery matrix has nonexistent time slot'));
                }}
                >
                    Activate
                </RegularButton> : <RegularButton
                    color="inactive"
                    className="disabled"
                >
                    Already active
                </RegularButton>}
                <Button
                    className="ml-2" color="primary" onClick={() => {
                    this.props.history.push("/catalog/deliveryMatrices")
                }}
                >
                    Go back
                </Button>
                <Button
                    className="ml-2" color="primary" onClick={() => {
                    document.getElementById('weekends').checked = false;
                    document.getElementById('deliveryMethods').selectedIndex = 0;
                    document.getElementById('paymentMethods').selectedIndex = 0;
                    this.setState({
                        chosen_courier_company: undefined,
                        index: undefined,
                        holiday: undefined,
                        saturday: undefined,
                        sunday:  undefined,
                        online_payable: undefined,
                        cash_acceptable: undefined,
                        delivery_card_acceptable: undefined,
                        on_the_spot_pay_boxberry: undefined
                    })
                }}
                >
                    Refresh filters
                </Button>
            </div>
            <div className={"form-inline pl-2 ml-1 mb-3 mt-3"}>
                <DatepickerWithTime
                    datepickerDaySelected={this.state.matrixActivationDate}
                    datepickerPlaceholder={'Choose start date'}
                    datepickerSearchFunction={value => this.setState({ matrixActivationDate: value })}
                    timepickerPlaceholder={'Start time'}
                    timepickerSearchFunction={ value => {
                        if (value) {
                            this.setState({matrixActivationTime: value.format('HH:mm')})
                        } else {
                            this.setState({matrixActivationDate: undefined})
                            this.setState({matrixActivationTime: undefined})
                        }
                    }}
                    timepickerValue={this.state.matrixActivationTime}
                    className={'startDate'}
                />
                <label className="ml-3 mr-3 col-form-label-sm"> — </label>
                <DatepickerWithTime
                    datepickerDaySelected={this.state.matrixDeactivationDate}
                    datepickerPlaceholder={'Choose end date'}
                    datepickerSearchFunction={value => this.setState({ matrixDeactivationDate: value })}
                    timepickerPlaceholder={'End time'}
                    timepickerSearchFunction={value => {
                        if(value) {
                            this.setState({ matrixDeactivationTime: value.format('HH:mm') })
                        } else {
                            this.setState({ matrixDeactivationDate: undefined })
                            this.setState({ matrixDeactivationTime: undefined })
                        }
                    }}
                    timepickerValue={this.state.matrixDeactivationTime}
                    className={'endDate'}
                />
            </div>
            <RegularButton color="primary" className="mb-3" onClick={() => {
                const { matrixActivationDate, matrixActivationTime, matrixDeactivationDate, matrixDeactivationTime, Name: name, id } = this.state;

                if((matrixActivationDate && matrixActivationTime) || (matrixDeactivationDate && matrixDeactivationTime)) {
                    createMatrixActivation({
                        matrixActivationDate,
                        matrixActivationTime,
                        matrixDeactivationDate,
                        matrixDeactivationTime,
                        name,
                        matrixId: id
                    })
                        .then(() => notify.showSuccessToast('Delayed activation was successful'))
                        .then(() => this.setState({
                            reload: !this.state.reload,
                            matrixActivationDate: undefined,
                            matrixActivationTime: undefined,
                            matrixDeactivationDate: undefined,
                            matrixDeactivationTime: undefined
                        }))
                        .catch(error => notify.showDangerToast(error.message));
                }
            }}>
                Past to schedule
            </RegularButton>
        </div>
    }

    render() {
        if (this.state.error) return <Redirect push to="/catalog/deliveryMatriсes"/>;
        if (this.state.loading) return <Loader/>;
        const { index, chosen_courier_company, holiday, saturday, sunday, online_payable, cash_acceptable, delivery_card_acceptable, on_the_spot_pay_boxberry } = this.state;
        const filters = {
            delivery_matrix_id: this.props.match.params.id,
            index: index || undefined,
            courier_company: chosen_courier_company || undefined,
            holiday: holiday || undefined,
            saturday: saturday || undefined,
            sunday: sunday || undefined,
            online_payable : online_payable || undefined,
            cash_acceptable: cash_acceptable || undefined,
            delivery_card_acceptable: delivery_card_acceptable || undefined,
            on_the_spot_pay_boxberry: on_the_spot_pay_boxberry || undefined
        };

        return (
            <div className="card">
                <div className="card-body">
                    <p className="ml-4"><b>{this.state.Name}</b>{` (${this.state.Email})`}</p>
                    <div className="card-body">
                        <ul className="nav nav-tabs" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" id="home-tab" data-toggle="tab" href="#matrix-data" role="tab"
                                   aria-controls="matrix-data" aria-selected="true">Matrix Data</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="profile-tab" data-toggle="tab" href="#future-activations" role="tab"
                                   aria-controls="future-activations" aria-selected="false">Schedule</a>
                            </li>
                        </ul>
                        <div className="tab-content">
                            <MatrixItemsTable courierCompanies={this.state.courier_companies} filters={filters} createButtons={this.createButtons} input_file_id={this.state.input_file_id} deliveryMatrixId={this.props.match.params.id} />
                            <MatrixActivationsPanel matrixId={filters.delivery_matrix_id} reload={this.state.reload} setReload={() => this.setState({ reload: !this.state.reload })}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};