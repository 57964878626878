import React, {useState, useEffect, useRef} from 'react'
import {Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {showWarningToast} from "../../lib/notify";
import {createEntry, updateEntry} from "../../service/referenceData";
import {findAndCount as prioritiesFindAndCount, insertPriorities} from "../../service/deliveryMethodPriorities";
import useInput from "../../hooks/useInput";
import {
  emptyStringToNull,
  getCodeAndLabelOfDeliveryMethod,
  modifyWarehouseDataForTypeahead,
  nullToEmptyString
} from "../../common";
import "./styles.css";
import {Typeahead} from "react-bootstrap-typeahead";
import PropTypes from 'prop-types'
import MethodActivationsPanel from "./methodActivations/Index";
import RegularButton from "../generic/RegularButton";

const CEComponent = ({
                       changeData,
                       closeModalConfirmation,
                       type,
                       isOpen,
                       onToggle,
                       kind,
                       warehouses,
                       referenceData,
                       folderOnCreation,
                       folders
                     }) => {

  const limitSalesRef = useRef(null);
  const limitReplacementRef = useRef(null);
  const limitTotalRef = useRef(null);
  const maxDeliveryDelayRef = useRef(null);

  const [enabledForSFWidget, setEnabledForSFWidget] = useState(undefined);
  const [stockManagement, setStockManagement] = useState(undefined);
  const [enabledCDEKPVZ, setEnabledCDEKPVZ] = useState(undefined);
  const [enabledDPDPVZ, setEnabledDPDPVZ] = useState(undefined);
  const [priorities, setPriorities] = useState(undefined);
  const [methodsWithPriorityAndBlur, setMethodsWithPriorityAndBlur] = useState(undefined);
  const [warehouse, setWarehouse] = useState(undefined);
  const [folder, setFolder] = useState(undefined);
  const [scheduleParams, setScheduleParams] = useState({methodId: null, methodCode: null});
  let selectedWarehouse;

  const {value: code, bind: bindCode, changeValue: changeCodeValue} = useInput(undefined);
  const {value: label, bind: bindLabel, changeValue: changeLabelValue} = useInput(undefined);
  const {value: externalId, bind: bindExternalId, changeValue: changeExternalId} = useInput(undefined);
  const {value: websiteLabel, bind: bindWebsiteLabel, changeValue: changeWebsiteLabel} = useInput(undefined);
  const {value: websiteOrder, bind: bindWebsiteOrder, changeValue: changeWebsiteOrder} = useInput(undefined);
  const {value: pmiEsb, bind: bindPmiEsb, changeValue: changePmiEsb} = useInput(undefined);
  const {value: EOLabel, bind: bindEOLabel, changeValue: changeEOLabel} = useInput(undefined);
  const {value: limitSales, bind: bindLimitSales, changeValue: changeLimitSales} = useInput(undefined);
  const {
    value: limitReplacement,
    bind: bindLimitReplacement,
    changeValue: changeLimitReplacement
  } = useInput(undefined);
  const {value: limitTotal, bind: bindLimitTotal, changeValue: changeLimitTotal} = useInput(undefined);
  const {
    value: maxDeliveryDelay,
    bind: bindMaxDeliveryDelay,
    changeValue: changeMaxDeliveryDelay
  } = useInput(undefined);

  useEffect(() => {
    if (changeData) {
      const {
        code,
        label,
        externalId,
        websiteLabel,
        websiteOrder,
        enabledForSFWidget,
        stockManagement,
        enabledCDEKPVZ,
        enabledDPDPVZ,
        warehouse,
        folder,
        maxDeliveryDelay,
        limitSales,
        limitReplacement,
        limitTotal,
        pmiEsb,
        EOLabel,
        id
      } = changeData;

      changeCodeValue(code);
      changeLabelValue(label);
      changeExternalId(externalId);
      changeWebsiteLabel(websiteLabel);
      changeWebsiteOrder(websiteOrder);
      changeLimitSales(nullToEmptyString(limitSales));
      changeLimitReplacement(nullToEmptyString(limitReplacement));
      changeLimitTotal(nullToEmptyString(limitTotal));
      changePmiEsb(pmiEsb);
      changeEOLabel(EOLabel);
      changeMaxDeliveryDelay(nullToEmptyString(maxDeliveryDelay));
      setEnabledForSFWidget(enabledForSFWidget);
      setStockManagement(stockManagement);
      setEnabledCDEKPVZ(enabledCDEKPVZ);
      setEnabledDPDPVZ(enabledDPDPVZ);
      setWarehouse(warehouse);
      setFolder(folder);
      setScheduleParams({methodId: id, methodCode: code});
      if(priorities) {
        setMethodsWithPriorityAndBlur(priorities.data.filter(priority => priority.lessPriorityMethodCode === (changeData.code)));
      }
    }
  }, [changeData])

  useEffect(() => {
    if (isOpen) {
      loadPriorities();
    }
  }, [isOpen]);

  useEffect(() => {
    if (folderOnCreation && folders.some(it => it === folderOnCreation)) {
      setFolder(folderOnCreation)
    } else if (folderOnCreation) {
      setFolder(undefined)
    }
  }, [folderOnCreation, folders]);

  useEffect(() => {
    if (priorities && changeData) {
      setMethodsWithPriorityAndBlur(priorities.data.filter(priority => priority.lessPriorityMethodCode === (changeData.code)))
    }
  }, [priorities])

  async function loadPriorities() {
    if (type === 'edit') setPriorities(await prioritiesFindAndCount(0, 100000, 'id', 'asc'));
  }

  const convertWebsiteOrder = () => {
    if (type === 'edit') {
      if (!websiteOrder) {
        changeWebsiteOrder(changeData.websiteOrder)
        return Number(websiteOrder);
      } else {
        if (isNaN(Number(websiteOrder))) {
          showWarningToast('Website order must be a number.')
          return undefined;
        } else {
          changeWebsiteOrder(Number(websiteOrder))
          return Number(websiteOrder);
        }
      }
    } else {
      if (isNaN(Number(websiteOrder))) {
        showWarningToast('Website order must be a number.')
        return undefined;
      } else {
        changeWebsiteOrder(Number(websiteOrder))
        return Number(websiteOrder);
      }
    }
  }

  const validateIntegers = (integers) => {
    const invalid = integers.find(({current}) => current.props.value && current.props.value.toString().match(/[^0-9]/g));
    return invalid ? invalid.current.props.label : undefined
  }

  const onSave = () => {
    const data = {
      code,
      label,
      kind,
      externalId,
      websiteLabel,
      websiteOrder,
      enabledForSFWidget,
      stockManagement,
      enabledCDEKPVZ,
      enabledDPDPVZ,
      warehouse,
      folder,
      pmiEsb,
      EOLabel,
      limitSales: emptyStringToNull(limitSales),
      limitReplacement: emptyStringToNull(limitReplacement),
      limitTotal: emptyStringToNull(limitTotal),
      maxDeliveryDelay: emptyStringToNull(maxDeliveryDelay),
    }
    const catchError = (e) => {
      if(e.message === 'Method with existing code cannot exist.') {
        showWarningToast(e.message)
      } else showWarningToast('Something went wrong.')
    }

    if (!stockManagement) {
      data.warehouse = '';
    }

    if (kind === 'deliveryMethod') {
      const websiteOrderLocal = convertWebsiteOrder(websiteOrder);
      const intValidation = validateIntegers([limitSalesRef, limitReplacementRef, limitTotalRef, maxDeliveryDelayRef]);

      if (!websiteOrderLocal && websiteOrderLocal !== 0) {
        return;
      }
      if (intValidation) {
        showWarningToast(`${intValidation} are incorrect`);
        return;
      }
    }

    setWarehouse(undefined);

    if (type === 'create') {
      createEntry(data).then(closeModalConfirmation).catch(catchError);
    } else {
      updateEntry(changeData.id, data).then(() => insertPriorities(methodsWithPriorityAndBlur, changeData.code))
        .then(closeModalConfirmation).then(() => setScheduleParams({methodId: null, methodCode: null}))
        .catch(catchError);
    }
  }

  const onClose = () => {
    setWarehouse(undefined);
    setScheduleParams({methodId: null, methodCode: null});
    closeModalConfirmation();
  }

  const handleEnabledForSFWidgetChange = () => setEnabledForSFWidget(!enabledForSFWidget);
  const handleStockManagementChange = () => setStockManagement(!stockManagement);
  const handleEnabledCDEKPVZ = () => {
    setEnabledCDEKPVZ(!enabledCDEKPVZ);
    setEnabledDPDPVZ(false);
  }
  const handleEnabledDPDPVZ = () => {
    setEnabledDPDPVZ(!enabledDPDPVZ);
    setEnabledCDEKPVZ(false);
  }

  const handleSettingMethodPriorityOrBlur = (method, type) => {
    if (methodsWithPriorityAndBlur && methodsWithPriorityAndBlur.length > 0) {
      const index = methodsWithPriorityAndBlur.findIndex(elem => elem.morePriorityMethodCode === method.code);

      if (index === -1) {
        const methodsWithPriorityAndBlurLocal = methodsWithPriorityAndBlur.slice();
        methodsWithPriorityAndBlurLocal.push({
          morePriorityMethodCode: method.code,
          lessPriorityMethodCode: changeData ? changeData.code : code,
          blur: type === 'blur'
        })

        setMethodsWithPriorityAndBlur(methodsWithPriorityAndBlurLocal);
      } else {
        const methodsWithPriorityAndBlurLocal = methodsWithPriorityAndBlur.slice();
        if (type === 'blur' && methodsWithPriorityAndBlurLocal[index].blur) {
          methodsWithPriorityAndBlurLocal.splice(index, 1);
        } else if (type === 'priority' && !methodsWithPriorityAndBlurLocal[index].blur) {
          methodsWithPriorityAndBlurLocal.splice(index, 1);
        } else {
          methodsWithPriorityAndBlurLocal.splice(index, 1, {
            morePriorityMethodCode: method.code,
            lessPriorityMethodCode: code,
            blur: type === 'blur'
          });
        }

        setMethodsWithPriorityAndBlur(methodsWithPriorityAndBlurLocal);
      }
    } else {
      const methodsWithPriorityAndBlurLocal = [];
      methodsWithPriorityAndBlurLocal.push({
        morePriorityMethodCode: method.code,
        lessPriorityMethodCode: code,
        blur: type === 'blur'
      });

      setMethodsWithPriorityAndBlur(methodsWithPriorityAndBlurLocal);
    }
  }

  const onFolderChange = folder => {
    setFolder(folder);
  }

  const onWarehouseChoose = warehouse => {
    if (warehouse.length) {
      setWarehouse(warehouse[0].code);
    }
  }

  if (warehouses) {
    warehouses = modifyWarehouseDataForTypeahead(warehouses);
    selectedWarehouse = warehouse ? [warehouses.find(elem => elem.code === warehouse)] : '';
  }

  return <Modal isOpen={isOpen} toggle={onToggle}>
    <ModalHeader>{type === 'create' ? 'Create row' : 'Edit row'}</ModalHeader>
    <ModalBody>
        <div className="card-body">
            <ul className="nav nav-tabs" role="tablist">
                {kind === 'deliveryMethod' && changeData &&
                    <>
                        <li className="nav-item">
                            <a className="nav-link active" id="data-tab" data-toggle="tab" href="#dm-data" role="tab"
                               aria-controls="dm-data" aria-selected="true">Data</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" id="methods-tab" data-toggle="tab" href="#dm-methods" role="tab"
                               aria-controls="dm-methods" aria-selected="false">Methods</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" id="shedule-tab" data-toggle="tab" href="#dm-activations" role="tab"
                               aria-controls="dm-activations" aria-selected="false">Schedule</a>
                        </li>
                    </>
                }
            </ul>
            <div className="tab-content">
                <div className="tab-pane fade active show" id="dm-data" role="tabpanel"
                     aria-labelledby="data-tab">
                    <div className="row">
                        <FormGroup
                            className={`ml-2 mr-2 leftGroupCreate`}>
                            <div className="row">
                                <div className="col-6">
                                    <Label for="code">Code</Label>
                                    <Input type="text" name="code" id="code" placeholder="Enter code ..." {...bindCode} />
                                </div>
                                <div className="col-6">
                                    <Label for="label">Label</Label>
                                    <Input type="text" name="label" id="label" placeholder="Enter label ..." {...bindLabel} />
                                </div>
                            </div>
                            {kind === 'deliveryMethod' && <div className="row">
                                <div className="col-6">
                                    <Label for="websiteLabel" className="mt-3">Website label</Label>
                                    <Input type="text" name="websiteLabel" id="websiteLabel"
                                           placeholder="Enter website label ..." {...bindWebsiteLabel}/>
                                </div>
                                <div className="col-6">
                                    <Label for="externalId" className="mt-3">ShippingId</Label>
                                    <Input type="text" name="externalId" id="externalId"
                                           placeholder="Enter shippingId ..." {...bindExternalId}/>
                                </div>
                                <div className="col-6">
                                    <Label for="EOLabel" className="mt-3">EOLabel</Label>
                                    <Input type="text" name="EOLabel" id="EOLabel"
                                           placeholder="Enter EOLabel ..." {...bindEOLabel} />
                                </div>
                                <div className="col-6">
                                    <Label for="limitSales" className="mt-3">Limit sales</Label>
                                    <Input ref={limitSalesRef} label="Limit sales" type="text" name="limitSales" id="limitSales"
                                           placeholder="Enter limit sales ..." {...bindLimitSales} />
                                </div>
                                <div className="col-6">
                                    <Label for="websiteOrder" className="mt-3">Website order</Label>
                                    <Input type="text" name="websiteOrder" id="websiteOrder"
                                           placeholder="Enter website order ..." {...bindWebsiteOrder}/>
                                </div>
                                <div className="col-6">
                                    <Label for="limitReplacement" className="mt-3">Limit replacement</Label>
                                    <Input ref={limitReplacementRef} label="Limit replacement" type="text" name="limitReplacement"
                                           id="limitReplacement"
                                           placeholder="Enter limit replacement ..." {...bindLimitReplacement} />
                                </div>
                                <div className="col-6">
                                    <Label for="maxDeliveryDelay" className="mt-3">Max delivery delay</Label>
                                    <Input ref={maxDeliveryDelayRef} label="Max delivery delay" type="text" name="maxDeliveryDelay"
                                           id="maxDeliveryDelay"
                                           placeholder="Enter max delivery delay ..." {...bindMaxDeliveryDelay} />
                                </div>
                                <div className="col-6">
                                    <Label for="limitTotal" className="mt-3">Limit total</Label>
                                    <Input ref={limitTotalRef} label="Limit total" type="text" name="limitTotal" id="limitTotal"
                                           placeholder="Enter limit total ..." {...bindLimitTotal} />
                                </div>
                                <div className="col-6">
                                    <Label for="typeaheadFolder" className="mt-3">Folder</Label>
                                    <Typeahead
                                        id="typeaheadFolder"
                                        options={folders}
                                        placeholder="Choose folder..."
                                        onChange={onFolderChange}
                                        defaultInputValue={(changeData && changeData.folder)
                                        || (folderOnCreation !== '' ? folderOnCreation : undefined)}
                                    />
                                </div>
                                <div className="col-6">
                                    <Label for="pmiEsb" className="mt-3">PMI ESB</Label>
                                    <Input type="text" name="pmiEsb" id="pmiEsb"
                                           placeholder="Enter PMI ESB ..." {...bindPmiEsb} />
                                </div>
                                <div className="col-6">
                                    <Label for="typeaheadFolder" className="mt-3">Warehouse</Label>
                                    <Typeahead
                                        id="typeahead"
                                        options={warehouses}
                                        placeholder="Choose warehouse..."
                                        onChange={onWarehouseChoose}
                                        selected={selectedWarehouse}
                                    />
                                </div>
                                <div className="col-12">
                                    <div className="form-check mt-3 mr-3">
                                        <label className="form-check-label">
                                            <input
                                                type="checkbox"
                                                onChange={handleEnabledForSFWidgetChange}
                                                checked={!!enabledForSFWidget}
                                            />
                                            Enabled for SF widget
                                            <i className="input-helper"/>
                                        </label>
                                    </div>
                                    <div className="form-check mt-3 mr-3">
                                        <label className="form-check-label">
                                            <input
                                                type="checkbox"
                                                onChange={handleStockManagementChange}
                                                checked={!!stockManagement}
                                            />
                                            Stock Management
                                            <i className="input-helper"/>
                                        </label>
                                    </div>
                                    <div className="form-check mt-3 mr-3">
                                        <label className="form-check-label">
                                            <input
                                                type="checkbox"
                                                onChange={ handleEnabledCDEKPVZ }
                                                checked={ !!enabledCDEKPVZ }
                                            />
                                            CDEK PVZ
                                            <i className="input-helper"/>
                                        </label>
                                    </div>
                                    <div className="form-check mt-3 mr-3">
                                        <label className="form-check-label">
                                            <input
                                                type="checkbox"
                                                onChange={ handleEnabledDPDPVZ }
                                                checked={ !!enabledDPDPVZ }
                                            />
                                            DPD PVZ
                                            <i className="input-helper"/>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            }
                        </FormGroup>
                    </div>
                </div>
                {kind === 'deliveryMethod' && changeData &&
                <>
                    <div className="tab-pane fade" id="dm-methods" role="tabpanel"
                         aria-labelledby="methods-tab">
                        <FormGroup>
                            <div>
                                <h5 className="mb-2">Exclude or blur if the following methods exist:</h5>
                                <table style={{width: "100%"}}>
                                    <th>Method</th>
                                    <th>Hide</th>
                                    <th>Blur</th>
                                    {
                                        referenceData && referenceData.data.map(method => {
                                            if(method.kind === 'deliveryMethod') {
                                                const hasHigherPriority = methodsWithPriorityAndBlur && methodsWithPriorityAndBlur.find(elem => elem.morePriorityMethodCode === method.code && !elem.blur);
                                                const hasBlur = methodsWithPriorityAndBlur && methodsWithPriorityAndBlur.find(elem => elem.morePriorityMethodCode === method.code && elem.blur);

                                                return (
                                                    <tr>
                                                        <td
                                                            className="methodPriority">{getCodeAndLabelOfDeliveryMethod(referenceData, {method: method.code})}</td>
                                                        <td className="checkboxPriorityOrBlur">
                                                            <div className="form-check">
                                                                <label className="form-check-label">
                                                                    <input
                                                                        type="checkbox"
                                                                        onChange={() => handleSettingMethodPriorityOrBlur(method, 'priority')}
                                                                        checked={!!hasHigherPriority}
                                                                    />
                                                                    <i className="input-helper"/>
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td className="checkboxPriorityOrBlur">
                                                            <div className="form-check">
                                                                <label className="form-check-label">
                                                                    <input
                                                                        type="checkbox"
                                                                        onChange={() => handleSettingMethodPriorityOrBlur(method, 'blur')}
                                                                        checked={!!hasBlur}
                                                                    />
                                                                    <i className="input-helper"/>
                                                                </label>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        })
                                    }
                                </table>
                            </div>
                        </FormGroup>
                    </div>
                    <div className="tab-pane fade" id="dm-activations" role="tabpanel"
                         aria-labelledby="shedule-tab">
                        <MethodActivationsPanel methodId={scheduleParams.methodId} methodCode={scheduleParams.methodCode}/>
                    </div>
                </>
                }
            </div>
        </div>
    </ModalBody>
    <ModalFooter>
      <RegularButton color="primary" onClick={onSave}>Save</RegularButton>{' '}
      <Button color="secondary" onClick={onClose}>Cancel</Button>
    </ModalFooter>
  </Modal>
}

CEComponent.propTypes = {
  type: PropTypes.string,
  isOpen: PropTypes.bool,
  kind: PropTypes.string,
  closeModalConfirmation: PropTypes.func,
  warehouses: PropTypes.array,
  folders: PropTypes.array,
  referenceData: PropTypes.object
}

export default CEComponent;