import React from "react";
import "./SortableColumn.css"
import PropTypes from "prop-types";

class SortableColumn extends React.Component {

    constructor(props) {
        super(props);
        this.state = { sort: props.sort };
        this.handleSort = this.handleSort.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({sort: nextProps.sort});
    }

    handleSort() {
        let newSort;
        switch(this.state.sort) {
            case "asc": newSort = "desc"; break;
            case "desc": newSort = undefined; break;
            default: newSort = "asc";
        }
        this.setState({sort: newSort});
        if(this.props.handleSort) this.props.handleSort(newSort);
    }

    render() {
        const {children} = this.props;
        return (
            <th className={"sortable-column " + (this.state.sort ? `sortable-column-${this.state.sort}` : "")} onClick={this.handleSort}>
                {children}
                <i className="fas fa-arrow-down fa-sm"/>
                <i className="fas fa-arrow-up fa-sm"/>
            </th>
        );
    }
}

SortableColumn.propTypes = {
    handleSort: PropTypes.func.isRequired,
    sort: PropTypes.oneOf(['asc', 'desc'])
};

export default SortableColumn;