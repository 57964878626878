import React from "react";
import DataTable, {field} from "../generic/DataTable";
import {exportAlternativeProducts, findAndCount} from "../../service/alternativeProducts";
import ExportFunButton from "../generic/ExportFunButton";

const Table = ({ filters }) => {
    return (
        <DataTable
            filters={filters}
            findAndCount={findAndCount}
            fields={[
                field('Code', true, 'code'),
                field("Name", true, "name"),
                field("Alternative Code", true, "alternativeCode"),
                field("Alternative Name", true, "alternativeName"),
            ]}
            tableButtons={dataParameters => <ExportFunButton dataParameters={dataParameters} exportFun={exportAlternativeProducts}/> }
            name={"alternative_products"}
        />
    );
}

export default Table;