import moment from "moment";

export const getDateWithTimezone = () => {
    return (Date.now() + ((new Date().getTimezoneOffset() / -60) * 60 * 60 * 1000))
};

export const getLabelOfDeliveryMethod = (methods, data) => {
    if(methods && methods.data) {
        const method = methods.data.find(method => method.code === data.method);
        if(method) {
            return method.label;
        }
    }
}

export const getCodeOfDeliveryMethod = (methods, label) => {
    if(methods && methods.data && label) {
        const method = methods.data.find(method => method.label.toUpperCase().includes(label.toUpperCase()));

        if(method) {
            return method.code;
        }
    }
}

export const getCodeAndLabelOfDeliveryMethod = (methods, data) => {
    if(methods && methods.data) {
        const method = methods.data.find(method => method.code === data.method);
        if(method) {
            return `${method.code} ${method.label}`;
        }
    }
}

export const modifyMethodLabelOfObjectForTypeahead = obj => (obj && obj.data) ? obj.data.map(elem => ({
    code: elem.code,
    enabledForSFWidget: elem.enabledForSFWidget,
    externalId: elem.externalId,
    id: elem.id,
    kind: elem.kind,
    label: `${elem.code} ${elem.label}`,
    websiteLabel: elem.websiteLabel,
    websiteOrder: elem.websiteOrder
})) : [];

export const modifyWarehouseDataForTypeahead = obj => (obj && obj.data) ? obj.data.map(elem => ({
    code: elem.code,
    label: elem.name
})) : [];

export const nullToEmptyString = (value) => {
    return value !== null ? value : ''
}

export const emptyStringToNull = (value) => {
    return value !== '' ? value : null
}

export const exportStatuses = {
    status: {
        Processing: "processing",
        Ready: "ready",
        Error: "error",
        Deleted: "deleted"
    }
}

export const booleanToTinyint = (value) => {
    return value === true ? 1 : 0;
}

export const getRequestLogsParams = (filters) => {
    if(filters.name) {
        filters['name_contains'] = filters.name;
        delete filters.name;
    }

    if(filters.activationDate && filters.activationTime) {
        const time = filters.activationTime.split(':');

        filters.activationDatetime_gte = moment(filters.activationDate).set({
            hours: +time[0],
            minutes: +time[1]
        }).utc(false).format('YYYY-MM-DD HH:mm');
    }

    if(filters.deactivationDate && filters.deactivationTime) {
        const time = filters.deactivationTime.split(':');

        filters.deactivationDatetime_lte = moment(filters.deactivationDate).set({
            hours: +time[0],
            minutes: +time[1]
        }).utc(false).format('YYYY-MM-DD HH:mm');
    }

    delete filters.activationDate;
    delete filters.activationTime;
    delete filters.deactivationDate;
    delete filters.deactivationTime;

    return Object.assign({}, filters);
}
