import React from "react";
import PropTypes from 'prop-types';
import DataTable, {field} from "../../../generic/DataTable";
import {findAndCount} from "../../../../service/deliveryMatrixRaw";
import Loader from "../../../generic/Loader";
import moment from "moment";

export default class Table extends React.Component {

    constructor(props) {
        super(props);
        this.handleSort = this.handleSort.bind(this);

        this.state = {
            sortField: "id",
            sortDir: "desc",
            start: 0,
            limit: 10
        };
    }


    handleSort(field) {
        return dir => this.setState({sortField: field, sortDir: dir});
    }


    componentWillMount() {
    }


    render() {
        const filters = {};
        const {uploadId} = this.props;

        if (this.state.loading) return <Loader/>;

        if (uploadId) filters.input_file_id = uploadId;

        return (<DataTable
            filters={filters}
            findAndCount={findAndCount}
            fields={[
                field("INDEX", true, 'index'),
                field("City", true, "city"),
                field("Created at", true, "created_at",res => moment(res.created_at).format("YYYY.MM.DD")),
                field("Region", true, "region"),
                field("Input File Id", true, "input_file_id")
            ]}
            name={"deliveryMatrixRaw"}
        />);
    }
}

Table.propTypes = {
    uploadId: PropTypes.number
};