import {strapi} from "../lib/auth";

export const findAndCount = async (start = 0, limit = 20) => {
    const rawData = await strapi.getEntries("users-permissions/roles")
        .then(x => x && (x.roles || []).filter(r => r["type"] !== "public"));
    const total = rawData.length;
    const data = rawData.slice(start, start + limit);

    return {total, data};
};

export const all = async () => findAndCount(0, 10000).then(x => x.data);

export default {findAndCount, all};