import React, {useEffect} from 'react'
import {Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import { showWarningToast } from "../../lib/notify";
import { createEntry, updateEntry } from "../../service/warehouses";
import * as notify from "../../lib/notify"
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import RegularButton from "../generic/RegularButton";

const CEComponent = ({ isOpen, closeModalConfirmation, type, changeData }) => {
  const { register, handleSubmit, formState: { errors }, reset } = useForm({});

  useEffect(() => {
    reset(changeData);
  }, [changeData])

  const onSave = data => {
    const { name, code } = data;

    if(type === 'create') {
      if(code && name) {
        createEntry({ name, code }).then(closeModalConfirmation).catch(() => showWarningToast('Something went wrong.'));
      } else {
        notify.showDangerToast('All fields must be filled');
      }
    } else {
      if(code && name) {
        updateEntry(changeData.id, { name, code }).then(closeModalConfirmation).catch(() => showWarningToast('Something went wrong.'));
      } else {
        notify.showDangerToast('All fields must be filled');
      }
    }
  }

  return <Modal isOpen={isOpen}>
    <ModalHeader>{type === 'create' ? 'Create row' : 'Edit row'}</ModalHeader>
    <ModalBody>
      <form onSubmit={handleSubmit(onSave)}>
      <FormGroup>
          <Label for="code">Code</Label>
          <input
              name="code"
              placeholder="Enter code"
              className={`form-control mb-2 ${errors.code ? "is-invalid" : ""}`}
              ref={register({
                required: "Code is required"
              })}
          />
        {(errors && errors.code) && <div className="invalid-feedback">{errors.code.message}</div>}
        <Label for="name">Name</Label>
        <input
            name="name"
            placeholder="Enter name"
            className={`form-control ${errors.name ? "is-invalid" : ""}`}
            ref={register({
              required: "Name is required"
            })}
        />
        {(errors && errors.name) && <div className="invalid-feedback">{errors.name.message}</div>}
      </FormGroup>
      </form>
    </ModalBody>
    <ModalFooter>
      <RegularButton color="primary" className="mr-2" onClick={() => {
        handleSubmit(onSave)()
      }}>Save</RegularButton>
      <Button color="secondary" onClick={ closeModalConfirmation }>Cancel</Button>
    </ModalFooter>
  </Modal>
}

CEComponent.propTypes = {
  closeModalConfirmation: PropTypes.func,
  type: PropTypes.string,
  changeData: PropTypes.object,
  isOpen: PropTypes.bool
}

export default CEComponent;