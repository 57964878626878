import React from "react";
import DataTable, { field } from "../../../generic/DataTable";
import { findAndCount } from "../../../../service/deliveryMethodProductsRaw";
import { getCodeAndLabelOfDeliveryMethod } from '../../../../common';

const Table = ({ uploadId, methods, reload }) => {
    const filters = {};

    if (uploadId) filters.input_file_id = uploadId;

    return (<DataTable
        filters={ filters }
        findAndCount={ findAndCount }
        fields={[
          field("Product Code", true, "code"),
          field("Delivery Method", true, "method", response => getCodeAndLabelOfDeliveryMethod(methods, response)),
          field('Availability', true, 'availability')
        ]}
        name={ "deliverymethodproductsraw" }
        reload={ reload }
    />);
}

export default Table;