import React from "react";
import DataTable, { field } from "../../../generic/DataTable";
import { findAndCount } from "../../../../service/alternativeProductsRaw";

const Table = ({ uploadId, reload }) => {
    const filters = {};

    if (uploadId) filters.input_file_id = uploadId;

    return (<DataTable
        filters={ filters }
        findAndCount={ findAndCount }
        fields={[
            field("Code", true, "code"),
            field("Name", true, "name"),
            field('Alternative code', true, 'alternativeCode'),
            field('Alternative name', true, 'alternativeName'),
        ]}
        name={ "alternativeproductsraw" }
        reload={ reload }
    />);
}

export default Table;