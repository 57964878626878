import React, { Fragment } from "react";
import Card from "../../../generic/Card";
import { Col, Row } from "reactstrap";
import Loader from "../../../generic/Loader";
import InputFileCard from "../../InputFileCard";
import InputFileProcess from "../../InputFileProcess";
import InputFileDatabaseCard from "../../InputFileDatabaseCard";
import StocksTable from "../stocksData/Table";
import StocksRawTable from "../stocksRaw/Table";
import { Redirect } from "react-router-dom";

class Index extends InputFileProcess {

    constructor(props) {
        super(props);

        this.state = {
            reload: false
        }
    }

    render() {
        const { loading, deleted, inputFile, reload } = this.state;
        if (loading) return <Card title={ "Process file" }><Loader/></Card>;
        if (deleted) return <Redirect push to={ "/input/stocks/upload" }/>;
        if (!inputFile) return "";

        const { status } = inputFile;

        return (
            <Fragment>
                {super.render()}
                <br/>
                <Row>
                    <Col lg={["success", "loading"].includes(status) ? 6 : 12} md={6}>
                        <InputFileCard inputFile={ inputFile } onCancel={ this.onConfirmCancel }
                                       onDelete={ this.onConfirmDelete }
                                       onConfirm={this.onConfirm}/>
                    </Col>

                    {["success", "loading"].includes(status) && <Col lg={6} md={6}>
                        <InputFileDatabaseCard inputFile={ inputFile }/>
                    </Col>}
                </Row>
                <br/>

                <>
                    {
                        status === "success" &&
                        <Card title={"Database load result"}>
                            <StocksTable uploadId={ inputFile.id } />
                        </Card>
                    }
                    {
                        status === "mapping" &&
                        <Card title={ "Raw file data" }>
                            <StocksRawTable uploadId={ inputFile.id } isNew={ true } reload={ reload } />
                        </Card>
                    }
                </>

            </Fragment>
        );
    }
}

export default Index;