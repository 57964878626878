import React from "react";
import TableCard from "../generic/TableCard";
import Table from "./Table";
import TextSearch from "../generic/field/TextSearch";
import { pickBy } from 'lodash';
import { useQueryParam, StringParam } from 'use-query-params';
import {Button} from "reactstrap";

const Index = () => {
    const [filterCode, setFilterCode] = useQueryParam('code', StringParam);
    const [filterAlternativeCode, setFilterAlternativeCode] = useQueryParam('alternativeCode', StringParam);

    const searchCode = code => {
        setFilterCode(code)
    };

    const searchAlternativeCode = alternativeCode => {
        setFilterAlternativeCode(alternativeCode)
    };

    const dropFilters = () => {
        setFilterCode(undefined)
        setFilterAlternativeCode(undefined)
    };

    const filters = pickBy({
        code: filterCode,
        alternativeCode: filterAlternativeCode
    })

    return (
        <TableCard title={"Alternative products"}>
            <div style={{minHeight: "400px"}}>
                <div className={"row justify-content-start ml-1 mb-3"}>
                    <TextSearch
                        type="Code"
                        name="Code"
                        onSearch={ searchCode }
                        className="form-control col-sm-3 mr-2"
                        value={ filterCode }
                        placeholder={"Enter code..."}
                    />
                    <TextSearch
                        type="AlternativeCode"
                        name="AlternativeCode"
                        onSearch={ searchAlternativeCode }
                        className="form-control col-sm-3 mr-2"
                        value={ filterAlternativeCode }
                        placeholder={"Enter alternative code..."}
                    />
                    <Button className="mr-sm-2" color="default" onClick={ dropFilters }>
                        <i className="fa fa-fw fa-times"/>
                        Cancel
                    </Button>
                </div>
                <Table filters={ filters } />
            </div>
        </TableCard>
    )
}

export default Index;