import React from 'react';
import PropTypes from "prop-types";
import {Button} from "reactstrap";

export default function RoleButton({ disableFor, role, children, notComponent, ...props}) {
    let isDisabled = disableFor && (role === disableFor);

    return (
        notComponent ?
            <button disabled={isDisabled}{...props}>{children}</button>:
            <Button disabled={isDisabled}{...props}>{children}</Button>
    )
}

RoleButton.propTypes = {
    disableFor: PropTypes.string,
    children: PropTypes.element.isRequired,
    role: PropTypes.string,
    notComponent: PropTypes.bool
}