import React from "react";
import DataTable, {field} from "../generic/DataTable";
import {findAndCount} from "../../service/deliverymatrixactivationslogs";
import PropTypes from "prop-types";
import {datetime} from "../../lib/format";

const Table = ({ filters }) => {
    return (
        <DataTable
            filters={filters}
            findAndCount={findAndCount}
            fields={[
                field('ID', true, 'id'),
                field("Name", true, "name"),
                field("User", false, "user"),
                field("Activation Time", true, "activationDatetime", response => datetime(response.activationDatetime)),
                field("Deactivation Time", true, "deactivationDatetime", response => datetime(response.deactivationDatetime))
            ]}
            name={"deliverymatrixlogs"}
        />
    );
}

Table.propTypes = {
    filters: PropTypes.object
}

export default Table;