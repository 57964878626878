import React, {useState} from 'react'
import DataTable, {field} from "../../generic/DataTable";
import { findAndCount } from "../../../service/user";
import {Link, Redirect} from "react-router-dom";
import TableCard from "../../generic/TableCard";

export default function AdministrationUser() {
    const [selectedUserId, setSelectedUserId] = useState(undefined);
    if (selectedUserId) return <Redirect to={`/administration/user/edit/${selectedUserId}`}/>;

    return (
        <TableCard title={"Users"}>
            <div className={"row justify-content-start"} style={{margin: 10}}>
                <div className={"col-"}>
                    <Link className="btn btn-primary" to="/administration/user/edit">
                        <i className="fa fa-plus"/>
                        &nbsp; Add user
                    </Link>
                </div>
            </div>
            <div className={"pt-3"}>
                <DataTable
                    findAndCount={ findAndCount }
                    fields={[
                        field("ID", true, "id"),
                        field("Username", true, "username"),
                        field("Email", true, "email"),
                        field("Is blocked", true, "blocked", response => response.blocked ? "Yes" : "No"),
                    ]}
                    name={ "users" }
                    onClick={response => setSelectedUserId(response.id)}
                />
            </div>
        </TableCard>
    )
}