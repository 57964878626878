import React from "react";
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
class Title extends React.Component {
    render() {

        const {title, breadcrumbs} = this.props;
        return (
            <div className="row">
                <div className="col-md-12 mb-3">
                    <div className="d-flex align-items-baseline flex-wrap mt-1">
                        <h2 className="mr-4 mb-0">

                            {title}</h2>
                        <div className="d-flex align-items-baseline mt-2 mt-sm-0">
                            {breadcrumbs.map(item => {
                                return item.icon ? <i className={`fas fa-${item.icon} mr-1 text-muted`}/> :
                                <Link to={item.to}>
                                    <span key={"breadcrumbs-"+item.title}>
                                        <i className="fas fa-chevron-right fa-xs mr-1 text-muted"/>
                                        <small className="mb-0 mr-1">{item.title}</small>
                                    </span>
                                </Link>
                            })
                            }

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Title.propTypes = {
    title: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired
};

export default Title;