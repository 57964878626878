import React from 'react';
import "./Loader.css";

class Loader extends React.Component {
    render() {
        return <div className="loader-spinner">
            <div className={"flip-square-loader mx-auto" + (this.props.loading === false ? "d-none" : "")}/>
        </div>;
    }
}

export default Loader;

