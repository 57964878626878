import cookie from "js-cookie";

export const setCookie = (key, value, options = {}) => {
    cookie.set(key, value, {
        expires: new Date(new Date().getTime() + 30 * 60 * 1000), //30m
        path: "/",
        ...options
    });
};

export const removeCookie = key => {
    cookie.remove(key, {
        expires: 1
    });
};

export const getCookie = (key) => {
    return cookie.get(key);
};

export const saveToken = (res) => {
    setCookie("jwt", res.jwt, {secure: true, sameSite: 'strict'});
    window.localStorage.setItem("user", JSON.stringify({
        username: res.user.username,
        role: res.user.role.name,
        email: res.user.email,
        id: res.user.id
    }));
}
