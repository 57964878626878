import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import React from "react";
import PropTypes from "prop-types";

const DeletePopup = ({ children, onDelete, onCancel, isOpen }) => {
    return (
        <Modal isOpen={isOpen}>
        <ModalHeader>Delete row</ModalHeader>
        <ModalBody>
            { children }
        </ModalBody>
        <ModalFooter>
            <Button color="primary" className="mr-2" onClick={ onDelete }>Delete</Button>
            <Button color="secondary" onClick={ onCancel }>Cancel</Button>
        </ModalFooter>
    </Modal>
    )
}

DeletePopup.propTypes = {
    children: PropTypes.element.isRequired,
    onDelete: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired
}

export default DeletePopup;