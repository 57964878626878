import Strapi from "strapi-sdk-javascript";
import {getJwt, setRefreshedJWT} from "./auth";

class StrapiRemote extends Strapi {
    request(method, url, requestConfig) {
        return super.request(method, url, requestConfig).then(setRefreshedJWT)
    }
    getEntries(contentTypePluralized, params) {
        return super.getEntries(contentTypePluralized, params).then(setRefreshedJWT);
    }
    getEntryCount(contentType, params) {
        return super.getEntryCount(contentType, params).then(setRefreshedJWT);
    }
    deleteEntry(contentTypePluralized, id) {
        return super.deleteEntry(contentTypePluralized, id).then(setRefreshedJWT);
    }
    createEntry(contentTypePluralized, data) {
        return super.createEntry(contentTypePluralized, data).then(setRefreshedJWT);
    }
    updateEntry(contentTypePluralized, id, data) {
        return super.updateEntry(contentTypePluralized, id, data).then(setRefreshedJWT);
    }
}

export const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:1337";
export const remote = new StrapiRemote(apiUrl);


export class Service {
    constructor(entity, defaultSortField = "id", defaultSortDir = "desc", defaultLimit = 20) {
        this.entity = entity;
        this.defaultSortField = defaultSortField;
        this.defaultSortDir = defaultSortDir;
        this.defaultLimit = defaultLimit;
        this.find = this.find.bind(this);
        this.count = this.count.bind(this);
        this.findAndCount = this.findAndCount.bind(this);
        this.findAndCountServerSide = this.findAndCountServerSide.bind(this);
        this.createEntry = this.createEntry.bind(this);
        this.deleteEntry = this.deleteEntry.bind(this);
        this.updateEntry = this.updateEntry.bind(this);
        this.export = this.export.bind(this);
    }

    find(start = 0, limit = this.defaultLimit, sortField = this.defaultSortField, sortDir = this.defaultSortDir, filters = {}) {

        const withSortAndLimit = Object.assign(filters, {
            "_sort":`${sortField}:${sortDir.toUpperCase()}`,
            "_limit": limit,
            "_start": start
        });

        return remote.getEntries(this.entity, withSortAndLimit)
    }

    count(filters = {}) {
        return remote.getEntryCount(this.entity, filters);
    }

    /**
     * Exports all data by default (start and limit = 0)
     * @param start
     * @param limit
     * @param sortField
     * @param sortDir
     * @param filters
     * @returns {Promise<any>}
     */
    export(start = 0, limit = 0, sortField = this.defaultSortField, sortDir = this.defaultSortDir, filters = {}) {

        const withSort = Object.assign(filters, {
            "_sort": `${sortField}:${sortDir.toUpperCase()}`
        });

        if(limit && limit > 0) {
            withSort._limit = limit;
            withSort._start = start;
        }

        return remote.request("get", `/${this.entity}/export`, {params: withSort});
    }

    findAndCount(start = 0, limit = this.defaultLimit, sortField = this.defaultSortField, sortDir = this.defaultSortDir, filters = {}) {
        return Promise.all([
            this.find(start, limit, sortField, sortDir, filters),
            this.count(filters)
        ]).then(([data, total]) => ({data, total}));
    }

    request(method, url, params) {
        return remote.request(method, url, params);
    }

    findAndCountServerSide(start = 0, limit = this.defaultLimit, sortField = this.defaultSortField, sortDir = this.defaultSortDir, filters = {}) {

        const withSortAndLimit = Object.assign(filters, {
            "_sort":`${sortField}:${sortDir.toUpperCase()}`,
            "_limit": limit,
            "_start": start
        });

        remote.setToken(getJwt())

        return remote.request("get", `/${this.entity}/findAndCount`, {params:withSortAndLimit});
    }

    deleteEntry(id) {
        return remote.deleteEntry(this.entity, id);
    }

    createEntry(data) {
        return remote.createEntry(this.entity, data);
    }

    updateEntry(id, data) {
        return remote.updateEntry(this.entity, id, data)
    }

    saveEntry = (id, data) => {
      if (id) {
        return this.updateEntry(id, data);
      } else {
        return this.createEntry(data);
      }
    }
}

export default {remote, Service};