import React from "react";
import PropTypes from 'prop-types';
import {Button, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import * as notify from "../../../../lib/notify";
import {insertData} from "../../../../service/referenceData";

export default class MappingModal extends React.Component {
    constructor(props) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.onSave = this.onSave.bind(this);
        this.state = {
            label: undefined,
            method: undefined,
            reload: undefined
        };
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    onSave() {
        const {label, method} = this.state;
        const { onToggle, reloadRawTable } = this.props;
        if(label && method) {
            insertData(method, this.props.code, label).then(() => {
                onToggle();
                reloadRawTable();
            });
        } else {
            notify.showWarningToast('All fields are required.')
        }
    }

    render() {
        const {isOpen, onToggle, code} = this.props;
        const {label, method} = this.state;
        return <Modal isOpen={isOpen} toggle={onToggle} className="">
            <ModalHeader toggle={onToggle}>Enter new Data"</ModalHeader>
            <ModalBody>
                <Form>
                    <FormGroup>
                        <Label for="code">Code</Label>
                        <input type="text" name="code" id="code" placeholder="Enter code ..." className="form-control"
                               value={code} />
                        <Label for="label">Label</Label>
                        <input type="text" name="label" id="label" placeholder="Enter label ..." className="form-control"
                               value={label} onChange={this.handleInputChange} />
                        <div className="form-check mt-3 mr-3">
                            <label className="form-check-label">
                                <input
                                    checked={method === 'deliveryMethod'}
                                    type="checkbox"
                                    onClick={() => {
                                        this.setState({
                                            method: "deliveryMethod"
                                        })
                                    }}
                                />
                                Delivery Method
                                <i className="input-helper"/>
                            </label>
                        </div>
                        <div className="form-check mt-3 mr-3">
                            <label className="form-check-label">
                                <input
                                    checked={method === 'paymentMethod'}
                                    type="checkbox"
                                    onClick={() => {
                                        this.setState({
                                            method: "paymentMethod"
                                        })
                                    }}
                                />
                                Payment Method
                                <i className="input-helper"/>
                            </label>
                        </div>
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={this.onSave}>Save</Button>{' '}
                <Button color="secondary" onClick={onToggle}>Cancel</Button>
            </ModalFooter>
        </Modal>
    }
}

MappingModal.propTypes = {
    reloadRawTable: PropTypes.func.isRequired,
    code: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onToggle: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired
};