import React from "react";
import {logout} from "../../lib/auth";
import {Link} from "react-router-dom";
import PropTypes from 'prop-types';
import {cookie} from "../../lib/cookie";

class HeaderUser extends React.Component {
    render() {
        return (
            <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end" onLoad={() => this.getCookieSidebar()}>
                <button className="navbar-toggler navbar-toggler align-self-center" onClick={() => this.setCookieSidebar()} type="button"
                        data-toggle="minimize">
                    <span className="fas fa-align-justify"/>
                </button>
                <ul className="navbar-nav navbar-nav-right">
                    <li className="nav-item nav-profile dropdown">
                        <a className="nav-link" href="/" data-toggle="dropdown" id="profileDropdown">
                            <img src="/images/person/1.png" alt="profile"/>
                        </a>
                        <div className="dropdown-menu dropdown-menu-right navbar-dropdown"
                             aria-labelledby="profileDropdown">
                            <Link to="/me" className="dropdown-item">
                                <i className="fa fa-cog text-primary"/>
                                Settings
                            </Link>
                            <Link to={"/login"} className="dropdown-item" onClick={logout}>
                                <i className="fa fa-sign-out-alt text-primary"/>
                                Logout
                            </Link>
                        </div>
                    </li>
                </ul>
                <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
                        type="button"
                        onClick={this.props.onBurgerClick}>
                    <span className="fas fa-align-justify"/>
                </button>
            </div>
        );
    }
    getCookieSidebar() {
        if(cookie('dm_sidebar') === 'false') {
            document.body.classList.add('sidebar-icon-only')
        }
    }
    setCookieSidebar() {
        const resp = cookie('dm_sidebar') === 'true';
        if(cookie('dm_sidebar') === undefined) {
            cookie('dm_sidebar', false);
            document.body.classList.add('sidebar-icon-only')
            return
        }
        if(!resp) {
            cookie('dm_sidebar', true);
            document.body.classList.remove('sidebar-icon-only')
            return;
        }
        if(resp) {
            cookie('dm_sidebar', false);
            document.body.classList.add('sidebar-icon-only')
        }
    }
}

HeaderUser.propTypes = {
    onBurgerClick: PropTypes.func.isRequired
};

export default HeaderUser;