import React from "react";
import PropTypes from 'prop-types';
import DataTable, {field} from "../../../generic/DataTable";
import {findAndCount} from "../../../../service/weekendsRaw";
import moment from "moment";

export default class Table extends React.Component {

    render() {
        const filters = {};
        const {uploadId} = this.props;

        if (uploadId) filters.input_file_id = uploadId;

        return (<DataTable
            filters={filters}
            findAndCount={findAndCount}
            fields={[
                field("id", true),
                field("Date", true, 'date',res => moment(res.date).format("YYYY.MM.DD")),
                field("Day", false, 'day',res => moment(res.date).format('dddd')),
                field("Kind", true, "kind"),
                field("Created at", true, "created_at",res => moment(res.created_at).format("YYYY.MM.DD"))
                ]}
            name={"weekendsRaw"}
        />);
    }
}

Table.propTypes = {
    uploadId: PropTypes.number
};