import React from 'react'

import { Route, Switch } from 'react-router-dom';
import Index from "./Index";
import Create from "./Create"
import Process from "./Process"

const IndexRoute = () => {
    return (
      <Switch>
        <Route exact path="/input/stocks/upload" component={ Index } />
        <Route path="/input/stocks/upload/new" component={ Create } />
        <Route path="/input/stocks/upload/:id" component={ Process } />
      </Switch>
    );
};

export default IndexRoute;