import React, {Component} from 'react';
import 'react-day-picker/dist/style.css';
import './App.css';
import Layout from './components/layout/Layout';
import Login from './components/Login';
import Index from './components/Index';
import PrivateRoute from './route/PrivateRoute';
import {BrowserRouter as Router, Switch, Route as DefaultRoute, Redirect} from "react-router-dom";
import PublicRoute from "./route/PublicRoute";
import Me from "./components/Me/Index";
import AdministrationUser from "./components/administration/user/Route";
import AdministrationRoles from "./components/administration/role/Index";
import InputDeliveryMatrix from "./components/input/deliveryMatrix/Index";
import InputWeekends from "./components/input/weekends/Index";
import InputDeliveryMethodProducts from "./components/input/deliveryMethodProducts/Index";
import InputDeliveryDate from "./components/delivery/deliveryDate/Index";
import InputStocks from './components/input/stocks/Index'
import InputAlternativeProducts from './components/input/alternativeProducts/Index'
import InputProductsLimits from './components/input/productsLimits/Index'
import CatalogDeliveryMatrix from "./components/catalog/deliveryMatrix/Route";
import Methods from "./components/methods/Index";
import Products from './components/products/Index';
import Warehouses from './components/warehouses/Index';
import Stocks from './components/stocks/Index';
import Orders from './components/orders/Index';
import AlternativeProducts from './components/alternativeProducts/Index';
import deliveryMethodProducts from './components/deliveryMethodProducts/Index'
import DeliveryMatrixLogs from './components/deliveryMatrixLogs/Index'
import DeliveryMethodLogs from './components/deliveryMethodLogs/Index'
import TimeSlots from './components/timeSlots/Index';
import ReportingStocks from './components/reporting/stocks/Index';
import CORS from './components/cors/Index';
import Journal from './components/journal/Index';
import PasswordForm from './components/passwordForm/Index'
import ForgotPasswordForm from './components/forgotPasswordForm/Index'
import AzureLogin from "./components/AzureLogin";

import { QueryParamProvider } from "use-query-params";
import PublicTokens from './components/publicTokens';

class App extends Component {

    render() {
        return (
            <Router>
                <Layout {...this.props}>
                    <QueryParamProvider ReactRouterRoute={DefaultRoute}>
                        <Switch>
                            <PublicRoute exact path="/connect/microsoft" component={AzureLogin} />
                            <PublicRoute path="/login" component={Login} />
                            <PublicRoute path="/setPassword/:code" component={PasswordForm} />
                            <PublicRoute path="/forgotPassword" component={ForgotPasswordForm} />
                            <PrivateRoute exact path="/" component={Index} />
                            <PrivateRoute path="/me" component={Me} />
                            <PrivateRoute exact path="/administration" component={() => <Redirect to={"/administration/user"} />} />
                            <PrivateRoute path="/administration/user" component={AdministrationUser}/>
                            <PrivateRoute path="/administration/roles" component={AdministrationRoles}/>
                            <PrivateRoute path="/input/deliveryMatrix" component={InputDeliveryMatrix}/>
                            <PrivateRoute path="/input/weekends" component={InputWeekends}/>
                            <PrivateRoute path="/input/deliveryMethodProducts" component={InputDeliveryMethodProducts}/>
                            <PrivateRoute path="/input/stocks" component={InputStocks}/>
                            <PrivateRoute path="/input/alternativeProducts" component={InputAlternativeProducts}/>
                            <PrivateRoute path="/input/productsLimits" component={InputProductsLimits}/>
                            <PrivateRoute path="/delivery/deliveryDate" component={InputDeliveryDate}/>
                            <PrivateRoute path="/catalog/deliveryMatrices" component={CatalogDeliveryMatrix}/>
                            <PrivateRoute path="/catalog/deliveryMethodProducts" component={deliveryMethodProducts}/>
                            <PrivateRoute path="/catalog/products" component={Products}/>
                            <PrivateRoute path="/catalog/reference/:kind" component={Methods}/>
                            <PrivateRoute path="/catalog/reference/:kind" component={Methods}/>
                            <PrivateRoute path="/catalog/warehouses" component={Warehouses}/>
                            <PrivateRoute path="/catalog/stocks" component={Stocks}/>
                            <PrivateRoute path="/administration/deliverymatrixlogs" component={DeliveryMatrixLogs}/>
                            <PrivateRoute path="/administration/deliverymethodlogs" component={DeliveryMethodLogs}/>
                            <PrivateRoute path="/catalog/orders" component={Orders}/>
                            <PrivateRoute path="/catalog/alternativeProducts" component={AlternativeProducts}/>
                            <PrivateRoute path="/catalog/timeSlots" component={TimeSlots}/>
                            <PrivateRoute path="/reporting/stocks" component={ReportingStocks}/>
                            <PrivateRoute path="/administration/cors" component={CORS}/>
                            <PrivateRoute path="/administration/journal" component={Journal}/>
                            <PrivateRoute path="/administration/public-tokens" component={PublicTokens}/>
                        </Switch>
                    </QueryParamProvider>
                </Layout>
            </Router>
        );
    }
}

export default App;