import React from 'react'
import {Route, Switch,} from 'react-router-dom';
import Index from "./Index";
import Edit from "./Edit"

export default class IndexRoute extends React.Component {
    render() {
        return (<Switch>
            <Route exact path="/catalog/deliveryMatrices" component={Index} />
            <Route path="/catalog/deliveryMatrices/:id" component={Edit} />
        </Switch>);
    }
};
