import React from "react";
import TableCard from "../generic/TableCard";
import Table from "./Table";
import TextSearch from "../generic/field/TextSearch";
import { pickBy } from 'lodash';
import { useQueryParam, StringParam } from 'use-query-params';
import {Button} from "reactstrap";

const Index = () => {
  const [filterSKUName, setFilterSKUName] = useQueryParam('skuName', StringParam);
  const [filterSKUCode, setFilterSKUCode] = useQueryParam('skuCode', StringParam);

  const searchSKUName = filterSKUName => {
    setFilterSKUName(filterSKUName)
  };

  const searchSKUCode = filterSKUCode => {
    setFilterSKUCode(filterSKUCode)
  };

  const dropFilters = () => {
    setFilterSKUName(undefined)
    setFilterSKUCode(undefined)
  };

  const filters = pickBy({
    code: filterSKUCode,
    name: filterSKUName
  })

  return (
    <TableCard title={"Products"}>
      <div style={{minHeight: "400px"}}>
        <div className={"row justify-content-start ml-1 mb-3"}>
          <TextSearch
            type="SKUCode"
            name="SKUCode"
            onSearch={ searchSKUCode }
            className="form-control col-sm-3 mr-2"
            value={ filterSKUCode }
            placeholder={"Enter sku code..."}
          />
          <TextSearch
            type="SKUName"
            name="SKUName"
            onSearch={ searchSKUName }
            className="form-control col-sm-3 mr-2"
            value={ filterSKUName }
            placeholder={"Enter sku name..."}
          />
          <Button className="mr-sm-2" color="default" onClick={ dropFilters }>
            <i className="fa fa-fw fa-times"/>
            Cancel
          </Button>
        </div>
        <Table filters={ filters } />
      </div>
    </TableCard>
  )
}

export default Index;