import React, {useState} from "react";
import InputFileHistory from "../../InputFileHistory";
import TableCard from "../../../generic/TableCard";
import {Link, Redirect} from "react-router-dom";

export default function Index() {
    const [redirectToFileId, setRedirectToFiledId] = useState(undefined);

    const handleSearch = (e) => {
        e.preventDefault();
    }

    const handleClick = (row) => {
        setRedirectToFiledId(row.id);
    }
    const filters = {};
    if(redirectToFileId) return <Redirect push to={"/input/weekends/upload/"+redirectToFileId}/>;

    return (
        <TableCard title={"Uploaded Files"}>
            <form className="form-inline mb-2" onSubmit={handleSearch}>
                <Link className="btn btn-primary"
                      to={`/input/weekends/upload/new?`}>
                    <i className="fa fa-plus"/>
                    &nbsp; Upload file
                </Link>
            </form>
            <InputFileHistory
                filters={filters}
                entity="weekends"
                onClick={handleClick}
            />
        </TableCard>
    );
}