import React, {useEffect, useState} from 'react'
import {Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import RegularButton from "../../../../../generic/RegularButton";
import {useForm} from "react-hook-form";
import fields from "../fields.json";
import PropTypes from "prop-types";
import Field from "../inputs/Field";
import Toggle from "../inputs/Toggle";
import TypeaheadCustom from "../inputs/TypeaheadCustom"
import useSubmit from "./useSubmit";
import {ReactDadata} from "react-dadata";
import {findAndCount} from "../../../../../../service/timeSlots";
import {noRange, noRangeFields, TIMESLOTS_FIELDS} from "./timeSlotsFields";

const timeSlotIdToName = (id) => id[0].toUpperCase() + id.slice(1).replace('_', ' ').replace('_id', '')

const CEComponent = ({type, isOpen, closeModalConfirmation, deliveryMatrixId, courierCompanies, changeData = {}}) => {
    const { register, watch, handleSubmit, formState: { errors }, control, setValue } = useForm({});
    const onSubmit = useSubmit({closeModalConfirmation, deliveryMatrixId, type, id: changeData.id});
    const [timeSlots, setTimeSlots] = useState([])
    const [isExpress, setIsExpress] = useState(undefined)

    useEffect(() => {
        findAndCount(0, 1000).then(({data}) => setTimeSlots(data))
    }, [])

    useEffect(() => {
        setIsExpress(watch('no_range') && watch('no_range').toLowerCase() === 'да')
    }, [watch('no_range'), isOpen])

    return (
    <Modal isOpen={isOpen}>
        <ModalHeader>{type === 'create' ? 'Create row' : 'Edit row'}</ModalHeader>
        <ModalBody>
            <ul className="nav nav-tabs" role="tablist">
                <li className="nav-item">
                    <a className="nav-link active" id="data-tab" data-toggle="tab" href="#dm-data" role="tab"
                       aria-controls="dm-data" aria-selected="true">Data</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" id="timeslots-tab" data-toggle="tab" href="#dm-timeslots" role="tab"
                       aria-controls="dm-methods" aria-selected="false">Timeslots</a>
                </li>
            </ul>
            <div className="tab-content">
                <div className="tab-pane fade active show" id="dm-data" role="tabpanel"
                     aria-labelledby="data-tab">
                    <div className="card-body">
                        <Label>Address suggestions</Label>
                        <ReactDadata placeholder="Enter address or postal code to autocomplete - index, region, city and region code"
                                     className={'mb-4'} token="564ccc10de37e3345d791584f63d3dd63bb58390"
                                     onChange={async suggestion => {
                                         let region_code;
                                         if (suggestion.data.kladr_id) region_code = suggestion.data.kladr_id.substr(0, 2)
                                         setValue('index', suggestion.data.postal_code)
                                         setValue('region', suggestion.data.region_with_type)
                                         setValue('city', suggestion.data.city)
                                         setValue('region_code', region_code)
                                     }}/>
                        <FormGroup>
                            <div className="row">
                                {fields.map((it) => {
                                    if (it.validation.pattern === 'toggle') {
                                        return <Toggle  key={it.id} control={control} it={it} defaultValue={changeData[it.id]} />
                                    }
                                    if (it.id === 'courier_company') {
                                        return <TypeaheadCustom key={it.id}
                                                                defaultInputValue={changeData[it.id]}
                                                                options={courierCompanies}
                                                                it={it}
                                                                control={control}
                                                                placeholder={'Choose courier company...'}
                                                                label={'Courier company'}
                                                                error={errors[it.id]} />
                                    }
                                    return <Field key={it.id} register={register} it={it} defaultValue={changeData[it.id]} error={errors[it.id]} />
                                })}
                            </div>
                        </FormGroup>
                    </div>
                </div>
                <div className="tab-pane" id="dm-timeslots" role="tabpanel"
                     aria-labelledby="timeslots-tab">
                    <div className={'row'}>
                        <div className={'col-12'}>
                            <Toggle key={noRange.id} control={control} it={noRange} defaultValue={changeData[noRange.id]} />
                        </div>
                        {isExpress
                            ? noRangeFields.map((it) => {
                                return <Field key={it.id} register={register} it={it} defaultValue={changeData[it.id]} error={errors[it.id]} />
                            })
                            : TIMESLOTS_FIELDS.map(it => (
                                <div className={'col-6'}>
                                    <Label for={it} className="mt-3">{timeSlotIdToName(it)}</Label>
                                    <select id={it} name={it} className="form-control mr-2 mt-1" defaultValue={changeData[it]} ref={register()}>
                                        <option key={0} value={0}>0</option>
                                        {timeSlots.map((it) => <option key={it.id} value={it.id}>{it.timeslots}</option>)}
                                    </select>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </ModalBody>
        <ModalFooter>
          <RegularButton color="primary" onClick={handleSubmit(onSubmit)}>Save</RegularButton>
          <Button color="secondary" onClick={closeModalConfirmation}>Cancel</Button>
        </ModalFooter>
    </Modal>)
}

CEComponent.propTypes = {
    type: PropTypes.string,
    isOpen: PropTypes.bool,
    deliveryMatrixId: PropTypes.number,
    closeModalConfirmation: PropTypes.func,
    changeData: PropTypes.object,
    courierCompanies: PropTypes.array
}

export default CEComponent;